import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import BandsList from './bandsList'

export default function Bands(props) {
    return(
        <div>
            <Route path="/data/bands" component={BandsList} />
        </div>
    )
}