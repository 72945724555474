//react and material
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

  
export default function DataTableHead(props) {
    var createSortHandler = property => event => {
        props.onRequestSort(event, property);
    };
  
    const { onSelectAllClick, sortDir, sort, selected, listIDs, columnData, selectActions } = props;

    //determine if all checked or indeterminate
    var allChecked = false
    var indeterminateChecked = false
    //get the rows in the quickFitlered view
    var quickFilterRows = listIDs.filter(idRow => (idRow[1] === true))
    //take all data in the current quickFiltered view and return the rows present in the selected list
    var selectedRows = quickFilterRows.filter(idRow => (selected.indexOf(idRow[0]) !== -1)) 
    if (selectedRows.length > 0) {
        if(selectedRows.length === quickFilterRows.length) { //if the number in the selected data list equals the number of rows in the quickfiltered list, means all in the quickfiltered list are selected
            allChecked = true
        } else {
            //so not all checked.  But becasue some are checked it means this is indeterminate
            indeterminateChecked = true
        }
    }

    return (
        <TableHead>
            <TableRow>
                {selectActions !== undefined ? 
                    <TableCell padding="checkbox" style={{padding: "6px 0px 6px 0px"}}>
                        <Checkbox
                            indeterminate={indeterminateChecked}
                            checked={allChecked}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    : undefined
                }
                {
                    columnData.map(column => 
                        <TableCell
                            key={column.fieldName}
                            sortDirection={sort === column.fieldName ? sortDir : false} tooltip={column.description}
                            style={column.alignRight ? {textAlign: "right", padding: "6px 2px 6px 3px"} : {padding: "6px 2px 6px 3px"}}
                        >
                            <Tooltip title={column.description} >
                                <TableSortLabel
                                    hideSortIcon
                                    active={sort === column.fieldName}
                                    direction={sortDir}
                                    onClick={createSortHandler(column.fieldName)}
                                >
                                    {column.name}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    )
                }
                { //wouldnt it be better to do this with colspan?
                    props.noStackCrud ?
                        //not stacking crud.  individual columns
                        <>
                            {props.showRead ? 
                                <TableCell style={{padding: "0px"}}>
                                    
                                </TableCell>
                                : null
                            }
                            {props.showCopy ? 
                                <TableCell style={{padding: "0px"}}>
                                    
                                </TableCell>
                                : null
                            }
                            {props.showEdit ? 
                                <TableCell style={{padding: "0px"}}>
                                    
                                </TableCell>
                                : null
                            }
                            {props.showDelete ? 
                                <TableCell style={{padding: "0px"}}>
                                    
                                </TableCell>
                                : null
                            }
                        </>
                    :
                        //stacking crud.  one column for it all
                        props.showRead || props.showCopy || props.showEdit || props.showDelete ?
                            <TableCell style={{padding: "0px"}}>
                                
                            </TableCell>
                        : null
                }
            </TableRow>
        </TableHead>
    );
}
