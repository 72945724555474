import React, {useState} from 'react'
import Chip from '@material-ui/core/Chip'

//components
import SKUHistoryModal from './skuHistoryModal'

export default function SKUHistoryCell(props) {
    const [modalOpen, setModalOpen] = useState(false)

    function handleClose() {
        setModalOpen(false)
    }

    function handleClick() {
        setModalOpen(true)
    }

    return (
        <div>
            {
                props.id === 0 ? undefined :
                <Chip variant="outlined" size="small" clickable label={props.sku} onClick={() => handleClick()}>
                </Chip>
            }
            <SKUHistoryModal 
                open={modalOpen}
                sku={props.sku}
                onClose={handleClose}
            />
        </div> 
        )
}