import { ContactsOutlined } from "@material-ui/icons";

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
        const negativeSign = amount < 0 ? "-" : "";
    
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
    
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

function initialsFromName(name) {
    function checkBlankString(check) {
        if(check + "" === "") {
            return "N"
        } else {
            return check.substring(0, 1).toUpperCase()
        }
    }
    //generate the 2 letter (max) avatar
    var nameArray = (name === undefined ? "" : name + "").split(" ")
    if(nameArray.length === 1) {
        //only one name
        return checkBlankString(nameArray[0]) + nameArray[0].substring(1,2).toLowerCase()
    } else {
        //multiple names, take the first and last
        return checkBlankString(nameArray[0]) + checkBlankString(nameArray[nameArray.length - 1])
    }
}

var getDaysInMonth = function(month, year) {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month, 0).getDate();
}

function byName(a, b) {
    if(a.name > b.name) {return 1}
    if(a.name < b.name) {return -1}
    return 0
}

//using me, gives a list of the unique users who's accessProfile allows them access to the same stores as this users accessProfile
function accessibleUsers(me) {
    if(me === undefined || me.accessProfileStores === undefined) {
        return []
    }
    //get the stores they are allows
    var myStoreIDs = me.accessProfile.accessProfileStores.map(aps => aps.storeID)
    //get the accessprofilesstores that have access to these stores
    var apsUsers = me.accessProfileStores
    .filter(aps => myStoreIDs.includes(aps.storeID))
    .map(aps => aps.accessProfile.users) //now an array of user arrays, flattening
    .reduce((acc, cur) => acc.concat(...(Array.isArray(cur) ? cur : [])), []) //now remove dupes
    .reduce((acc, cur) => acc.concat(acc.map(u => u.userID).includes(cur.userID) ? [] : cur), []) //now remove any not enabled
    .filter(u => u.active)
    .sort(byName)
    //return the unique set
    return apsUsers
}

//using me, gives a list of the unique stores this user has access to
function accessibleStores(me) {
    if(me === undefined || me.accessProfileStores === undefined) {
        return []
    }
    //get the stores they are allows
    var myStoreIDs = me.accessProfile.accessProfileStores.map(aps => aps.storeID)
    //console.log("my stoee ids" + JSON.stringify(myStoreIDs))
    //get the accessprofilesstores that have access to these stores
    var allStores = me.accessProfileStores.map(s => s.store)
    //return the stores
    return myStoreIDs.map(s => allStores.filter(as => as.storeID === s)[0]).sort(byName)
}

function getDefaultStoreID(me) {
    //have a look if they have a default store first
    if(me !== undefined && me.storeID !== 0 && me.accessProfile.accessProfileStores.map(s => s.storeID).includes(me.storeID)) {
        return me.storeID
    }
    //just return the first store in their access profile
    if(me.accessProfile.accessProfileStores.map(s => s.storeID).length > 0) {
        return me.accessProfile.accessProfileStores.map(s => s.storeID)[0]
    }
}

function ensureArray(possibleArray) {
    return (Array.isArray(possibleArray) ? possibleArray : [])
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export {formatMoney, initialsFromName, getDaysInMonth, accessibleUsers, accessibleStores, byName, ensureArray, isJsonString, getDefaultStoreID}