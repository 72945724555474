import React from 'react'
import {Route, Switch} from 'react-router-dom'

import ViewRota from './view/viewRota'
import PlanRota from './plan/planRota'

export default function Rota(props) {
    return(
        <Switch>
            <Route path="/rota/view" render={renderProps => <ViewRota {...renderProps} me={props.me} />} />
            <Route path="/rota/plan" render={renderProps => <PlanRota {...renderProps} me={props.me} />} />
        </Switch>
    )
}