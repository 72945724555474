import React from 'react'
import CardSetHeader from '../cardSetHeader'

export default function MeTitle(props) {
    return (
        <CardSetHeader
            title={props.userName + " " + props.revenueCommission.substring(0,1).toUpperCase() + props.revenueCommission.substring(1)}
            onAddHidden={props.onAddHidden}
            setName={props.setName}
        />
    
    )
}