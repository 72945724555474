import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import AutoComplete from '../../../shared/autoComplete'
import RateCardLineStoreSelector from './rateCardLineStoreSelector'
import {getToken} from '../../../../lib/auth';
import SKUHistoryModal from './skuHistoryModal'
import AutoCompleteNative from '../../../shared/autoCompleteNative'

export default {
    displayName: "Rate Card Line",
    idField: "rateCardLineID",

    getURL: id => "/rateCardLines?fkJoins=" + encodeURIComponent("file,rateCardLineStores") + "&rateCardLineID=" + id,
    putURL: id => "/rateCardLine/" + id,
    deleteURL: id => "/rateCardLine/" + id,
    postURL: "/rateCardLine",
    getListURL: "/rateCardLines",
    maxWidth: "lg",
    recordName: formVals => formVals.sku,
    overrideOnCopy: {
        fileID:0
    },

    blankForm: {
        rateCardLineID: 0,
        sku: "", 
        skuTypeID: 0,
        description: "", 
        customerRRP: "", 
        customerMonthly: "", 
        contractMonths: "",
        rateCardLineStores: [],
        rateCardID: 0,
        bandID: 0,
        legacySKU: "",
        saleTypeID: 0,
        fileID: 0,
        file: {
            originalName: "",
            fileID: 0
        }
    },
    form : function (props) {
        const [historyOpen, setHistoryOpen] = useState(false)

        function handleChange(event) {
            //check if we are allowing the change
            //only allow prices that are decimal numbers
            var decimal = new RegExp("^[0-9]*([.][0-9]{0,2}){0,1}$")
            if(['customerRRP', 'customerMonthly'].includes(event.target.id) && !decimal.test(event.target.value)) {
                //invalid.  revert the value
                event.target.value = props.formVals[event.target.id]
            }
            //only whole contract months allowed
            var intreg = new RegExp("^[0-9]*$")
            if(['contractMonths'].includes(event.target.id) && !intreg.test(event.target.value)) {
                //invalid.  revert the value
                event.target.value = props.formVals[event.target.id]
            }            
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }

        //console.log("Date is " + props.formVals.fromDate.toString())

        function handleHistory(isOpen) {
            setHistoryOpen(isOpen)
        }
    
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <h4>SKU details</h4>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="sku"
                            label="SKU"
                            type="text"
                            value={props.formVals.sku}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("sku")}
                            helperText={props.validationResult.failMessages["sku"]}
                            fullWidth
                            disabled={props.disabled}
                        />
                        <AutoComplete 
                            multi={false}
                            id="skuTypeID"
                            label="Type"
                            onChange={handleChange}
                            placeholder="Choose..."
                            value={props.formVals.skuTypeID}
                            getURL="/skuTypes"
                            valueField="skuTypeID"
                            labelField="name"
                            error={props.validationResult.failMessages.hasOwnProperty("skuTypeID")}
                            helperText={props.validationResult.failMessages["skuTypeID"]}
                            disabled={props.disabled}
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            value={props.formVals.description}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("description")}
                            helperText={props.validationResult.failMessages["description"]}
                            fullWidth
                            disabled={props.disabled}
                        />
                        <AutoComplete 
                            multi={false}
                            id="bandID"
                            label="Band"
                            onChange={handleChange}
                            placeholder="Choose..."
                            value={props.formVals.bandID}
                            getURL="/bands"
                            valueField="bandID"
                            labelField="name"
                            error={props.validationResult.failMessages.hasOwnProperty("bandID")}
                            helperText={props.validationResult.failMessages["bandID"]}
                            disabled={props.disabled}
                            clearable={true}
                        />
                        <TextField
                            margin="dense"
                            id="legacySKU"
                            label="Legacy SKU"
                            type="text"
                            value={props.formVals.legacySKU}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("legacySKU")}
                            helperText={props.validationResult.failMessages["legacySKU"]}
                            fullWidth
                            disabled={props.disabled}
                        />
                        {
                            props.formVals.rateCardLineID === 0 ? null :
                            <div>
                                <Button onClick={() => handleHistory(true)} variant="contained">
                                    View SKU history...
                                </Button>
                                <SKUHistoryModal 
                                    open={historyOpen}
                                    sku={props.formVals.sku}
                                    onClose={() => handleHistory(false)}
                                />
                            </div>
                        }
                        <h4>Customer details</h4>
                        <TextField
                            margin="dense"
                            id="customerRRP"
                            label="RRP"
                            type="text"
                            value={props.formVals.customerRRP}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("customerRRP")}
                            helperText={props.validationResult.failMessages["customerRRP"]}
                            fullWidth
                            disabled={props.disabled}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            }}

                        />
                        <TextField
                            margin="dense"
                            id="customerMonthly"
                            label="Monthly recurring charge"
                            type="text"
                            value={props.formVals.customerMonthly}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("customerMonthly")}
                            helperText={props.validationResult.failMessages["customerMonthly"]}
                            fullWidth
                            disabled={props.disabled}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                endAdornment: <InputAdornment position="end">/month</InputAdornment>,
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="contractMonths"
                            label="Contract length (months)"
                            type="text"
                            value={props.formVals.contractMonths}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("contractMonths")}
                            helperText={props.validationResult.failMessages["contractMonths"]}
                            fullWidth
                            disabled={props.disabled}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">months</InputAdornment>,
                            }}
                        />
                        { //if this is an upload, and they have permission to system setup
                            props.formVals.fileID === 0 || !props.me.accessProfile.systemAreas.includes("SystemSetup") ? null :
                            <div>
                                <h4>
                                    Upload file
                                </h4>
                                <p>This is the file used to upload this line:</p>
                                <p><a href={(process.env.REACT_APP_API_URL === undefined ? ((window.location.href + "salt").replace(window.location.pathname + "salt", "/api")) : process.env.REACT_APP_API_URL) + "/file/" + props.formVals.file.fileID + "/download?token=" + getToken()}>{props.formVals.file.originalName}</a></p>
                            </div>
                        }
                        <h4>
                            Miscellaneous
                        </h4>
                        <AutoCompleteNative
                            id="saleTypeID"
                            value={props.formVals.saleTypeID}
                            label="Sale type"
                            onChange={handleChange}
                            optionLabel={o => o.name}
                            optionValue={o => o.saleTypeID}
                            clearValue={0}
                            optionsURL={"/saleTypes?sort=name"}
                            error={props.validationResult.failMessages.hasOwnProperty("saleTypeID")}
                            helperText={props.validationResult.failMessages["saleTypeID"]}
                            placeholder={"Choose sale type..."}
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <h4>
                            Store, revenues and commission
                        </h4>
                        <RateCardLineStoreSelector
                            id="rateCardLineStores"
                            value={props.formVals.rateCardLineStores || []}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("rateCardLineStores")}
                            helperText={props.validationResult.failMessages["rateCardLineStores"]}
                            disabled={props.disabled}
                            rateCardLineID={props.formVals.rateCardLineID}
                            me={props.me}
                        />
                    </Grid>
                </Grid>
    
            </div>
    
        )
    },
}

