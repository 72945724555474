import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'


export default function ValueField(props) {
    return (
        props.visible ?
            <Grid item xs={props.width} >
                <TextField
                    margin="dense"
                    id={props.field + props.store.storeID}
                    label={props.store.name + " " + props.field}
                    type="text"
                    value={props.value.filter(l => l.storeID === props.store.storeID).length === 1 ? (props.value.filter(l => l.storeID === props.store.storeID)[0])[props.field] : ""}
                    onChange={e => props.onChange(e, props.store.storeID, props.field)}
                    fullWidth
                    disabled={props.disabled || props.value.filter(rcls => rcls.active && rcls.storeID === props.store.storeID).length === 0}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                />
            </Grid>
        : null
    )
}
