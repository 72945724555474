import React from 'react'

import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import MetricPartEntityForm from './metricPartEntityForm';


const columnData = [
    { fieldName: 'metricPartID', default: true, name: 'ID', description: "The unchanging identifier of the metric part"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the metric part"},
    { fieldName: 'divisionPart', default: true, name: 'Division part', description: "Is this part of the numerator or denominator of the metric calculation"},
    { fieldName: 'multiplier', default: true, name: 'Multiplier', description: "Multiply the field by this amount (-1 for subtractions, for example)"},
    { fieldName: 'tableName', default: true, name: 'Table', description: "System table from which these values should come"},
    { fieldName: 'field', default: true, name: 'Field', description: "Field used, ie count or revenue"},
];

export default function MetricPartsTable(props) {
    const filters={
        metricID: props.metric.metricID,
    }

    return(
        <>
            <DataTable
                columnData={addAuditFields(columnData)}
                //overriding the rateCardID param on blankform to be the current one
                entityForm={{
                    ...MetricPartEntityForm,
                    blankForm: {
                        ...MetricPartEntityForm.blankForm,
                        metricID: props.metric.metricID,
                    }
                }}
                selectRefreshTime={props.selectRefreshTime}
                dataRefreshTime={props.dataRefreshTime}
                filters={filters} 
                title={props.metric.name + " Metric Parts"}
                enableCrud={props.enableCrud}
                me={props.me}
            />
        </>
    )
}