import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Grid, InputAdornment } from '@material-ui/core'

import CheckBox from '../../../shared/checkBox'
import AutoCompleteNative from '../../../shared/autoCompleteNative'
import { getPrefixSuffix } from './metricsList'
import { formatMoney } from '../../../../lib/general'

const metricTypes = [
    {
        value: "percent",
        label: "Percent"
    },
    {
        value: "integer",
        label: "Integer"
    },                
    {
        value: "2dp",
        label: "2 decimal places"
    },                
    {
        value: "money",
        label: "Money"
    },                
]

const successIsLesses = [
    {
        value: 0,
        label: "More than or equal"
    },
    {
        value: 1,
        label: "Less than or equal"
    },                
]

export default {
    displayName: "Metric",
    idField: "metricID",

    getURL: id => "/metrics?metricID=" + id,
    putURL: id => "/metric/" + id,
    deleteURL: id => "/metric/" + id,
    postURL: "/metric",
    getListURL: "/metrics",
    maxWidth: "sm",
    recordName: formVals => formVals.name,

    blankForm: {
        metricID: 0,
        name: "", 
        showCard: true,
        showInTables: true,
        successValue: "0",
        successIsLess: 0,
        metricType: "",
    },
    form : function (props) {
        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }

        function handlePercentChange(event) {
            //if percent, handle it different, dividing by 100
            if(props.formVals.metricType === "percent") {
                props.onChange({...props.formVals, [event.target.id]: (event.target.value / 100)})
            } else {
                handleChange(event)
            }
           
        }
    
        return (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    value={props.formVals.name}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("name")}
                    helperText={props.validationResult.failMessages["name"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <AutoCompleteNative
                    id="metricType"
                    value={props.formVals.metricType}
                    label="Metric type"
                    onChange={handleChange}
                    optionLabel={o => o.label}
                    optionValue={o => o.value}
                    clearValue=""
                    options={metricTypes}
                    error={props.validationResult.failMessages.hasOwnProperty("metricType")}
                    helperText={props.validationResult.failMessages.metricType}
                    disabled={props.disabled}
                />
                <p>
                    Success value should be 0 if not used.  Success value should only be used on percent type metrics for now, otherwise varied calculation scope (user, store, etc) can lead to misleading results.
                </p>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <AutoCompleteNative
                            id="successIsLess"
                            value={props.formVals.successIsLess ? 1 : 0}
                            label="Success is"
                            onChange={handleChange}
                            optionLabel={o => o.label}
                            optionValue={o => o.value}
                            clearValue={""}
                            options={successIsLesses}
                            error={props.validationResult.failMessages.hasOwnProperty("successIsLess")}
                            helperText={props.validationResult.failMessages.successIsLess}
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="dense"
                            id="successValue"
                            label="Success value"
                            type="text"
                            value={props.formVals.metricType === "percent" ? formatMoney(props.formVals.successValue * 100, 0) : props.formVals.successValue}
                            onChange={handlePercentChange}
                            fullWidth
                            error={props.validationResult.failMessages.hasOwnProperty("successValue")}
                            helperText={props.validationResult.failMessages.successValue}
                            disabled={props.disabled}
                            InputProps={{
                                startAdornment: getPrefixSuffix(props.formVals.metricType)[0] === "" ? undefined : <InputAdornment position="end">{getPrefixSuffix(props.formVals.metricType)[0]}</InputAdornment>,
                                endAdornment: getPrefixSuffix(props.formVals.metricType)[1] === "" ? undefined : <InputAdornment position="end">{getPrefixSuffix(props.formVals.metricType)[1]}</InputAdornment>,
                            }}
                        />                    
                    </Grid>
                </Grid>
                
                <h3>
                    Where Shown
                </h3>
                <CheckBox 
                    id="showCard"
                    label="Show card"
                    tooltip="Show a card to the user for this metric"
                    onChange={handleChange}
                    value={props.formVals.showCard}
                    error={props.validationResult.failMessages.hasOwnProperty("showCard")}
                    helperText={props.validationResult.failMessages["showCard"]}
                    disabled={props.disabled}
                />
                <CheckBox 
                    id="showInTables"
                    label="Show in tables"
                    tooltip="Show this metric in the tables (by user, store, etc)"
                    onChange={handleChange}
                    value={props.formVals.showInTables}
                    error={props.validationResult.failMessages.hasOwnProperty("showInTables")}
                    helperText={props.validationResult.failMessages["showInTables"]}
                    disabled={props.disabled}
                />
                <AutoCompleteNative
                    id="analysisFieldID"
                    value={props.formVals.analysisFieldID}
                    label="Show in daily report?"
                    onChange={handleChange}
                    optionLabel={o => o.name}
                    optionValue={o => o.analysisFieldID}
                    clearValue={0}
                    optionsURL={"/analysisFields?sort=name"}
                    error={props.validationResult.failMessages.hasOwnProperty("analysisFieldID")}
                    helperText={props.validationResult.failMessages["analysisFieldID"]}
                    placeholder={"Choose where to show..."}
                    disabled={props.disabled}
                />
            </div>
        )
    },
}

