import React from 'react'
import TextField from '@material-ui/core/TextField'

export default {
    displayName: "Sale Type",
    idField: "saleTypeID",

    getURL: id => "/saleTypes?saleTypeID=" + id + "&fkJoins=saleTypeStores",
    putURL: id => "/saleType/" + id,
    deleteURL: id => "/saleType/" + id,
    postURL: "/saleType",
    getListURL: "/saleTypes",
    maxWidth: "lg",
    recordName: formVals => formVals.name,

    blankForm: {
        saleTypeID: 0,
        name: "", 
    },
    form : function (props) {
        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }
    
        return (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    value={props.formVals.name}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("name")}
                    helperText={props.validationResult.failMessages["name"]}
                    fullWidth
                    disabled={props.disabled}
                />
            </div>
    
        )
    },
}

