import React, {useState, useRef} from 'react'
import TableCell from '@material-ui/core/TableCell';
import Popover from '@material-ui/core/Popover'

import TargetGridCell from './targetGridCell'
import IsUserTargetCell from './isUserTargetCell';
import {targetsAreEqual} from './targetGrid' 
import TotalCell from './totalCell';
import { formatMoney } from '../../../lib/general';
import HoursTotalCell from './hoursTotalCell';



export default function ColsStore(props) {
    const anchorEl = useRef(null);
    const [closed, setClosed] = useState(false)

    //header 1 is handled by the col store set for stores
    if(props.rowType === "header2") {
        return (
            <TableCell align="center" style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}} align="center" colSpan={2}>
                <strong>
                    {props.targetType.substring(0, 1).toUpperCase() + props.targetType.substring(1)}
                </strong>
            </TableCell>
        )
    }

    function isTarget() {
        //is there a relevent target?
        if(props.targets.length === 1) {
            //return the isTarget value of the first record
            return props.targets[0].isTarget || false
        } 
        //no targets so not a target
        return false
    }

    function handleIsUserTargetChange(e) {
        //if there are targets, update them.  If not, add them
        if(props.targets.length === 1) {
            //update them to isTarget = true
            props.onChange(
                props.allTargets.map(t => ({
                    ...t,
                    isTarget: (targetsAreEqual(props.targets[0], t) ? e.target.checked : t.isTarget)
                }))
            )
        } else {
            //add relevent targets with isTarget=true
            props.onChange(
                props.allTargets.concat(
                    {
                        ...props.blankTarget,
                        isTarget: true
                    }
                )
            )
        }
    }

    if(props.rowType === "row") {
        return (
            <>
                {
                    props.hoursInput ? null :
                    <TableCell style={{backgroundColor: "rgb(241,241,241)", padding: "0px"}}>
                            <IsUserTargetCell
                                user={props.user}
                                disabled={props.view !== "set" || !props.me.accessProfile.systemAreas.includes("RecordTargets")}
                                value={isTarget()}
                                onChange={e => handleIsUserTargetChange(e)}
                            />
                    </TableCell>
                }
                <TableCell style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}} align="right" colSpan={props.hoursInput ? 2 : 1}>
                    <TargetGridCell 
                        view={props.view}
                        targets={props.targets}
                        disabled={props.view !== "set" || !props.me.accessProfile.systemAreas.includes("RecordTargets") || props.hoursInput}
                        targetType={props.targetType}
                        onChange={props.onChange}
                        allTargets={props.allTargets}
                        blankTarget={props.blankTarget}
                        highlightTargetID={props.highlightTargetID}
                    />
                </TableCell>
            </>
        )
    }
    if(props.rowType === "total") {
        return (
            <>
                {
                    //if using hours use the full cell instead of using this filler cell
                    props.hoursInput ? null :
                    <TableCell style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}}>
                        
                    </TableCell>
                }
                {
                    props.view === "set" ?
                        props.hoursInput ?
                            <TableCell style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}} colSpan={props.hoursInput ? 2 : 1}>
                                <HoursTotalCell
                                    totalTargets={props.totalTargets}
                                    onChange={props.onChange}
                                    allTargets={props.allTargets}
                                />
                            </TableCell>
                        :
                            <TotalCell
                                targets={props.targets}
                                totalTargets={props.totalTargets}
                                backgroundColor="rgb(241,241,241)"
                                bold
                                colSpan={props.hoursInput ? 2 : 1}
                            />
                    :
                        <TableCell style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}} colSpan={props.hoursInput ? 2 : 1}>
                        
                        </TableCell>
                }
                
            </>
        )
    }
    if(props.rowType === "totalTarget") {
        return (
            <>
                <TableCell style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}}>
                    <IsUserTargetCell
                        user={props.user}
                        disabled={props.view !== "set" || !props.me.accessProfile.systemAreas.includes("RecordTargets")}
                        value={isTarget()}
                        onChange={e => handleIsUserTargetChange(e)}
                    />
                </TableCell>
                {
                    props.view === "set" ?
                        <TotalCell
                            targets={props.targets}
                            totalTargets={props.totalTargets}
                            backgroundColor="rgb(241,241,241)"
                            highlightTargetID={props.highlightTargetID}
                        />
                    :
                        props.targets.length === 1 && props.targets[0].isTarget ?
                            <TableCell ref={anchorEl} style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}} align="right">
                                {
                                    formatMoney(props.view === "progress" ? props.targets[0].actual : props.targets[0].actual - props.targets[0].target, 0)
                                }
                                {
                                    anchorEl.current === null ? null :
                                        <Popover
                                            open={!closed && props.highlightTargetID !== undefined && props.highlightTargetID !== 0 && (props.targets || []).map(t => t.targetID + "").includes(props.highlightTargetID + "")}
                                            anchorEl={anchorEl.current}
                                            onClose={() => setClosed(true)}
                                            onClick={() => setClosed(true)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <div style={{padding: "18px"}}>
                                                The target you clicked
                                            </div>
                                        </Popover>
                                }
                            </TableCell>
                        :
                            <TableCell style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}} align="right">
                                n/a
                            </TableCell>
                        
                }
            </>
        )
    }
    //should never get here
    return null
}