import React, {useState, useEffect} from 'react'

//project
import CheckBox from '../../../shared/checkBox'
import authFetch from '../../../../lib/authFetch'


//icons


export default function SystemAreaSelector(props) {
    const [systemAreas, setSystemAreas] = useState([])

    useEffect(() => {
        authFetch("/accessProfiles/systemAreas")
        .then(r => setSystemAreas(r.listData))
    }, [])

    function buildEvent(withNewVal) {
        var e = {
            target: {
                id: props.id,
                value: withNewVal
            }
        }
        //console.log("Calling change with " + JSON.stringify(withNewVal))
        props.onChange(e)
    }

    function handleCheckboxChange(event, systemArea) {
        //decide if we're adding or removing it
        var newVals = []
        if(event.target.value) {
            //they have ticked, add it
            newVals.push(...props.value.split(", "), systemArea)
        } else {
            //they have unticked
            //delete it
            props.value.split(", ").forEach(s => {
                if(s === systemArea) {
                    //skip this one, deleting it from the list
                } else {
                    newVals.push(s)
                }
            })
        }
        //construct a pseudo event to pass up to the parent form
        buildEvent(newVals.join(", "))
    }

    return (
        <div>
            {systemAreas.map(s => {
                return (
                    <CheckBox key={s.name} disabled={props.disabled} label={s.description} onChange={e => handleCheckboxChange(e, s.name)} value={props.value.split(", ").includes(s.name)} />
                )
            })}
        </div>

    )
}