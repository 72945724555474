import React, {useState} from 'react'
import UsersTable from './usersTable'

const filters = {}

export default function UsersList(props) {

    return(
        <div>
            <h1>
                All Users
            </h1>
            <p>
                Here is a list of all users in the system.
            </p>
            <UsersTable
                filters={filters} 
                enableCrud={true}
            />
        </div>
    )
}