import React from 'react'
import { withRouter } from 'react-router-dom'

import { paramsToSaleURL } from '../pages/sales/general'

export default withRouter(function SalesLink(props) {
    return (
        <span 
            style={{cursor: "pointer"}} 
            onClick={() => props.history.push(
                paramsToSaleURL(props.params)
            )}
        >
            {props.children}
        </span>
    )
})