import React from 'react'
import {Route, Switch} from 'react-router-dom'

import MetricsList from './metricsList'
import MetricParts from './metricParts'

export default function Metrics(props) {
    return(
        <Switch>
            <Route path="/data/metrics/:metricID" render={renderProps => <MetricParts {...renderProps} me={props.me} />} />
            <Route path="/data/metrics" render={renderProps => <MetricsList {...renderProps} me={props.me} />} />
        </Switch>
    )
}