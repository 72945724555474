import 'date-fns';
import {format} from 'date-fns'
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    TimePicker as ATimePicker,
} from '@material-ui/pickers';

//expect the time as a string like "09:00" or "17:30"
export default function TimePicker(props) {
    function handleChange(time) {
        //build an event object to return
        var e = {
            target: {
                id: props.id,
                value: format(time, "HH:mm")
            }
        }
        props.onChange(e)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ATimePicker
                margin="dense"
                id={props.id}
                label={props.label}
                ampm={false}
                value={
                    new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate(),
                        props.value.split(":")[0],
                        props.value.split(":")[1],
                        0
                    )
                }
                onChange={handleChange}
                error={props.error}
                minutesStep={5}
                fullWidth
                helperText={props.helperText}
                disabled={props.disabled}
            />
        </MuiPickersUtilsProvider>
    );
}