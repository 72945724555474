import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'


export default function CancelModal(props) {
    const [note, setNote] = useState("")
    const [error, setError] = useState("")

    function handleCancelSale() {
        var ok = true
        if(note === "") {
            ok = false
            setError("You must explain the reason for cancelling")
        }
        if(ok) {
            //ok, we are cancelling
            props.onCancelSale(note)
            props.onClose()
        }
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Cancel Sale?
            </DialogTitle>
            <DialogContent>
                <strong>Please make sure you should not be recording a refund instead!</strong>
                <TextField
                    margin="dense"
                    id="note"
                    label="Reason for cancellation (required)"
                    type="text"
                    value={note}
                    onChange={e => setNote(e.target.value)}
                    error={error !== ""}
                    helperText={error === "" ? undefined : error}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
                <Button color="secondary" onClick={handleCancelSale} variant="contained">
                    Cancel Sale
                </Button>
            </DialogActions>
      </Dialog>
    )
}