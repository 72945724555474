import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import AsyncSelect from 'react-select/async';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import Popper from '@material-ui/core/Popper'
import { IconButton } from '@material-ui/core';

//project lib
import authFetch from '../../lib/authFetch'
import CrudModal from '../shared/crudModal'
import useWindowDimensions from './useWindowDimensions'

//icons
import ClearIcon from '@material-ui/icons/Clear'
import PageViewOutlinedIcon from '@material-ui/icons/PageviewOutlined'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        //height: 250,
        minWidth: 350,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function NoOptionsMessage(props) {
    return (
        <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
        >
        {props.children}
        </Typography>
    );
}


function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}




function Option(props) {
    return (
        <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
            fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
        >
        {props.children}
        </MenuItem>
    );
}


function Placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
        {children}
        </Typography>
    );
}


function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}


function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}


function MultiValue(props) {
  return (
    <Chip
        tabIndex={-1}
        label={props.children}
        className={clsx(props.selectProps.classes.chip, {
            [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
        size="small"
    />
  );
}



export default function AutoCompleteAsync(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [valueLabels, setValueLabels] = useState([])
    const [defaultOptions, setDefaultOptions] = useState([])
    const [previewOpen, setPreviewOpen] = useState(false)
    const [valuesRes, setValuesRes] = useState([])
    //const [loadOptions, setLoadOptions] = useState(promiseOptions)
    const {width, height} = useWindowDimensions()

    var anchor = null
    var selectAnchor = null

    function Menu(props2) {
        // console.log("Anchor is " + anchor)
        // console.log("Half viewport height is " + (height / 2))
        // console.log("Anchor y pos is " + anchor.getBoundingClientRect().top)
        // if(anchor.getBoundingClientRect().top > (height / 2.1)) {
        //     console.log("Menu should be flipped")
        // }

        return (
            <Popper open={true} anchorEl={anchor} style={{zIndex: 1400}} placement={"bottom-start" } modifiers={{
                flip: {
                  enabled: true,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent',
                },
              }}>
                <Paper square className={props2.selectProps.classes.paper} style={{ marginTop: 8, width: anchor ? anchor.clientWidth : undefined }} {...props2.innerProps}>
                    {props2.children}
                </Paper>
            </Popper>
        );
    }

    useEffect(() => {
        //we need to use AJAX to get the value labels for whatever value is passed
        //console.log("props.value set to " + props.value + "... looking up label from " + props.getURLValues)
        if(props.value + "" === "0" || props.value === []) {
            //a zero value does not need to be looked up
            if(props.multi) {
                setValueLabels([])
            } else {
                var vl = {}
                vl.value= props.value 
                vl.label= props.placeholder
                //console.log("Found zero so not bothering with " + props.getURLValues)
                setValueLabels(vl)
            }
            // var vl = {}
            // vl.value= props.value 
            // vl.label= props.placeholder
            // //console.log("Found zero so not bothering with " + props.getURLValues)
            // setValueLabels(vl)
        } else {
                //now build a list of options for state
                var g = valuesRes.map(o => 
                    {
                        //the label can be built from a simple labelField or label (function)
                        return {
                            [props.idField]: o[props.idField], 
                            label: props.labelFieldValues ? o[props.labelFieldValues] : props.labelValues(o)
                        }
                    }
                )
                //console.log("setting value labels: " + JSON.stringify(g))
                var vlu = {}
                if(props.multi) {
                    vlu = props.value.map(e => 
                        {
                            return {value: e, label: g.filter(o => o[props.idField] === e).reduce((acc, cur) => acc = cur.label, e === 0 ? props.placeholder : "Loading " + e + "...")}
                        }
                    )
                } else {
                    //console.log("looking for valuelabels with " + props.idField + " equal to " + props.value)
                    var matchingLabel = g.filter(o => o[props.idField] + "" === props.value + "")
                    //console.log("Found " + matchingLabel.length + ": " + JSON.stringify(matchingLabel))
                    vlu.value= props.value 
                    vlu.label=  (matchingLabel.length > 0 ? matchingLabel[0].label : "Loading...")
                }
                //console.log("For " + props.value + " we found vl " + JSON.stringify(vl))
                setValueLabels(vlu)
        }
    }, [JSON.stringify(props.value), valuesRes])

    useEffect(() => {
        //we need to use AJAX to get the value labels for whatever value is passed
        //console.log("props.value set to " + props.value + "... looking up label from " + props.getURLValues)
        if(props.value === 0 || props.value === []) {
            //a zero value does not need to be looked up
        } else {
            authFetch(props.getURLValues)
            .then(res => {
                setValuesRes(res.listData)
            })
        }
    }, [props.getURLValues])

    //console.log("Get URL vlas " + JSON.stringify(props.value))

    function Control(props) {
        const {
            children,
            innerProps,
            innerRef,
            selectProps: { classes, TextFieldProps },
        } = props;
        return (
            <TextField
                ref={node => anchor = node}
                fullWidth
                style={{marginTop: "0px"}}
                margin="dense"
                InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    style: {overflow: "hidden", height: "36px"},
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
                }}
                {...TextFieldProps}
            />
        );
    }


    const components = {
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
    };


    const selectStyles = {
        input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
            font: 'inherit',
        },
        }),
    };

    function handleChange(newVal) {
        //console.log("Automcomplete says newVal is " + JSON.stringify(newVal))
        var setting = (props.multi ? 
                newVal === null ? [] : newVal.map(e => e.value) 
            : newVal.value)
        //console.log("Setting new value to " + JSON.stringify(setting))
        //create a phony event object to pass back to the parent so it can handle it as if it's a text box
        var e = {
            target: {
                id: props.id,
                value: setting
            }
        }
        props.onChange(e)
    }

    function handleClear() {
        handleChange({value: 0})
    }

    //console.log("Rendering " + props.idField + " autocomplete with value " + props.value)

    useEffect(() => {
        //console.log("Effect to fetch options triggered")
        authFetch(props.getURL(""))
        .then(res => {
            //now build a list of options for state
            //console.log("Defaultoptions found: " + res.listData.length + " options")
            setDefaultOptions(res.listData.map(o => 
            {
                //the label can be built from a simple labelField or label (function)
                return {
                    value: o[props.valueField], 
                    label: props.labelField ? o[props.labelField] : props.labelFn(o)
                }
            }))
            //setLoadOptions(promiseOptions)
        })
    }, [props.getURL("")])

    const promiseOptions = function(inputValue) 
    {
        return new Promise(resolve => {
            //console.log("Loading async options via promise...")
            authFetch(props.getURL(inputValue))
            .then(res => {
                //now build a list of options for state
                //console.log("Promise found " + res.listData.length + " options")
                resolve(res.listData.map(o => 
                {
                    //the label can be built from a simple labelField or label (function)
                    return {
                        value: o[props.valueField], 
                        label: props.labelField ? o[props.labelField] : props.labelFn(o)
                    }
                }))
            })
        });
    }

    function handlePreviewOpen() {
        setPreviewOpen(true)
    }

    function handlePreviewClose() {
        setPreviewOpen(false)
    }

    //rendering different depending on if we need the clearable button or not
    //need to render some divs and a clear button
    return (
        <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
            <div style={{overflow: "hidden", flexGrow: 1}}>
                <AsyncSelect
                    defaultOptions={defaultOptions}
                    loadOptions={promiseOptions}
                    ref={node => selectAnchor = node}
                    classes={classes}
                    styles={selectStyles}
                    inputId={"react-select" + props.id}
                    TextFieldProps={{
                        label: props.label,
                        InputLabelProps: {
                            htmlFor: "react-select" + props.id,
                            shrink: true,
                        },
                        error: props.error,
                        helperText: props.helperText,
                        disabled:props.disabled,
                        //id: props.id + "text"
                        placeholder: props.placeholder,
                        variant:props.variant,
                    }}
                    isDisabled={props.disabled}
                    //options={options}
                    components={components}
                    //value={props.multi ?  : }
                    value={valueLabels}
                    onChange={handleChange}
                    isMulti={props.multi}
                    menuPlacement={"top"}
                    //menuPortalTarget={document.querySelector("body")}
                />
            </div>
            {
                props.clearable ? 
                    <div style={{width:"35px", display: "flex", alignItems:"center"}}>
                        <div >
                            <IconButton style={{marginTop: "20px"}} size="small" onClick={handleClear} disabled={props.disabled} >
                                <ClearIcon size="small" />
                            </IconButton>
                        </div>
                    </div>
                : null
            }
            {
                props.preview && props.entityForm !== undefined && props.getURL !== undefined ? 
                    <div style={{width:"35px", display: "flex", alignItems:"center"}}>
                        <div >
                            <IconButton style={{marginTop: "20px"}} size="small" onClick={handlePreviewOpen} disabled={props.value === 0} >
                                <PageViewOutlinedIcon size="small" />
                            </IconButton>
                            <CrudModal 
                                getURL={props.getURL}
                                entityName={props.entityName}
                                entityDisplayName={props.entityDisplayName}
                                action="read"
                                open={previewOpen}
                                onClose={handlePreviewClose}
                                //handleDataChanged={props.onDataChanged}
                                id={props.value}
                                blankForm={props.blankForm}
                                entityForm={props.entityForm}
                                maxWidth={props.maxWidth}
                                //postURL={props.postURL}
                            />
                        </div>
                    </div>
                : null
            }
        </div>
    )
}