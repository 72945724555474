import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'

export default function TargetsToolbar(props) {
    //calculate the tab value
    let tabValue = false
    //console.log("PLP is " + props.location.pathname + " and match is ")
    if(props.location.pathname.includes("/daily/dailyStats")) {
        tabValue = 2
    } else if(props.location.pathname.includes("/daily/dailyTable")) {
        tabValue = 1
    } else {
        tabValue = 0
    }

    return (
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
            <Tab label="Daily Report" component={Link} to="/daily/dailyReport" />
            <Tab label="Daily Table" component={Link} to="/daily/dailyTable" />
            <Tab label="Stats Input" component={Link} to="/daily/dailyStats" />
        </Tabs>
    );
}
