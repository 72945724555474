import React from 'react'

export default function Support(props) {


    return(
        <div>
            <h2>
                Support
            </h2>
            <p>
                For support please email <a href="mailto:support@mtssoftware.co.uk">support@mtssoftware.co.uk</a>
            </p>
            <p>
                Alternatively for urgent requests, please use the telephone support number provided at <a target="_blank" href="https://mtssoftware.co.uk" >mtssoftware.co.uk</a>
            </p>
            <p>
                Please note that support is limited to the software itself, and support cannot be provided for the devices being used to connect, or issues with the connection.  Such issues must be taken up with the relevent provider.
            </p>
            <p>
                Some requests may incur a charge.  In this case purchase authorisation will always be sought from the system licence holder prior to commencing work on the request.  Such requests are including but not limited to
                <ul>
                    <li>
                        Manually reverting the system to a previous point in time due to user error
                    </li>
                    <li>
                        New feature requests
                    </li>
                    <li>
                        System customization
                    </li>
                    <li>
                        Changes in system behaviour or calculations
                    </li>
                </ul> 
            </p>
            <h2>
                Backups
            </h2>
            <p>
                You system is backed up daily to secure cloud storage, and this backup is retained for 30 days.
            </p>
        </div>
    );
}
