import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'


export default function NoAcknowledgeModal(props) {
    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} maxWidth="xs" fullScreen={!noFullscreen} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {
                    props.announcement.clickType === "url" ?
                        "Click Link First"
                    :
                        "Download File First"
                }
            </DialogTitle>
            <DialogContent>
                Please 
                {
                    props.announcement.clickType === "url" ?
                        " click the link "
                    :
                        " download the file "
                }
                before clicking OK to hide this announcement
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}