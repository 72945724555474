import cookie from 'react-cookies'
import authFetch from './authFetch'

//call to get the token we think we are logged in with
function getToken() {
    const token = cookie.load("token")
    //console.log("Found token " + token)
    if(!token) {
        //it looks like we are logged out from the value of this cookie we are seeing
        return 0
    } else {
        return token.token
    }
}

//gives 0 if not logged in or the userID if they are NOTE the server may not agree with this answer!
function appearsLoggedIn() {
    const token = cookie.load("token")
    //console.log("Found token " + token)
    if(!token) {
        //it looks like we are logged out from the value of this cookie we are seeing
        return 0
    } else {
        return token.userID
    }
}

//call to logout
function logOut() {
    //console.log("Removing token cookie")
    cookie.remove("token", {path: "/"})
}

function logIn(token, userID) {
    //save the cookie that is used to determine if the user is logged in in future
    cookie.save("token", {token, userID}, {path: "/"})
    //run a call to get the users defaults (siteGate, siteGateScale)
    //it is done at login so we don't make a call when first opneing the ticket screen
    //authFetch("/user/getQuickDefaults", {method: "GET"}, "/")
    //.then(d => {
        //setQuickDefaults(d.siteGateID, d.siteGateScaleID)
    //})
}

const blankQuickDefaults = {siteGateID: 0, siteGateScaleID1: 0, siteGateScaleID2: 0, siteGatePrinterID: 0}

function getQuickDefaults() {
    return new Promise(function(resolve, reject) {
        //first check the cookie
        var d = cookie.load("quickDefaults")
        if(checkQuickDefaults(d)) {
            resolve(d)
        } else {
            //check the api.  any failure here results in resolving with the blanks
            authFetch("/user/userPreference/defaults/quickDefaults", {method: "GET"})
            .then(r => {
                if(r.validationResult.fail) {
                    resolve(blankQuickDefaults)
                } else {
                    //it returned, but is it valid?
                    if(checkQuickDefaults(JSON.parse(r.data))) {
                        //set the cookie for faster recall next time
                        //set the defaults into a cookie for quick recall
                        cookie.save("quickDefaults", r.data, {path: "/", maxAge: 43200})
                        //and resolve
                        resolve(JSON.parse(r.data))
                    } else {
                        resolve(blankQuickDefaults)
                    }
                }
            })
            .catch(err => resolve(blankQuickDefaults))
        }   
    })
}

function checkQuickDefaults(qd) {
    //returns true if the passed object can be used as quickDefaults
    if(qd === undefined) {
        return false
    }
    if(qd.siteGateID === undefined || qd.siteGateScaleID1 === undefined || qd.siteGateScaleID2 === undefined || qd.siteGatePrinterID === undefined) {
        return false
    }
    return true
}

function setQuickDefaults(siteGateID, siteGateScaleID1, siteGateScaleID2, siteGatePrinterID) {
    return new Promise(function(resolve, reject) {
        if(siteGateID === undefined) {
            siteGateID = 0
        }
        if(siteGateScaleID1 === undefined) {
            siteGateScaleID1 = 0
        }
        if(siteGateScaleID2 === undefined) {
            siteGateScaleID2 = 0
        }
        if(siteGatePrinterID === undefined) {
            siteGatePrinterID = 0
        }
        var obj = {siteGateID, siteGateScaleID1, siteGateScaleID2, siteGatePrinterID}
        //set the defaults into a cookie for quick recall
        cookie.save("quickDefaults", JSON.stringify(obj), {path: "/", maxAge: 43200})
        //and also set them into the API for later recall
        //console.log("Setting up qd to " + JSON.stringify(obj))
        authFetch("/user/userPreference/defaults/quickDefaults", {method: "PUT", body: JSON.stringify({value: obj})})
        .then(na => resolve("ok"))

    })
}

//get a menu appropriate for the logged in user
// function getLeftMenu() {
//     return new Promise(function(resolve, reject) {
//         //console.log("Getting logged in left menu")
//         resolve(
            
//         )
//     })
        
    
        
// }

export {getToken, appearsLoggedIn, logOut, logIn, getQuickDefaults, setQuickDefaults, blankQuickDefaults}