import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import dateFormat from 'dateformat'

import ColsStoreSet from './colsStoreSet'
import ColsAnalysisFieldsSet from './colsAnalysisFieldsSet';
import HoursCell, { hoursValueFromTargets } from './hoursCell'

const blankTarget = {
    targetID: 0,
    storeID: 0,
    userID: 0,
    targetForUserID: 0,
    targetDate: new Date(),
    analysisFieldID: 0,
    analysisListItemID: 0,
    target: 0,
    isTarget: false,
    hours: 0,
    weight: 0,
}




export default function TargetsTable(props) {
    //console.log("Drawing targets: " + props.targets.length)

    return(
        <TableContainer component={Paper} style={{marginTop: "10px"}}>
            <Table aria-label="Targets table">
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2} style={{padding: "4px"}}><strong>User</strong></TableCell>
                        {
                            props.hoursInput ? 
                                <TableCell colSpan={2} align="center" style={{backgroundColor: "rgb(222,255,247)", padding: "4px"}}>
                                    <strong>
                                        Users hours
                                    </strong>
                                </TableCell>
                            : null
                        }            
                        <ColsStoreSet 
                            store={props.store}
                            rowType={"header1"}
                        />
                        <ColsAnalysisFieldsSet 
                            analysisFields={props.analysisFields}
                            rowType={"header1"}
                        />
                    </TableRow>
                    <TableRow>
                        {
                            props.hoursInput ? 
                                <>
                                    <TableCell align="center" style={{backgroundColor: "rgb(222,255,247)", padding: "4px"}}>
                                        <strong>
                                            Hours
                                        </strong>
                                    </TableCell>
                                    <TableCell align="center" style={{backgroundColor: "rgb(222,255,247)", padding: "4px"}}>
                                        <strong>
                                            Weight
                                        </strong>
                                    </TableCell>
                                </>
                            : null
                        }            
                        <ColsStoreSet 
                            store={props.store}
                            rowType={"header2"}
                        />
                        <ColsAnalysisFieldsSet 
                            analysisFields={props.analysisFields}
                            rowType={"header2"}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.users.map(u => (
                            <TableRow key={u.userID}>
                                <TableCell component="th" scope="row" style={{padding: "4px"}}>
                                    {u.name}
                                </TableCell>
                                {
                                    props.hoursInput ? 
                                        <>
                                            <TableCell style={{backgroundColor: "rgb(222,255,247)", padding: "4px"}}>
                                                <HoursCell
                                                    id="hours" 
                                                    label="Hours"
                                                    allTargets={props.targets}
                                                    onTargetsChange={props.onTargetsChange}
                                                    disabled={props.view !== "set"}
                                                    user={u}
                                                    users={props.users}
                                                    store={props.store}
                                                    analysisFields={props.analysisFields}
                                                    targetDate={props.targetDate}
                                                />
                                            </TableCell>
                                            <TableCell style={{backgroundColor: "rgb(222,255,247)", padding: "4px"}}>
                                                <HoursCell
                                                    id="weight" 
                                                    label="Weight"
                                                    allTargets={props.targets}
                                                    onTargetsChange={props.onTargetsChange}
                                                    disabled={props.view !== "set"}
                                                    user={u}
                                                    users={props.users}
                                                    store={props.store}
                                                    analysisFields={props.analysisFields}
                                                    targetDate={props.targetDate}
                                                />
                                            </TableCell>
                                        </>
                                    : null
                                } 
                                <ColsStoreSet 
                                    store={props.store}
                                    rowType={"row"}
                                    view={props.view}
                                    allTargets={props.targets}
                                    targets={props.targets.filter(t => (
                                        t.storeID === props.store.storeID &&
                                        dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                        t.targetForUserID === u.userID &&
                                        t.userID === u.userID &&
                                        t.analysisFieldID === 0
                                    ))}
                                    user={u}
                                    me={props.me}
                                    onChange={props.onTargetsChange}
                                    blankTarget={{
                                        ...blankTarget,
                                        storeID: props.store.storeID,
                                        targetDate: props.targetDate,
                                        targetForUserID: u.userID,
                                        userID: u.userID,
                                    }}
                                    highlightTargetID={props.highlightTargetID}
                                    hoursInput={props.hoursInput}
                                />
                                <ColsAnalysisFieldsSet 
                                    analysisFields={props.analysisFields}
                                    rowType={"row"}
                                    view={props.view}
                                    allTargets={props.targets}
                                    targets={props.targets.filter(t => (
                                        t.storeID === props.store.storeID &&
                                        dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                        t.targetForUserID === u.userID &&
                                        t.userID === u.userID &&
                                        t.analysisFieldID !== 0
                                    ))}
                                    user={u}
                                    me={props.me}
                                    onChange={props.onTargetsChange}
                                    blankTarget={{
                                        ...blankTarget,
                                        storeID: props.store.storeID,
                                        targetDate: props.targetDate,
                                        targetForUserID: u.userID,
                                        userID: u.userID,
                                    }}
                                    highlightTargetID={props.highlightTargetID}
                                    hoursInput={props.hoursInput}
                                />
                            </TableRow>
                        ))
                    }
                    <TableRow>
                        <TableCell component="th" scope="row" style={{padding: "4px"}}>
                            <strong>Totals</strong>
                        </TableCell>
                        {
                            props.hoursInput ? 
                                <>
                                    <TableCell colSpan={2} style={{backgroundColor: "rgb(222,255,247)", padding: "4px"}}>
                                        Hours: {props.users.map(u => hoursValueFromTargets(props.targets, "hours", props.targetDate, props.store, u)).reduce((acc,cur) => acc + parseFloat(cur),0)}
                                    </TableCell>
                                </>
                            : null
                        } 
                        <ColsStoreSet 
                            store={props.store}
                            rowType={"total"}
                            view={props.view}
                            totalTargets={props.targets.filter(t => (
                                t.storeID === props.store.storeID &&
                                dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                t.analysisFieldID === 0 &&
                                t.userID !== 0 //only summing targets of users
                            ))}
                            targets={props.targets.filter(t => (
                                t.storeID === props.store.storeID &&
                                dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                t.analysisFieldID === 0 &&
                                t.userID !== 0 //only summing targets of users
                            ))}
                            hoursInput={props.hoursInput}
                            allTargets={props.targets}
                            onChange={props.onTargetsChange}
                        />
                        <ColsAnalysisFieldsSet 
                            analysisFields={props.analysisFields}
                            rowType={"total"}
                            view={props.view}
                            totalTargets={props.targets.filter(t => (
                                t.storeID === props.store.storeID &&
                                dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                t.analysisFieldID !== 0 &&
                                t.userID !== 0 //only summing targets of users
                            ))}
                            targets={props.targets.filter(t => (
                                t.storeID === props.store.storeID &&
                                dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                t.analysisFieldID !== 0 &&
                                t.userID !== 0 //only summing targets of users
                            ))}
                            hoursInput={props.hoursInput}
                            allTargets={props.targets}
                            onChange={props.onTargetsChange}
                        />
                    </TableRow>
                    {
                        props.users.map(u => (
                            <TableRow key={u.userID}>
                                <TableCell component="th" scope="row" style={{padding: "4px"}}>
                                    {u.name}
                                </TableCell>
                                {
                                    props.hoursInput ? 
                                        <>
                                            <TableCell style={{backgroundColor: "rgb(222,255,247)", padding: "4px"}}>
                                                
                                            </TableCell>
                                            <TableCell style={{backgroundColor: "rgb(222,255,247)", padding: "4px"}}>
                                                
                                            </TableCell>
                                        </>
                                    : null
                                } 
                                <ColsStoreSet 
                                    store={props.store}
                                    rowType={"totalTarget"}
                                    view={props.view}
                                    allTargets={props.targets}
                                    totalTargets={props.targets.filter(t => (
                                        t.storeID === props.store.storeID &&
                                        dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                        t.analysisFieldID === 0 &&
                                        t.userID !== 0 //only summing targets of users
                                    ))}
                                    targets={props.targets.filter(t => (
                                        t.storeID === props.store.storeID &&
                                        dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                        t.analysisFieldID === 0 &&
                                        t.targetForUserID === u.userID &&
                                        t.userID === 0
                                    ))}
                                    user={u}
                                    me={props.me}
                                    onChange={props.onTargetsChange}
                                    blankTarget={{
                                        ...blankTarget,
                                        storeID: props.store.storeID,
                                        targetDate: props.targetDate,
                                        targetForUserID: u.userID,
                                    }}
                                    highlightTargetID={props.highlightTargetID}
                                />
                                <ColsAnalysisFieldsSet 
                                    analysisFields={props.analysisFields}
                                    rowType={"totalTarget"}
                                    view={props.view}
                                    allTargets={props.targets}
                                    totalTargets={props.targets.filter(t => (
                                        t.storeID === props.store.storeID &&
                                        dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                        t.analysisFieldID !== 0 &&
                                        t.userID !== 0 //only summing targets of users
                                    ))}
                                    targets={props.targets.filter(t => (
                                        t.storeID === props.store.storeID &&
                                        dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(props.targetDate, "yyyy-mm-dd") &&
                                        t.analysisFieldID !== 0 &&
                                        t.targetForUserID === u.userID &&
                                        t.userID === 0
                                    ))}
                                    user={u}
                                    me={props.me}
                                    onChange={props.onTargetsChange}
                                    blankTarget={{
                                        ...blankTarget,
                                        storeID: props.store.storeID,
                                        targetDate: props.targetDate,
                                        targetForUserID: u.userID,
                                    }}
                                    highlightTargetID={props.highlightTargetID}
                                />
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export {blankTarget}