import React, {useState, useEffect} from 'react'
import dateFormat from 'dateformat'

import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import DailyStatEntityForm from './dailyStatEntityForm'
import { formatMoney } from '../../../../lib/general'
import DailyStatFilters from './dailyStatFilters'

const columnData = [
    { fieldName: 'dailyStatID', default: false, name: 'ID', description: "The unchanging identifier of the daily stat record"},
    { fieldName: 'dailyStatDate', default: true, name: 'Sale Date', description: "The date these stats were recorded for", renderCell: dr => dateFormat(new Date(dr.dailyStatDate), "dd/mm/yyyy")},
    { fieldName: 'storeName', default: true, name: 'Store', description: "Store these stats relate to"},
    { fieldName: 'footfall', default: true, name: 'Footfall', description: "Footfall recorded on this day", alignRight: true, renderCell: dr => formatMoney(dr.footfall)},
    { fieldName: 'chatCount', default: true, name: 'Chats', description: "Number of chats recorded on this day", alignRight: true, renderCell: dr => formatMoney(dr.chatCount)},
    { fieldName: 'staffCount', default: true, name: 'Staff', description: "Number of staff recorded on this day", alignRight: true, renderCell: dr => formatMoney(dr.staffCount)},

];

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default function SalesTable(props) {
    const [filters, setFilters] = useState(props.filters === undefined ? {} : props.filters)

    //watch for new filters being passed in from the parent
    useEffect(() => {
        //override the current filters with the new ones passed in from the parent.  Eg they have changed tab or something
        setFilters(props.filters)
    }, [JSON.stringify(props.filters)])

    function handleFiltersChange(newFilters) {
        setFilters(newFilters)
    }

    return(
        <div>
            <DailyStatFilters
                filters={filters}
                onFiltersChange={handleFiltersChange}
            />
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={DailyStatEntityForm}
                filters={filters} 
                title={props.title}
                enableCrud={false} //api does not do it like a normal entity form so can't crud it
                sort={props.sort}
                sortDir={props.sortDir}
                me={props.me}
                enableRead={true}
            />
        </div>
    )
}