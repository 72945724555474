import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

//project
import CheckBox from '../../../shared/checkBox'
import { Paper } from '@material-ui/core'
import FieldSet from './fieldSet'
import { accessibleStores } from '../../../../lib/general'

const blankDiscountStore = {
    storeID: 0, 
    enabled: true, 
    discountID: 0, 
    captureVariablePercent: false,
    revenueReductionIsFixed: true,
    commissionReductionIsFixed: true,
    revenueReductionFixed: "0",
    commissionReductionFixed: "0",
    revenueReductionRatio: "0",
    commissionReductionRatio: "0",
    commissionSameAsRevenue: true,
}

export default function DiscountStoreSelector(props) {
    const stores = props.me === undefined ? [] : accessibleStores(props.me)

    function handleChange(event, storeID, field) {
        //some fields require a decimal value
        var decimal = new RegExp("^[0-9]*([.][0-9]{0,2}){0,1}$")
        //find the relevent store being updated, and update its value
        var newVals = []
        props.value.forEach(s => {
            var thisOne = {...s}
            if(s.storeID === storeID) {
                //only making the change if it doesnt violate the decimal validation
                if(!(["revenueReductionFixed", "commissionReductionFixed", "revenueReductionRatio", "commissionReductionRatio"].includes(field) && !decimal.test(event.target.value))) {
                    thisOne[field] = event.target.value
                    //cascades
                    //if setting captureVariablePercent to false then also set fixed to true
                    if(field === "captureVariablePercent" && !event.target.value) {
                        thisOne.revenueReductionIsFixed = true
                        thisOne.commissionReductionIsFixed = true
                    }
                }
            }
            newVals.push(thisOne)
        })
        //construct a pseudo event to pass up to the parent form
        buildEvent(newVals)
    }

    function buildEvent(withNewVal) {
        var e = {
            target: {
                id: props.id,
                value: withNewVal
            }
        }
        //console.log("Calling change with " + JSON.stringify(withNewVal))
        props.onChange(e)
    }

    function handleEnableChange(event, storeID) {
        //decide if we're adding or removing it
        var newVals = []
        if(event.target.value) {
            //they have ticked
            //if it exists then set enabled to true, else add it
            if(props.value.filter(s => s.storeID === storeID).length === 1) {
                //it exists
                props.value.forEach(s => {
                    var thisOne = {...s}
                    if(s.storeID === storeID) {
                        thisOne.enabled = true
                    }
                    newVals.push(thisOne)
                })
            } else {
                //it does not exist, add it
                newVals.push(...props.value, 
                {
                    ...blankDiscountStore,
                    storeID: storeID, 
                    discountID: props.discountID, 
                })
            }
        } else {
            //they have unticked
            //set it as inactive.  unless revenue and commission is "" then delete it
            props.value.forEach(s => {
                var thisOne = {...s}
                if(s.storeID === storeID) {
                    thisOne.enabled = false
                }
                newVals.push(thisOne)
            })
        }
        //construct a pseudo event to pass up to the parent form
        buildEvent(newVals)
    }

    function handleCopyToAll(store) {
        //copy this stores discount settings to all the others
        var newVals = []
        //get the one we are copying
        var copyThis = props.value.filter(l => l.storeID === store.storeID)[0]
        //make the new newVals
        newVals = stores.map(s => ({
            ...copyThis,
            storeID: s.storeID
        }))
        buildEvent(newVals)
    }

    return (
        <div>
            {stores.map(s => {
                var captureVariablePercent = props.value.filter(l => l.storeID === s.storeID).length === 1 ? (props.value.filter(l => l.storeID === s.storeID)[0]).captureVariablePercent : false
                var commissionSameAsRevenue = props.value.filter(l => l.storeID === s.storeID).length === 1 ? (props.value.filter(l => l.storeID === s.storeID)[0]).commissionSameAsRevenue : false
                var enabled = props.value.filter(ts => s.storeID === ts.storeID && ts.enabled).length === 1

                return (
                    <Paper elevation={3} style={{marginTop: "10px", padding: "16px"}} key={s.storeID}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{alignItems: "flex-end", display: "flex"}}>
                                <CheckBox 
                                    disabled={props.disabled} 
                                    label={"Enable " + props.name + " at " + s.name} 
                                    onChange={e => handleEnableChange(e, s.storeID)} 
                                    value={enabled} 
                                />
                            </Grid>
                            <Grid item xs={6} style={{textAlign: "right"}}>
                                <Button onClick={() => handleCopyToAll(s)}>
                                    Copy to all
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{alignItems: "flex-end", display: "flex"}}>
                                <CheckBox 
                                    disabled={props.disabled || !enabled} 
                                    label={"Capture applied percent"} 
                                    onChange={e => handleChange(e, s.storeID, "captureVariablePercent")} 
                                    value={captureVariablePercent}
                                />
                            </Grid>
                            <Grid item xs={6} style={{alignItems: "flex-end", display: "flex"}}>
                                <CheckBox 
                                    disabled={props.disabled || !enabled} 
                                    label={"Commission same as revenue"} 
                                    onChange={e => handleChange(e, s.storeID, "commissionSameAsRevenue")} 
                                    value={commissionSameAsRevenue}
                                />
                            </Grid>
                            <Grid item xs={12} style={{alignItems: "flex-end", display: "flex"}}>
                                <Grid container spacing={6}>
                                    <Grid item xs={commissionSameAsRevenue || props.value.filter(l => l.storeID === s.storeID).length === 0 ? 12 : 6}>
                                        <FieldSet
                                            title={commissionSameAsRevenue || props.value.filter(l => l.storeID === s.storeID).length === 0 ? "Revenue and commission" : "Revenue"}
                                            fieldPrefix="revenue"
                                            captureVariablePercent={captureVariablePercent}
                                            value={props.value}
                                            store={s}
                                            onChange={handleChange}
                                            disabled={props.disabled || !enabled}
                                            me={props.me}
                                        />
                                    </Grid>
                                    {
                                        commissionSameAsRevenue || props.value.filter(l => l.storeID === s.storeID).length === 0 ? null :
                                        <Grid item xs={6}>
                                            <FieldSet
                                                title="Commission"
                                                fieldPrefix="commission"
                                                captureVariablePercent={captureVariablePercent}
                                                value={props.value}
                                                store={s}
                                                onChange={handleChange}
                                                disabled={props.disabled || !enabled || commissionSameAsRevenue}
                                                me={props.me}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                )
            })}
        </div>

    )
}

export {blankDiscountStore}