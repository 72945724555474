import React from 'react'

import MatchI from '@material-ui/icons/CheckOutlined'
import NoMatchI from '@material-ui/icons/ReportProblemOutlined'

export default function MatchIcon(props) {
    return (
        props.value ? 
            <MatchI style={{color: "#4caf50"}} />
        :
            <NoMatchI style={{color: "#f44336"}} />
    )
}