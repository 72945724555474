import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'

import CrudModal from '../../shared/crudModal'
import saleEntityForm from '../sales/entityForm/saleEntityForm'
import UnrecModal from '../sales/entityForm/unrecModal'
import { formatMoney } from '../../../lib/general'

export default function reconciledAlert(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [unrecOpen, setUnrecOpen] = useState(false)
    
    function handleModalClose() {
        setModalOpen(false)
    }

    function handleClick() {
        setModalOpen(true)
    }

    function handleUnrecClick() {
        setUnrecOpen(true)
    }

    function handleUnrecAction() {
        setUnrecOpen(false)
        //we must also close the parent modal in order to force a reload
        props.onDataChanged()
    }

    function handleUnrecCancel() {
        setUnrecOpen(false)
    }

    return (
        props.formVals.saleID === 0 ?
            <p>This rec sheet line is not yet reconciled</p>
        :
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Alert style={{margin: "8px"}} severity="success" action={
                        <Button onClick={handleClick}>
                            View
                        </Button>
                    }>
                        This line is reconciled with sale <strong>{props.formVals.saleID}</strong> by {props.formVals.recByUserName} at {dateFormat(props.formVals.recDate, "dd/mm/yyyy HH:MM:ss")}.               
                    </Alert>
                    {//showing a warning if revenue was adjusted
                        (props.formVals.sale === undefined ? 0 : props.formVals.sale.adjustment) === 0 ? null : 
                        <Alert style={{margin: "8px"}}
                            severity="warning"
                        >
                            Revenue was adjusted by £{formatMoney(props.formVals.sale.adjustment)} to make the sale match the revenue sheet! 
                        </Alert>
                    }
                    <CrudModal
                        action="read"
                        open={modalOpen}
                        id={props.formVals.saleID}
                        entityForm={saleEntityForm}
                        onClose={handleModalClose}
                        me={props.me}
                    />
                </Grid>
                {
                    props.me.accessProfile.systemAreas.includes("RevenueReconciliation") ?
                        <Grid item xs={12} sm={12}>
                            <Button variant="contained" color="secondary" onClick={handleUnrecClick}>
                                Unreconcile
                            </Button>
                            <UnrecModal
                                open={unrecOpen}
                                onAction={handleUnrecAction}
                                onCancel={handleUnrecCancel}
                                saleID={props.formVals.saleID}
                            />
                        </Grid>
                    : null
                }
                
            </Grid> 
    )
}