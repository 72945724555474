import React, {useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core'

import CancelModal from './cancelModal'
import RefundModal from './refundModal'
import CreditFailModal from './creditFailModal'

export default function RefundAndCancel(props) {
    const [cancelOpen, setCancelOpen] = useState(false)
    const [refundOpen, setRefundOpen] = useState(false)
    const [creditFailOpen, setCreditFailOpen] = useState(false)

    //###########cancellation functions##################

    function handleCancelSale(note) {
        //the user has indicated they want to cancel the sale and completed the modal
        props.onChange({...props.formVals, newStep: "cancelled", newStepNote: note})
    }

    function handleCancelCancel() {
        //they said they wanted to cnacel, now they don't
        props.onChange({...props.formVals, newStep: undefined, newStepNote: undefined})
    }

    //###########refund functions##################

    function handleRefundSale(note, refundRef) {
        //the user has indicated they want to refund the sale and completed the modal
        props.onChange({...props.formVals, isRefunded: true, isRefundedNote: note, isRefundedOrderRef: refundRef})
    }

    function handleRefundCancel() {
        //they said they wanted to refund, now they don't
        props.onChange({...props.formVals, isRefunded: undefined, isRefundedNote: undefined, isRefundedOrderRef: undefined})
    }

    //##############credit fail functions#####################

    function handleCreditFailSale() {
        //the user has indicated they want to record the sale as credit fail (cancel) and completed the modal
        //we will also put in a dummy order number, to save the user from this validation error
        props.onChange({...props.formVals, newStep: "cancelled", newStepNote: "Credit check fail", orderRef: "CUSCREDITCHECKFAILED"})
    }

    function handleCreditFailCancelClick() {
        //they said they wanted to record as credit fail, now they don't
        props.onChange({...props.formVals, newStep: undefined, newStepNote: undefined, orderRef: ""})
    }

    return (
        <>
            { //cancellation bits
                !props.formVals.isRefunded && !props.disabled && ["recorded", "modified"].includes(props.formVals.currentHistoryStep) ?
                    //the status is one that this sale can be cancelled
                    //is it currently cancelled?
                    props.formVals.newStep === "cancelled" ? 
                        //yes it is going to be cancelled when it is saved
                        <div>
                            <Alert severity="error" action={
                                <Button onClick={handleCancelCancel} color="default">
                                    Cancel
                                </Button>
                            }>
                                <strong>Cancelling</strong> this sale when you click save, reason: '{props.formVals.newStepNote}'
                            </Alert>
                            {
                                props.validationResult.failMessages.newStep === undefined ? null :
                                <FormHelperText error={true}>
                                    {props.validationResult.failMessages.newStep}
                                </FormHelperText>
                            }
                        </div>
                    :
                        //no it is not currently cancelled
                        <Button style={{margin: "12px 12px 12px 0px"}} color="secondary" variant="contained" onClick={() => setCancelOpen(true)} >
                            Cancel {props.formVals.isRefund ? "Refund" : "Sale"}
                        </Button>
                : null
            }
            { //refund bits
                props.me.accessProfile.systemAreas.includes("RecordRefunds") && props.formVals.newStep !== "cancelled" && !props.disabled && ["recorded", "modified", "reconciled"].includes(props.formVals.currentHistoryStep) && !(props.formVals.isRefunded && props.formVals.isRefundedOrderRef === undefined) && !props.formVals.isRefund ?
                    //the status is one that this sale can be cancelled
                    //is it currently being refunded?
                    props.formVals.isRefundedOrderRef === undefined ? 
                        //no it is not currently being refunded
                        <Button style={{margin: "12px 12px 12px 0px"}} color="secondary" variant="contained" onClick={() => setRefundOpen(true)} >
                            Refund sale
                        </Button>
                    :
                        //yes it is going to be refunded when it is saved
                        <div>
                            <Alert severity="warning" action={
                                <Button onClick={handleRefundCancel} color="default">
                                    Cancel
                                </Button>
                            }>
                                <strong>Refunding</strong> this sale when you click save, refund order reference: '{props.formVals.isRefundedOrderRef}', notes: '{props.formVals.isRefundedNote}'
                            </Alert>
                            {
                                props.validationResult.failMessages.isRefunded === undefined ? null :
                                <FormHelperText error={true}>
                                    {props.validationResult.failMessages.isRefunded}
                                </FormHelperText>
                            }
                        </div>
                : null
            }
            { //credit fail bits
                props.me.accessProfile.systemAreas.includes("RecordCreditFails") && props.formVals.saleID === 0 && !props.disabled && ["recording"].includes(props.formVals.currentHistoryStep) && !props.formVals.isRefund ?
                    //the status is one that this sale can be recorded as credit fail.  In other words its a new sale
                    //is it already due to be cancelled?  ie has it already been marked as credit failed?
                    props.formVals.newStep === "cancelled" ? 
                        //yes.  give them the opportunity to cancel the credit check fail
                        <div>
                            <Alert severity="error" action={
                                <Button onClick={handleCreditFailCancelClick} color="default">
                                    Cancel
                                </Button>
                            }>
                                <strong>Sale will be recorded as cancelled</strong> due to credit check fail
                            </Alert>
                            {
                                props.validationResult.failMessages.newStep === undefined ? null :
                                <FormHelperText error={true}>
                                    {props.validationResult.failMessages.newStep}
                                </FormHelperText>
                            }
                        </div>
                    :
                        //no it is not currently cancelled/credit check fail
                        <Button style={{margin: "12px 12px 12px 0px"}} color="secondary" variant="contained" onClick={() => setCreditFailOpen(true)} >
                            Record Credit Check Fail
                        </Button>
                : null
            }
            <CancelModal 
                onCancelSale={handleCancelSale}
                onClose={() => setCancelOpen(false)}
                open={cancelOpen}
            />
            <RefundModal 
                onRefundSale={handleRefundSale}
                onClose={() => setRefundOpen(false)}
                open={refundOpen}
            />
            <CreditFailModal 
                onCreditFailSale={handleCreditFailSale}
                onClose={() => setCreditFailOpen(false)}
                open={creditFailOpen}
            />
        </>
    )
}