import React, {useState} from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import {byName} from '../../lib/general'

export default function StoreTabs(props) {
    const sortedStores = props.me.accessProfile.accessProfileStores.map(aps => aps.store).sort(byName)

    //if showAll is enabled then there is an extra tab so the number of tabs is incremented by 1
    const allInc = props.showAll ? 1 : 0

    const handleTabChange = (event, newVal) => {
        props.onChange(sortedStores.reduce((acc, cur, i) => (i + allInc) === newVal ? cur : acc, "all"))
    };

    return(
        <Paper square>
            <Tabs
                variant="scrollable" scrollButtons="auto"
                value={props.store === "all" ? 0 : sortedStores.reduce((acc, cur, i) => cur.storeID === props.store.storeID ? (i + allInc) : acc, false)}
                onChange={handleTabChange}
            >
                {
                    props.showAll ? 
                        <Tab 
                            style={{minWidth: "80px"}} 
                            label={"All"} 
                        />
                    : null
                }
                {
                    sortedStores.map(s => 
                        <Tab 
                            style={{minWidth: "80px"}} 
                            label={s.name} 
                            key={s.name} 
                        />
                    )
                }
            </Tabs>
        </Paper>
    )
}