import React from 'react'
import { Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'

import TrendIcon from '../../../shared/trendIcon'
import TopIcon from '@material-ui/icons/Whatshot'
import { formatMoney } from '../../../../lib/general'
import KPICard from './kpiCard'
import TopButtons from '../../sales/topButtons'
import {paramsToSaleURL} from '../../sales/general' 

export default withRouter(function TransactionKPICard(props) {
    //return a value to represent the percentage increase/decreease from the comparator b
    function calculatePercent(a, b) {
        if(b === 0) {
            return 100
        } else {
            return Math.floor((a - b) * 1000 / b) / 10
        }
    }
    
    function percentString(a, b) {
        if(calculatePercent > 0) {
            return "+" + formatMoney(calculatePercent(a, b), 1) + "%"
        } else {
            return formatMoney(calculatePercent(a, b), 1) + "%"
        }
    }

    function handleClick(to) {
        props.history.push(to)
    }

    function saleURL(period, storeID, attributableToUserID, sku) {
        var params = {
            ...props.value[period].params
        }
        if(storeID !== undefined) {params.storeID = storeID}
        if(attributableToUserID !== undefined) {params.attributableToUserID = attributableToUserID}
        if(sku !== undefined) {params.sku = sku}
        return paramsToSaleURL(params)
    }

    const fieldName = (props.revenueCommission === "revenue" ? "adjustedRevenueTotal" : "commissionTotal")
    const topFieldName = (props.revenueCommission === "revenue" ? "Revenue" : "Commission")

    return (
        <KPICard
            action={
                <TopButtons 
                    me={props.me}
                    filters={props.value.current.params}
                    showTopUser={props.value.current["topUser" + topFieldName] !== undefined}
                    showTopSKU={props.value.current["topSKU" + topFieldName] !== undefined}
                    showTopStore={props.value.current["topStore" + topFieldName] !== undefined}
                    size="small"
                    sort={fieldName}
                />
            }
            minHeight={props.minHeight}
        >
            <Typography color="textSecondary" gutterBottom>
                {props.currentDisplayName}
            </Typography>
            <Typography variant="h5" component="h2">
                <span style={{cursor: "pointer"}} onClick={() => handleClick(saleURL("current"))}>{props.value.current.revenueCount} {props.revenueCommission === "revenue" ? "revenue" : "commission"} £{formatMoney(props.value.current[fieldName], 0)}</span>
            </Typography>
            <Typography color="textSecondary">
                <span style={{cursor: "pointer"}} onClick={() => handleClick(saleURL("prior"))}>
                    <TrendIcon value={calculatePercent(props.value.current[fieldName], props.value.prior[fieldName])} />
                    {percentString(props.value.current[fieldName], props.value.prior[fieldName])} vs {props.priorDisplayName}
                </span>
                {
                    props.showPriorYear ? 
                        <span style={{cursor: "pointer"}} onClick={() => handleClick(saleURL("py"))}>
                            <br />
                            <TrendIcon value={calculatePercent(props.value.current[fieldName], props.value.py[fieldName])} />
                            {percentString(props.value.current[fieldName], props.value.py[fieldName])} vs this period last year
                        </span>
                    : null
                }
            </Typography>
            <Typography variant="body2" component="p">
                {
                    props.value.current["topSKU" + topFieldName] !== undefined ?
                        <Tooltip title={props.value.current["topSKU" + topFieldName].rateCardLineDescription + ""}>
                            <span style={{cursor: "pointer"}} onClick={() => handleClick(saleURL("current", undefined, undefined, props.value.current["topSKU" + topFieldName].sku))}>
                                <TopIcon />SKU: {props.value.current["topSKU" + topFieldName].sku} ({props.value.current["topSKU" + topFieldName].revenueCount} £{formatMoney(props.value.current["topSKU" + topFieldName][fieldName],0)})
                            </span>
                        </Tooltip>
                    : null
                }
                {
                    props.value.current["topUser" + topFieldName] !== undefined ?
                        <span style={{cursor: "pointer"}} onClick={() => handleClick(saleURL("current", undefined, props.value.current["topUser" + topFieldName].attributableToUserID))}>
                            <br /><TopIcon />User: {props.value.current["topUser" + topFieldName].attributableToUserName} ({props.value.current["topUser" + topFieldName].revenueCount} £{formatMoney(props.value.current["topUser" + topFieldName][fieldName],0)})
                        </span>
                    : null
                }
                {
                    props.value.current["topStore" + topFieldName] !== undefined ?
                        <span style={{cursor: "pointer"}} onClick={() => handleClick(saleURL("current", props.value.current["topStore" + topFieldName].storeID))}>
                            <br /><TopIcon />Store: {props.value.current["topStore" + topFieldName].storeName} ({props.value.current["topStore" + topFieldName].revenueCount} £{formatMoney(props.value.current["topStore" + topFieldName][fieldName],0)})
                        </span>
                    : null
                }
            </Typography>
        </KPICard>
    )
})