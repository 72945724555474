import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import {formatMoney} from '../../../../lib/general'

export default function TotalCol(props) {
    return (
        <>
            <TableCell scope="row" style={{padding: "4px", backgroundColor: props.shaded ? "#e8e8e8" : undefined, textAlign: "right"}}>
                <strong>
                    {
                        props.quantity
                    }
                </strong>
            </TableCell>
            {
                props.me === undefined || !props.me.accessProfile.showRevenue ? null :
                <TableCell scope="row" style={{padding: "4px", backgroundColor: props.shaded ? "#e8e8e8" : undefined, textAlign: "right"}}>
                        <strong>
                            {
                                formatMoney(props.revenue, 2)
                            }
                        </strong>
                </TableCell>
            }
        </>
    )
}