import React from 'react'
import { Grid, Tooltip, TextField } from '@material-ui/core'
import OKIcon from '@material-ui/icons/Check'
import NOKIcon from '@material-ui/icons/Clear'

export default function apiParams(props) {
    function handleChange(e, param) {
        var newParamVals = []
        //removing it?
        if(e.target.value === "") {
            //yes add every one apart from this
            props.paramVals.forEach(x => {
                if(x.param !== param.param) {
                    newParamVals.push(x)
                }
            })
        } else {
            const reg = new RegExp(param.regex)
            //not removing it, it should exist
            if(props.paramVals.map(x => x.param).includes(param.param)) {
                //it exists.  update it
                newParamVals.push(
                    ...props.paramVals.map(x => ({
                        param: x.param,
                        value: x.param === param.param ? e.target.value : x.value,
                        valid: x.param === param.param ? reg.test(e.target.value) : x.valid,
                    }))
                )
            } else {
                //it does not exist, add it
                newParamVals.push(
                    ...props.paramVals,
                    {
                        param: param.param,
                        value: e.target.value,
                        valid: reg.test(e.target.value)
                    }
                )
            }
        }
        props.onChange(newParamVals)
    }

    return (
        <div>
            These filters control what data will be downloaded:
            <Grid container spacing={3} style={{marginTop: "12px"}}>
                {
                    props.params.map(p => {
                        var thisParamVals = props.paramVals.filter(pv => pv.param === p.param)
                        var thisParamVal = thisParamVals.length === 0 ? undefined : thisParamVals[0]

                        return (
                            <React.Fragment key={p.param}>
                                <Grid item xs={3}>
                                    <strong>{p.param}</strong><br/>
                                    {p.name}
                                </Grid>
                                <Grid item xs={3}>
                                    Format: {p.format}<br/>
                                    <span style={{fontStyle: "italic"}}>Example: {p.example}</span>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        margin="dense"
                                        label={p.name + " value"}
                                        type="text"
                                        value={thisParamVal === undefined ? "" : thisParamVal.value}
                                        onChange={e => handleChange(e, p)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {
                                        thisParamVal === undefined || !thisParamVal.valid ?
                                            <Tooltip title={thisParamVal === undefined ? "Type a value in the box to use this filter" : "Check the format of the value"}>
                                                <div>
                                                    <NOKIcon />
                                                </div>
                                            </Tooltip>
                                        :
                                            <Tooltip title="Format is OK">
                                                <div>
                                                    <OKIcon />
                                                </div>
                                            </Tooltip>
                                    }
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }
                
            </Grid>
        </div>
    )
}