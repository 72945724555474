import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import StoresList from './storesList'

export default function Stores(props) {
    return(
        <div>
            <Route path="/data/stores" component={StoresList} />
        </div>
    )
}