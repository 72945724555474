import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Switch, Route, Link} from 'react-router-dom'
import { accessibleStores } from '../../../lib/general';

function sortStores(a, b) {
    if (a.store.name < b.store.name) {
        return -1;
      }
      if (a.store.name > b.store.name) {
        return 1;
      }
}

export default function SalesToolbar(props) {
    return(
        <Switch>
            <Route path="/sales/calculator" render={renderProps => null} />
            <Route path="/sales" render={renderProps => <StoresToolbar {...renderProps} me={props.me} />} />
        </Switch>
    )
}

function StoresToolbar(props) {
    //get the accessible stores
    var usersStores = accessibleStores(props.me)
    //calculate the tab value
    let tabValue = false
    //if the user only has one store, just show that
    if(props.location.pathname === "/sales") {tabValue = 0}
    if(usersStores.length > 1) {
        usersStores.forEach((aps, i) => {
            //is it a single store?
            var lookingForPathEnd = "/sales/" + aps.storeID
            if(props.location.pathname.substring(props.location.pathname.length - lookingForPathEnd.length - 1) === lookingForPathEnd) {
                tabValue = i + 1
            } 
        })
        if(tabValue === false) {
            //they have access to multiple stores and they are not looking at a singe store
            //are they therefore looking at all stores?
            //assume yes since the GUI has no other options
            tabValue = 0
        }
    }

    var wideTabs = useMediaQuery('(min-width:1280px)')

    return (
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
            <Tab style={{minWidth: (wideTabs ? "160px" : "100px")}} label={usersStores.length === 1 ? usersStores[0].name : "All stores"} component={Link} to={"/sales"} />
            {
                usersStores.length > 1 ?
                usersStores.map(aps => {
                        return (
                            <Tab style={{minWidth: (wideTabs ? "160px" : "100px")}}key={aps.name} label={aps.name} component={Link} to={"/sales/" + aps.storeID} />
                        )
                    })
                : undefined
            }
            
        </Tabs>
    );
}