import React, {useState, useEffect} from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Badge from '@material-ui/core/Badge'

import authFetch from '../../../../lib/authFetch'

export default function SaleTypeTabs(props) {
    const [rateCardLines, setRateCardLines] = useState([])

    const handleTabChange = (event, newValue) => {
        props.onChange(props.saleTypes.reduce((acc, cur, i) => i === newValue ? cur : acc, props.blankForm));
    };

    //constantly check for updates
    useEffect(() => {
        checkUpdates()
        const interval = setInterval(() => checkUpdates(), 10000)
        return () => clearInterval(interval)
    }, [])

    function checkUpdates() {
        authFetch("/rateCardLines?rateCardID=" + props.rateCardID)
        .then(r => {
            setRateCardLines(r.listData)
        })
        .catch(err => {
            //notihgn to do, the most reliable data is that last got
        })
    }

    return(
        <Paper square>
            <Tabs
                variant="scrollable" scrollButtons="auto"
                value={props.saleTypes.reduce((acc, cur, i) => cur.saleTypeID === props.saleType.saleTypeID ? i : acc, false)}
                onChange={handleTabChange}
            >
                {
                    props.saleTypes.map(st => 
                        <Tab 
                            label={<Badge max={999} badgeContent={rateCardLines.filter(rcl => rcl.saleTypeID === st.saleTypeID).length} color="secondary">{st.name}</Badge>} 
                            key={st.saleTypeID} 
                        />
                    )
                }
            </Tabs>
        </Paper>
    )
}