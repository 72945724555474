import React, {useState} from 'react'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import Dropzone from 'react-dropzone'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import ValidationModal from './validationModal'
import authFetch from '../../../../lib/authFetch'
import MultipleModal from './multipleModal'

export default function OrderRefField(props) {
    const [loading, setLoading] = useState(false)
    const [failSummary, setFailSummary] = useState("")
    const [validationModalOpen, setValidationModalOpen] = useState(false)
    const [multipleOpen, setMultipleOpen] = useState(false)
    const [multipleData, setMultipleData] = useState({})

    function handleDrop(acceptedFiles) {
        //reset the data from any previous run
        setMultipleData({})
        //was there any acceptable file?
        if(acceptedFiles.length > 0) {
            //there is a file, do the ocr
            setLoading(true)
            setFailSummary("")
            //make a formdata of the file in the file upload
            var formData = new FormData();
            formData.append('file', acceptedFiles[0]);
            formData.append('saleTypeID', props.formVals.saleTypeID);
            formData.append('rateCardID', props.currentRateCardID);
            formData.append('currentStoreID', props.formVals.storeID); //suggesting the current storeID.  Will be overwritten if we find a new store on the scan
            authFetch("/file/saleOcr", {method: "POST", body: formData}, true)
            .then(r => {
                //have done the ocr now
                setLoading(false)
                //was it a success?
                if(r.validationResult.fail) {
                    //there was a problem, show it in the validation text
                    setFailSummary(r.validationResult.failSummary)
                    setValidationModalOpen(true)
                } else {
                    var newOrderRef = r.data.orderRefs.length > 0 ? r.data.orderRefs[0] : undefined
                    var newSaleDate = r.data.saleDates.length > 0 ? r.data.saleDates[0] : undefined
                    //do we have a single rateCardLineStoreID or multiple (it's common to sell multiple things at once)
                    if(r.data.rateCardLineStores.length > 1) {
                        //we are dealing with multiple.  set the state data and open the multiple resolution modal
                        setMultipleData({
                            orderRef: newOrderRef,
                            saleDate: newSaleDate,
                            rateCardLineStores: r.data.rateCardLineStores,
                            saleAnalysisValues: r.data.saleAnalysisValues,
                        })
                        setMultipleOpen(true)
                    } else {
                        //we are dealing with a single, go ahead and do it
                        var newRateCardLineStoreID = r.data.rateCardLineStores.length > 0 ? r.data.rateCardLineStores[0].rateCardLineStoreID : undefined
                        //in the future need to pop up a modal asking what to choose if there are multiple of any of the above
                        props.onOcrChanges(
                            newOrderRef, 
                            newSaleDate, 
                            newRateCardLineStoreID,
                            r.data.saleAnalysisValues
                        )
                    }
                    
                }
            })
        }
    }

    function handleMultipleClose(newRateCardLineStoreID) {
        setMultipleOpen(false)
        if(newRateCardLineStoreID === undefined) {
            //they cancelled, nothing more to do
        } else {
            //they have provided the resolution, call the parent function
            props.onOcrChanges(
                multipleData.orderRef,
                multipleData.saleDate,
                newRateCardLineStoreID,
                multipleData.saleAnalysisValues
            )
        }
    }

    const maxSize = 52428800

    return (
        props.formVals.saleTypeID + "" === "0" ? 
            <ScanButton
                disabled={true}
            />
        :
            <>
                <Dropzone onDrop={handleDrop} accept="image/*" minSize={0} maxSize={maxSize} multiple={false}>
                    {
                        ({getRootProps, getInputProps, isDragActive, rejectedFiles}) => {
                            return (
                                <div {...getRootProps()} style={{width: "100%"}}>
                                    <input {...getInputProps()} capture="camera" />
                                    <ScanButton
                                        disabled={false}
                                        loading={loading}
                                    />
                                </div>
                            )
                        }
                    }
                </Dropzone>
                <ValidationModal
                    open={validationModalOpen}
                    failSummary={failSummary}
                    onClose={() => setValidationModalOpen(false)}
                />
                <MultipleModal
                    open={multipleOpen}
                    onClose={handleMultipleClose}
                    multipleData={multipleData}
                />
            </>
    )
}

function ScanButton(props) {
    return (
        <Button
            startIcon={props.loading ? <CircularProgress size={24} /> : <CameraAltOutlinedIcon  />}
            fullWidth
            disabled={props.disabled}
        >
            {props.loading ? "Processing..." : "Scan receipt " + (props.disabled ? "(Choose sale type first)" : "")}
        </Button>
    )
}