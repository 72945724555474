import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function HoursInputChangeModal(props) {
    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {
                    props.potentialHoursInput ?
                        "Use Hours"
                    :
                        "Remove Hours"
                }
            </DialogTitle>
            <DialogContent>
                <p>
                    {
                        props.potentialHoursInput ?
                            "Switch to using hours to distribute targets to users?"
                        :
                            "Switch to standard target entry (no hours)?"
                    }
                </p>
                <p>
                    <strong>
                        {
                        props.potentialHoursInput ?
                            "Your targets (except store totals) for this store+month will be overwritten!"
                        :
                            "Your hours data for this store+month will be deleted!"
                        }
                    </strong>
                </p>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button 
                    color="primary" 
                    variant="contained" 
                    onClick={() => props.onChange(props.potentialHoursInput)} 
                >
                    {
                        props.potentialHoursInput ?
                            "Use Hours"
                        :
                            "Remove Hours"
                    }
                </Button>
            </DialogActions>
      </Dialog>
    )
}