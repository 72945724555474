import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'
import { InputAdornment, Grid, Button } from '@material-ui/core'

import AutoCompleteNative from '../../../shared/autoCompleteNative'
import { isJsonString } from '../../../../lib/general'
import TableDependentModal from "../../data/metrics/tableDependentModal"

const divisionParts = [
    {
        value: "numerator",
        label: "Numerator"
    },
    {
        value: "denominator",
        label: "Denominator"
    },                
]

const fields = [
    {
        value: "revenueCount",
        label: "Count",
        tableName: "sales",
    },
    {
        value: "adjustedRevenueTotal",
        label: "Total revenue",
        tableName: "sales",
    },
    {
        value: "adjustedRevenueASP",
        label: "Average revenue",
        tableName: "sales",
    },
    {
        value: "commissionTotal",
        label: "Total commission",
        tableName: "sales",
    },
    {
        value: "commissionASP",
        label: "Average commission",
        tableName: "sales",
    },
    {
        value: "footfallTotal",
        label: "Footfall",
        tableName: "dailyStats",
    },
    {
        value: "chatCountTotal",
        label: "Chat count",
        tableName: "dailyStats",
    },
    {
        value: "staffCountTotal",
        label: "Staff count",
        tableName: "dailyStats",
    },
    {
        value: "dayCount",
        label: "Days of stats recorded",
        tableName: "dailyStats",
    },
]

const tableNames = [
    {
        value: "sales",
        label: "Sales",
    },
    {
        value: "dailyStats",
        label: "Daily statistics",
    },
]


export default {
    displayName: "Metric Part",
    idField: "metricPartID",

    getURL: id => "/metricParts?metricPartID=" + id,
    putURL: id => "/metricPart/" + id,
    deleteURL: id => "/metricPart/" + id,
    postURL: "/metricPart",
    getListURL: "/metricParts",
    maxWidth: "lg",
    recordName: formVals => formVals.name,

    blankForm: {
        divisionPart: "",
        name: "", 
        multiplier: 1,
        field: "",
        params: "",
        metricID: 0,
        tableName: ""
    },
    form : function (props) {
        const [salesOpen, setSalesOpen] = useState(false)
        const [validJson, setValidJson] = useState(true)

        function handleChange(event) {
            var newFormVals = {
                ...props.formVals,
                [event.target.id]: event.target.value,
            }
            //if it's a change of tableName then reset the field
            if(event.target.id === "tableName" && event.target.value !== props.formVals.tableName) {
                //resetting the field as its no longer valid with the new table
                newFormVals.field = ""
            }
            //merge it with the existing form vals and call the parent handler function
            props.onChange(newFormVals)
            //is this a change of params?  if so decide if it's currently valid json or not
            if(event.target.id === "params") {
                setValidJson(isJsonString(event.target.value))
            }
        }

        return (
            <div>
                <AutoCompleteNative
                    id="divisionPart"
                    value={props.formVals.divisionPart}
                    label="Division part"
                    onChange={handleChange}
                    optionLabel={o => o.label}
                    optionValue={o => o.value}
                    clearValue=""
                    options={divisionParts}
                    error={props.validationResult.failMessages.hasOwnProperty("divisionPart")}
                    helperText={props.validationResult.failMessages.divisionPart}
                    disabled={props.disabled}
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            value={props.formVals.name}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("name")}
                            helperText={props.validationResult.failMessages.name}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            id="multiplier"
                            label="Multiplier"
                            type="text"
                            value={props.formVals.multiplier}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("multiplier")}
                            helperText={props.validationResult.failMessages.multiplier}
                            fullWidth
                            disabled={props.disabled}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">x field</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AutoCompleteNative
                            id="tableName"
                            value={props.formVals.tableName}
                            label="Table"
                            onChange={handleChange}
                            optionLabel={o => o.label}
                            optionValue={o => o.value}
                            clearValue=""
                            options={tableNames}
                            error={props.validationResult.failMessages.hasOwnProperty("tableName")}
                            helperText={props.validationResult.failMessages.tableName}
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AutoCompleteNative
                            id="field"
                            value={props.formVals.field}
                            label="Field"
                            onChange={handleChange}
                            optionLabel={o => o.label}
                            optionValue={o => o.value}
                            clearValue=""
                            options={fields.filter(f => f.tableName === props.formVals.tableName)}
                            error={props.validationResult.failMessages.hasOwnProperty("field")}
                            helperText={props.validationResult.failMessages.field}
                            disabled={props.disabled}
                        />
                    </Grid>
                </Grid>
                <p>
                    Avoid using date, store, or user filters, as these are added automatically by the system depending on the scope/context of the calculation at runtime and may therefore be ignored or overwritten with unpredictable results.
                </p>
                <TextField
                    margin="dense"
                    id="params"
                    label="Params (filters)"
                    type="text"
                    value={props.formVals.params}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("params")}
                    helperText={props.validationResult.failMessages["params"]}
                    fullWidth
                    disabled={props.disabled}
                    multiline
                    rows={6}
                    variant="outlined"
                />
                <Button
                    onClick={() => setSalesOpen(true)}
                    disabled={!validJson}
                >
                    {isJsonString(props.formVals.params) ? "Test these filters..." : "Cannot test - JSON is invalid"}
                </Button>
                <TableDependentModal
                    tableName={props.formVals.tableName}
                    title={"Matching sales - " + props.formVals.name}
                    me={props.me}
                    params={isJsonString(props.formVals.params) ? JSON.parse(props.formVals.params) : {}}
                    open={salesOpen}
                    onClose={() => setSalesOpen(false)}
                />
            </div>
    
        )
    },
}

