import React from 'react'

import DataTable, {addAuditFields, renderCB} from '../../../shared/dataTable/dataTable'
import SKUTypeEntityForm from './skuTypeEntityForm';

const filters = [
]

const columnData = [
    { fieldName: 'skuTypeID', default: true, name: 'ID', description: "The unchanging identifier of the skuType"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the SKU Type"},
    { fieldName: 'description', default: true, name: 'Description', description: "Description of the SKU Type"},
    { fieldName: 'excludeDiscount', default: true, name: 'Exclude discounts', description: "If ticked then the sale type discounts cannot be applied to this sku type", renderCell: dr => renderCB(dr.excludeDiscount)},
];

export default function SKUTypesList(props) {

    return(
        <div>
            <h1>
                SKU Types
            </h1>
            <p>
                SKU Types classify the SKU's into different categories.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={SKUTypeEntityForm}
                
                filters={filters} 
                title="SKU Types"
                enableCrud={true}
            />
        </div>
    )
}