import React, {useState, useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom'

import {formatMoney, ensureArray} from '../../../../lib/general'
import {paramsToSaleURL} from '../../sales/general'
import MetricCalcModal from '../cards/metricCalcModal';
import authFetch from '../../../../lib/authFetch';

import "./landingTable.css"

function descideDataValue(data) {
    //try and treat it as a float
    var floatPotential = parseFloat((data + "").replace(",", "").replace("%", ""))
    //if we got a number, return it
    if(isNaN(floatPotential)) {
        //not a number so just use the original value
        return data
    } else {
        return floatPotential
    }
}

function sortByData(a, b) {
    //get number values if possible
    var aData = descideDataValue(a.data)
    var bData = descideDataValue(b.data)
    //numbers always treated higher than strigns (which tends to mean blank value)
    if(Number.isFinite(aData) && !Number.isFinite(bData)) {return 1}
    if(Number.isFinite(bData) && !Number.isFinite(aData)) {return -1}
    //both are either numbers, or strings.  so straightforward compare
    if(aData > bData) {return 1}
    if(aData < bData) {return -1}
    return 0
}



export default withRouter(function LandingTable(props) {
    const [metricCalcOpen, setMetricCalcOpen] = useState(false)
    const [metricCalcParts, setMetricCalcParts] = useState([])
    const [currentPercentString, setCurrentPercentString] = useState("")
    const [metricName, setMetricName] = useState("")
    const [sortColumnName, setSortColumnName] = useState(null)
    const [sortDir, setSortDir] = useState(null) //can specify "desc" here.  Anything else means "asc"
    //const [loadingSort, setLoadingSort] = useState(true)

    //get the default sort for this table
    useEffect(() => {
        //console.log("Getting sort for " + props.table.name)
        getDefaultSort()
    }, [
        props.table.name
    ])

    const sortKey = props.table.name

    function getDefaultSort() {
        //setLoadingSort(true)
        authFetch("/user/userPreference/landingTableSort/" + sortKey)
        .then(r => {
            //setLoadingSort(false)
            if(r.validationResult.fail || r.data === "") {
                //no mappings
                return Promise.reject("No preference")
            } else {
                //there is something
                var parsedPreference = JSON.parse(r.data)
                setSortColumnName(parsedPreference.sortColumnName)
                setSortDir(parsedPreference.sortDir)
            }
        })
        .catch(err => {
            //no sort preference available
            //nothing to do
            console.log("Error getting sort pref " + err)
            //use any defaultsort passed in the props
            setSortColumnName(props.sortColumnName)
            setSortDir(props.sortDir)
        })
    }

    //figure out if we need to remove any columns
    var removeIs = props.table.columns.map(x => (x.isCommission && !props.me.revenueCommission.includes("commission")) || (x.isRevenue && !props.me.revenueCommission.includes("revenue"))).map((x, i) => x ? i : -1)

    //these are the column headers
    var usingColumns = props.table.columns.filter((x, i) => !removeIs.includes(i)) //filter out isRevenue and isCommission accordingly
    //these are arrays of row data (arrays of arrays).  These must respect the sortByI array if present
    var usingRows = props.table.rows.map(r => r.filter((x, i) => !removeIs.includes(i)))
    var usingParams = props.table.params.map(r => r.filter((x, i) => !removeIs.includes(i)))
    var usingColors = ensureArray(props.table.colors).map(r => r.filter((x, i) => !removeIs.includes(i)))
    var usingMetricParts = ensureArray(props.table.metricParts).map(r => r.filter((x, i) => !removeIs.includes(i)))

    //do we have a sortByColumnIndex that we can use?
    var sortByI = null //make this an array of i's we sort by, if we have sorting info
    if(usingColumns.map(n => n.name).includes(sortColumnName + "")) {
        //yes the sortColumnName is one of the headers in this table.  Build the array of sortByI
        //what is the index of the column we are sorting by?
        var sortByColumnIndex = usingColumns.map(n => n.name).indexOf(sortColumnName + "")
        //first get the appliccable data in an array of [{data: xxx, index: n}]
        sortByI = usingRows.map((r, i) => ({
                index: i,
                data: r[sortByColumnIndex]
            })
        ) //now sort by the data field
        .sort(sortByData) //now we only want the indexes
        .map(d => d.index)
        //if direction is desc then reverse
        if(sortDir === "desc") {
            sortByI.reverse()
        }
    }

    if(sortByI !== null) {
        //apply the sort to all of the rows, params, colours, metricparts
        usingRows = sortByI.map(i => usingRows[i])
        usingParams = sortByI.map(i => usingParams[i])
        usingColors = sortByI.map(i => usingColors[i])
        usingMetricParts = sortByI.map(i => usingMetricParts[i])
    }

    //do we have a superheader?  This is where there is a row above the header, denoted by a pipe symbol
    //then there are contigious superheaders the same, it implies the cells should be merged
    var superHeadersExist = (usingColumns.filter(c => (c.name + "").includes("|")).length > 0)
    //create an array of superheaders, shape [{name, colSpan}]
    var superHeaders = []
    if(superHeadersExist) {
        usingColumns.forEach((c, i) => {
            var thisSH = (c.name + "").includes("|") ? (c.name + "").split("|")[0] : ""
            //we either add a new header, or increment the colSpan of the previous one if its the same
            if(i === 0 || (usingColumns[i - 1].name + "").split("|")[0] !== thisSH || thisSH === "") {
                //it's the first one, or it's different to the last one, or there's no SH so add a new sh
                superHeaders.push({
                    name: thisSH,
                    colSpan: 1,
                    backgroundColor: c.backgroundColor,
                    alignRight: c.alignRight
                })
            } else {
                //increment the colspan of the previous one
                superHeaders[superHeaders.length - 1].colSpan = superHeaders[superHeaders.length - 1].colSpan + 1
            }
        })
    }

    function handleColumnSort(columnName) {
        //handles when a user clicks a column header to sort by this column
        //are we reversing the direction of sort?
        var newSortDir = (sortDir === "desc" ? "desc" : "asc") //set to what it is now, initially
        if(sortColumnName + "" === columnName) {
            //already sorted by this column, just reverse the direction
            if(sortDir === "desc") {
                newSortDir = "asc"
            } else {
                newSortDir = "desc"
            }
        } else {
            //set the new column
            setSortColumnName(columnName)
        }
        //set the newly determined direction
        setSortDir(newSortDir)
        //store this as the new preference for the user
        var preferenceObj = {
                value: {
                sortColumnName: columnName,
                sortDir: newSortDir
            }
        }   
        authFetch("/user/userPreference/landingTableSort/" + sortKey, {method: "PUT", body: JSON.stringify(preferenceObj)})
    }

    //console.log("sortDir: " + sortDir + ". sortColumnName: " + sortColumnName)

    function handleMetricClick(metricParts, currentPercentString, metricName) {
        setMetricCalcOpen(true)
        setMetricCalcParts(metricParts)
        setCurrentPercentString(currentPercentString)
        setMetricName(metricName)
    }

    return (
        <>
            <h3>
                {props.table.name + " - " + props.timeSlice.name}
            </h3>
            <TableContainer className="tscroll" component={Paper} style={{marginTop: "10px", overflowX: "auto"}}>
                <Table aria-label={props.table.name}>
                    <TableHead>
                        {
                            superHeadersExist ?
                                <TableRow>
                                    {
                                        superHeaders.map((sh, i) => 
                                            <TableCell 
                                                style={{padding: "4px", backgroundColor: sh.backgroundColor}}
                                                key={i}
                                                align={sh.colSpan > 1 ? "center" : sh.alignRight ? "right" : undefined}
                                                colSpan={sh.colSpan}
                                            >
                                                <strong>{sh.name}</strong>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            : null
                        }
                        <TableRow>
                            {
                                usingColumns.map((tc, i) => 
                                    <TableCell 
                                        style={{padding: "4px", width: (100 / usingColumns.length) + "%", backgroundColor: tc.backgroundColor, cursor: "pointer"}}
                                        key={i}
                                        align={tc.alignRight ? "right" : undefined}
                                        onClick={() => handleColumnSort(tc.name)}
                                    >
                                        <TableSortLabel
                                            hideSortIcon
                                            active={sortColumnName === tc.name}
                                            direction={sortDir === "desc" ? "desc" : "asc"}
                                            onClick={() => handleColumnSort(tc.name)}
                                        >
                                            <strong>{(tc.name + "").includes("|") ? (tc.name + "").split("|")[1] : tc.name}</strong>
                                        </TableSortLabel>
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            usingRows.map((tr, rowi) => 
                                <TableRow key={rowi}>
                                    {
                                        tr.map((td, coli) => {
                                            //is there params which take us to the sales list?
                                            var foundParam = ensureArray(usingParams[rowi])[coli]
                                            var useParam = foundParam === null || foundParam === undefined ? null : foundParam
                                            //is there a color to apply?
                                            var foundColor = ensureArray(usingColors[rowi])[coli]
                                            var useColor = foundColor === null || foundColor === undefined ? undefined : foundColor
                                            //are there metric parts?
                                            var foundMetricParts = ensureArray(usingMetricParts[rowi])[coli]
                                            var useMetricParts = (foundMetricParts === null || foundMetricParts === undefined || !Array.isArray(foundMetricParts)) ? undefined : foundMetricParts
                                            //what click action are we using?
                                            var clickAction = ""
                                            if(useParam !== null) {clickAction = "param"}
                                            if(useMetricParts !== undefined) {clickAction = "metricParts"}
                                            //what are we displaying in the cell?
                                            var display = usingColumns[coli].formatMoney ? 
                                                    formatMoney(td, 2)
                                                :
                                                    usingColumns[coli].format1DP ?
                                                        formatMoney(td, 1)
                                                    :
                                                        td
                                            return (
                                                <TableCell 
                                                    style={{padding: "4px", backgroundColor: usingColumns[coli].backgroundColor}}
                                                    key={coli}
                                                    align={usingColumns[coli].alignRight ? "right" : undefined}
                                                >
                                                    <span 
                                                        onClick={ //opening metricParts calc, or redirecting to salesParams
                                                            clickAction === "metricParts" ? 
                                                                () => handleMetricClick(useMetricParts, display, usingColumns[coli].name) 
                                                            : 
                                                                (clickAction === "param" ? 
                                                                    () => props.history.push(paramsToSaleURL(useParam)) 
                                                                : undefined)
                                                            } 
                                                        style={{
                                                            cursor: (clickAction === "" ? "default": "pointer"),
                                                            color: useColor
                                                        }}
                                                    >
                                                        {display}
                                                    </span>
                                                </TableCell>
                                            )
                                        }
                                            
                                        )
                                    }
                                </TableRow>    
                            )
                        }
                        {
                            props.table.totalsRow ? 
                                <TableRow>
                                    {
                                        usingColumns.map((tc, i) => 
                                            <TableCell 
                                                scope="row" style={{padding: "4px", backgroundColor: usingColumns[i].backgroundColor}}
                                                key={i}
                                                align={usingColumns[i].alignRight ? "right" : undefined}
                                            >
                                                <strong>
                                                    {
                                                        tc.totalsRowLabel === undefined ? null : tc.totalsRowLabel
                                                    }
                                                    {
                                                        tc.totalsRowFunction === "sum" ?
                                                            tc.formatMoney ?
                                                                formatMoney(usingRows.map(r => r[i]).reduce((acc, cur) => Number.isFinite(cur) ? acc + cur : acc, 0), 2)
                                                            :
                                                                tc.format1DP ?
                                                                    formatMoney(usingRows.map(r => r[i]).reduce((acc, cur) => Number.isFinite(cur) ? acc + cur : acc, 0), 1)
                                                                :
                                                                    usingRows.map(r => r[i]).reduce((acc, cur) => Number.isFinite(cur) ? acc + cur : acc, 0)
                                                        : null
                                                    }
                                                </strong>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <MetricCalcModal
                open={metricCalcOpen}
                value={metricCalcParts}
                onClose={() => setMetricCalcOpen(false)}
                title={metricName + " calculation"}
                me={props.me}
                currentPercentString={currentPercentString}
            />
        </>
    )
})