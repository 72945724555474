import React, {useState} from 'react'
import UsersTable from './usersTable'

const filters = {
    active: true
}

export default function UsersList(props) {

    return(
        <div>
            <h1>
                Active Users
            </h1>
            <p>
                Here are the active users.  Active users are able to log in to the system.
            </p>
            <UsersTable
                filters={filters} 
                enableCrud={props.me.accessProfile.systemAreas.split(", ").includes("UserSetup")}
            />
        </div>
    )
}