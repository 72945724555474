import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

export default function OptionalTooltip(props){
    if(props.tooltip === undefined || props.tooltip + "" === "") {
        return (
            props.children
        )
    }
    return(
        <Tooltip
            title={props.tooltip}
        >
            <div>
                {props.children}
            </div>
        </Tooltip>
    )
}