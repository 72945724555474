import React from 'react'
import APIFooter from '../../../shared/api/apiFooter'

const details = {
    name: "Rate Card Lines",
    url: (process.env.REACT_APP_API_URL === undefined ? ((window.location.href + "salt").replace(window.location.pathname + "salt", "/api")) : process.env.REACT_APP_API_URL) + "/rateCardLines",
    params: [
        {
            param: "rateCardID",
            name: "Rate card ID",
            format: "integer",
            example: "123",
            regex: "^[0-9]{1,7}$",
        },
        {
            param: "skuLower",
            name: "SKU lower case",
            format: "text",
            example: "1234abc",
            regex: "^.{0,25}$",
        },
        {
            param: "saleTypeID",
            name: "Sale type ID",
            format: "integer",
            example: "123",
            regex: "^[0-9]{1,7}$",
        },
    ]
}

export default function SalesAPIData(props) {
    return (
        <APIFooter
            me={props.me}
            details={details}
        />
    )
}