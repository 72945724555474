import React, {useState, useEffect} from 'react'
import dateFormat from 'dateformat'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import DataTable, {addAuditFields} from '../../shared/dataTable/dataTable'
import SaleEntityForm from './entityForm/saleEntityForm'
import rateCardLineEntityForm from '../data/rateCards/rateCardLineEntityForm'
import rateCardEntityForm from '../data/rateCards/rateCardEntityForm'
import storeEntityForm from '../data/stores/storeEntityForm'
import userEntityForm from '../data/users/userEntityForm'
import LookupCell from '../../shared/dataTable/lookupCell';
import ChangeRateCardModal from './changeRateCardModal'
import { formatMoney, initialsFromName } from '../../../lib/general'
import CurrentStepCell from './currentStepCell'
import SaleFilters from './saleFilters'
import TopButtons from './topButtons'
import OrderDupeClick from './orderDupeClick'


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function buildColumns(columnSet, me, geq700, geq1000, geq1280, filters) {
    //columnset can be used to specify a different set of columns, focussing on different things, eg
    // - revenue (default)
    // - commission (for box commission)
    // - adjustment
    //it's an array contianing one or more of these values

    function nameLabel(fromName) {
        if(geq1280) {
            //full name
            return fromName
        } else {
            //initials
            return initialsFromName(fromName)
        }
    }
    
    var columnData = [
        { fieldName: 'saleID', default: true, name: 'ID', description: "The unchanging identifier of the sale"},
    ]
    if(geq700) {
        //adding the distinct cols
        columnData.push(
            ...[
                { fieldName: 'saleDate', default: true, name: 'Sale Date', description: "The date of the sale", renderCell: dr => dateFormat(new Date(dr.saleDate), (geq1000 ? "dd/mm/yyyy" : "dd mmm")) },
                { fieldName: 'attributableToUserName', default: true, name: 'Seller', description: "The user who made this sale", renderCell: dr => 
                    <LookupCell 
                        id={dr.attributableToUserID} 
                        entityForm={userEntityForm}
                        label={nameLabel(dr.attributableToUserName)}
                    />
                },
                { fieldName: 'sku', default: true, name: 'SKU', description: "The SKU (rate card line) used in this sale", renderCell: dr => 
                    <LookupCell 
                        id={dr.rateCardLineID} 
                        entityForm={rateCardLineEntityForm}
                        label={dr.sku}
                        me={me}
                    />
                },
                { fieldName: 'rateCardLineDescription', default: true, name: 'Description', description: "SKU description"},
            ]
        )
    } else {
        //push a merged column containing all the data vertically
        columnData.push(
            { fieldName: 'saleDate', default: true, name: 'Sale Date', description: "The date of the sale", renderCell: dr => <span>
                {dateFormat(new Date(dr.saleDate), (geq1000 ? "dd/mm/yyyy" : "dd mmm"))}
                <br/>
                <LookupCell 
                    id={dr.attributableToUserID} 
                    entityForm={userEntityForm}
                    label={nameLabel(dr.attributableToUserName)}
                />
                <br/>
                <LookupCell 
                    id={dr.rateCardLineID} 
                    entityForm={rateCardLineEntityForm}
                    label={dr.sku}
                    me={me}
                />
            </span> }
        )
    }
    columnData.push(
        ...[
            { fieldName: 'orderRef', default: true, name: 'Order', description: "The order number", renderCell: dr => {
                if(geq1000) {
                    return (
                        <OrderDupeClick dupeCount={dr.dupeCount} orderRef={dr.orderRef}>
                            <span>{dr.orderRef}</span>
                        </OrderDupeClick>
                    )
                } else {
                    return (
                        <OrderDupeClick dupeCount={dr.dupeCount} orderRef={dr.orderRef}>
                            <span>
                                {dr.orderRef.substring(0,5)}<br/>
                                {dr.orderRef.substring(5,10)}<br/>
                                {dr.orderRef.substring(10,15)}<br/>
                                {dr.orderRef.substring(15)}
                            </span>
                        </OrderDupeClick>
                        
                    )
                }
            }},
            { fieldName: 'rateCardFromDate', default: geq1000, name: 'Rate Card', description: "The rate card used in this sale", renderCell: dr => 
                <LookupCell 
                    id={dr.rateCardID} 
                    entityForm={rateCardEntityForm}
                    label={dateFormat(dr.rateCardFromDate, "dd/mm/yyyy")}
                />
            },
        ]
    )
    //add store column only if list of stores is more than 1
    if(((filters.storeID === undefined || filters.storeID === "" ? me.accessProfile.accessProfileStores.map(aps => aps.storeID).join(",") : filters.storeID) + "").split(",").length === 1) {
        //no need ot show store column
        //console.log("store id " + filters.storeID)
    } else {
        columnData.push(
            { fieldName: 'storeName', default: true, name: 'Store', description: "Store the sale was at", renderCell: dr => 
                <LookupCell 
                    id={dr.storeID} 
                    entityForm={storeEntityForm}
                    label={geq1000 ? dr.storeName : initialsFromName(dr.storeName)}
                />
            }
        )
    }
    if(columnSet.includes("adjustment")) {
        columnData.push(
            ...[
                { fieldName: 'revenue', default: true, name: geq1000 ? 'Revenue' : "Rev", description: "Original recorded revenue on this sale", alignRight: true, renderCell: dr => formatMoney(dr.revenue)},
                { fieldName: 'adjustment', default: true, name: geq1000 ? 'Adjustment' : "Adj", description: "Adjustment made during reconciliation", alignRight: true, renderCell: dr => formatMoney(dr.adjustment)},
            ])
    }
    if(columnSet.includes("adjustment") || columnSet.includes("revenue")) {
        columnData.push(
            ...[
            //revenue column
            { fieldName: 'adjustedRevenue', default: true, name: geq1000 ? (!columnSet.includes("adjustment") ? 'Revenue' : "Adjusted Revenue") : (!columnSet.includes("adjustment") ? "Rev" : "Adj. Rev"), description: "Adjusted revenue on this sale", alignRight: true, renderCell: dr => {
                return (
                    dr.adjustment === 0 ?
                        //no sale adjustment, no tooltip
                        formatMoney(dr.adjustedRevenue)
                    :
                        <Tooltip title={"Revenue " + formatMoney(dr.revenue) + " + reconciliation adjustment " + formatMoney(dr.adjustment) + " = adjusted revenue"} >
                            <div>
                                {
                                    "(A) " + formatMoney(dr.adjustedRevenue)
                                }
                            </div>
                        </Tooltip>
                )
                
            }},
        ])
    }
    if(columnSet.includes("commission")) {
        columnData.push(
            ...[
                { fieldName: 'commission', default: true, name: geq1000 ? 'Commission' : "Com", description: "Commission payable as a result of this sale", alignRight: true, renderCell: dr => formatMoney(dr.commission)},
            ])
    }
    columnData.push(
    ...[
        //{ fieldName: 'adjustment', default: false, name: 'Adjustment', description: "Revenue adjustment made when reconciling this sale to revenue sheets"},
        { fieldName: 'notes', default: false, name: 'Notes', description: "Notes on this sale"},
    ]);
    //no status column in the adjustments columnSet since allt eh sales are reconciled when you're looking at adjustments
    if(columnSet.includes("commission") || columnSet.includes("revenue")) {
        columnData.push(
        ...[
            //{ fieldName: 'adjustment', default: false, name: 'Adjustment', description: "Revenue adjustment made when reconciling this sale to revenue sheets"},
            { fieldName: 'currentHistoryStep', default: true, name: geq1000 ? 'Status' : "St", description: "The status of the sale", renderCell: dr => <CurrentStepCell dataRow={dr} statusText={geq1000} />},
        ]);
    }
    return columnData
}

export default function SalesTable(props) {
    //layout decision factors, used in buildColumns function
    const geq1280 = useMediaQuery("(min-width:1280px)")
    const geq1000 = useMediaQuery("(min-width:1000px)")
    const geq700 = useMediaQuery("(min-width:700px)")

    const [changeRateCardOpen, setChangeRateCardOpen] = useState(false)
    const [salesIDs, setSalesIDs] = useState([])
    const [filters, setFilters] = useState(props.filters === undefined ? {} : props.filters)
    const [dataRefreshTime, setDataRefreshTime] = useState(new Date())

    

    function handleChangeRateCardOpen(salesIDs) {
        setChangeRateCardOpen(true)
        setSalesIDs(salesIDs)
    }

    function handleChangeRateCardClose() {
        setChangeRateCardOpen(false)
    }

    function handleChangeRateCardChange() {
        //theyve made a change, refresh the data
        setDataRefreshTime(new Date())
    }

    //watch for new filters being passed in from the parent
    useEffect(() => {
        //override the current filters with the new ones passed in from the parent.  Eg they have changed tab or something
        setFilters(props.filters)
    }, [JSON.stringify(props.filters)])

    //function for rendering the actions area when some items are selected
    function selectActions(props) {
        return (
            <Tooltip title="Change the rate card for these transactions">
                <IconButton aria-label="Change the rate card for these transactions" onClick={() => handleChangeRateCardOpen(props.selected)}>
                    <LowPriorityIcon />
                </IconButton>
            </Tooltip>
        );
    }

    function handleFiltersChange(newFilters) {
        setFilters(newFilters)
    }

    return(
        <div>
            <SaleFilters
                filters={filters}
                onFiltersChange={handleFiltersChange}
            />
            <DataTable
                columnData={addAuditFields(buildColumns(props.columnSet, props.me, geq700, geq1000, geq1280, filters))}
                entityForm={{
                    ...SaleEntityForm,
                    putURL: (props.me.accessProfile.systemAreas.split(", ").includes("UpdateSales") ? SaleEntityForm.putURL : undefined)
                }}
                selectActions={props.enableCrud && props.me.accessProfile.systemAreas.split(", ").includes("UpdateSales") ? selectActions : undefined}
                filters={{ //making sure a store filter applies, of course the api enforeces this
                    ...filters,
                    storeID: filters.storeID === undefined || filters.storeID === "" ? props.me.accessProfile.accessProfileStores.map(aps => aps.storeID).join(",") : filters.storeID
                }} 
                title={props.title}
                enableCrud={props.enableCrud && (props.me.accessProfile.systemAreas.split(", ").includes("UpdateSales") || props.me.accessProfile.systemAreas.split(", ").includes("RecordSales"))}
                sort={props.sort}
                sortDir={props.sortDir}
                me={props.me}
                crudRecordCheck={dr => ["recorded", "modified"].includes(dr.currentHistoryStep)}
                enableRead={true}
                conditionalRowStyle={dr => dr.isRefund ? {backgroundColor: "rgb(253, 236, 234)"} : dr.isRefunded ? {backgroundColor: "rgb(255, 244, 229)"} : {}}
                dataRefreshTime={dataRefreshTime}
                aggregateFieldIndex={(props.columnSet || []).includes("revenue") ? 0 : 1}
                crudModalOpen={props.newSale}
                defaultFormVals={props.defaultFormVals}
            />
            <ChangeRateCardModal 
                open={changeRateCardOpen}
                salesIDs={salesIDs}
                onClose={handleChangeRateCardClose}
                onChange={handleChangeRateCardChange}
            />
            {
                props.showTopButtons ?
                    <div style={{marginTop: "10px"}}>
                        <TopButtons
                            me={props.me}
                            filters={{ //making sure a store filter applies, of course the api enforeces this
                                ...filters,
                                storeID: filters.storeID === undefined || filters.storeID === "" ? props.me.accessProfile.accessProfileStores.map(aps => aps.storeID).join(",") : filters.storeID
                            }}
                            showTopUser={true}
                            showTopSKU={true}
                            //add store only if list of stores is more than 1
                            showTopStore={((filters.storeID === undefined || filters.storeID === "" ? props.me.accessProfile.accessProfileStores.map(aps => aps.storeID).join(",") : filters.storeID) + "").split(",").length !== 1}
                            variant="contained"
                            showTop={true}
                        />
                    </div>
                : null
            }
        </div>
    )
}