import React, {useState, useEffect} from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Button, CircularProgress } from '@material-ui/core'
import dateFormat from 'dateformat'

import LineUploadModal from './lineUpload/lineUploadModal'
import RateCardLinesTable from './rateCardLinesTable'
import rateCardEntityForm from './rateCardEntityForm'
import authFetch from '../../../../lib/authFetch'
import RateCardLinesApiData from './rateCardLinesApiData'
import saleTypeEntityForm from '../../data/saleTypes/saleTypeEntityForm'
import SaleTypeTabs from './saleTypeTabs'
import RevenueCommissionSwitchTitle from '../../../shared/title/revenueCommissionSwitchTitle'

export default function RateCardLines(props) {
    const [uploadOpen, setUploadOpen] = useState(false)
    const [dataRefreshTime, setDataRefreshTime] = useState(new Date())
    const [rateCard, setRateCard] = useState(rateCardEntityForm.blankForm)
    const [saleType, setSaleType] = useState(saleTypeEntityForm.blankForm)
    const [loading, setLoading] = useState(false)
    const [selectRefreshTime, setSelectRefreshTime] = useState(new Date())

    function handleSelectRefresh() {
        setSelectRefreshTime(new Date())
    }

    //get the rate card for use in the header etc
    useEffect(() => {
        setLoading(true)
        if(props.me !== undefined && Array.isArray(props.me.saleTypes) && [props.me.saleTypes.length > 0]) {
            setSaleType(props.me.saleTypes[0])
        }
        var proms = []
        //get the current rate card
        proms.push(
            authFetch("/rateCards?rateCardID=" + props.match.params.rateCardID)
            .then(r => {
                setRateCard(r.listData[0])
            })
        )
        Promise.all(proms)
        .then(na => {
            setLoading(false)
        })
    }, [props.match.params.rateCardID])

    function handleUploadClose() {
        setUploadOpen(false)
    }

    function handleUploadOpen() {
        setUploadOpen(true)
    }

    function handleUploadDataChange() {
        //set the data refresh date prop on the data table to trigger a refresh
        setDataRefreshTime(new Date())
    }

    function handleSaleTypeChange(newSaleType) {
        setSaleType(newSaleType)
        setSelectRefreshTime(new Date())
    }

    if(props.me !== undefined && Array.isArray(props.me.saleTypes) && [props.me.saleTypes.length > 0]) {
        return(
            <div>
                {
                    loading ? <CircularProgress size={200} /> :
                    <>
                        <RevenueCommissionSwitchTitle
                            me={props.me}
                            title={"Rate Card " + dateFormat(rateCard.fromDate, "dd/mm/yyyy") + " Lines"}
                        />
                        <SaleTypeTabs
                            onChange={handleSaleTypeChange}
                            saleTypes={props.me.saleTypes}
                            saleType={saleType}
                            blankForm={saleTypeEntityForm.blankForm}
                            rateCardID={props.match.params.rateCardID}
                        />
                        {
                            props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudUploadIcon />}
                                    style={{margin: "15px 0px 14px 0px"}}
                                    onClick={handleUploadOpen}
                                >
                                    Upload {saleType.name} Lines
                                </Button>
                            : undefined
                        }
                        <RateCardLinesTable 
                            dataRefreshTime={dataRefreshTime}
                            rateCardID={props.match.params.rateCardID}
                            saleType={saleType}
                            enableCrud={props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup")}
                            selectRefreshTime={selectRefreshTime}
                            onSelectRefresh={handleSelectRefresh}
                            me={props.me}
                            onChange={handleUploadDataChange}
                        />
                        <RateCardLinesApiData me={props.me} />
                        <LineUploadModal
                            open={uploadOpen}
                            rateCardID={props.match.params.rateCardID}
                            saleType={saleType}
                            onClose={handleUploadClose}
                            onDataChange={handleUploadDataChange}
                        />
                    </>
                }
            </div>
        )
    } else {
        return (null)
    }

}