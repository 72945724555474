import React from 'react'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'

import TransactionKPICard from './transactionKPICard'


export default function TransactionKPICardSet(props) {
    var Title = props.title

    return (
        props.me === undefined || props.me.revenueCommission === undefined ? null :
        props.me.revenueCommission.map(rc =>
            props.hidden.split(",").includes(props.setNamePrefix + rc) ? null :
            <React.Fragment key={rc}>
                <Title
                    revenueCommission={rc}
                    me={props.me}
                    value={props.value}
                    userName={props.userName}
                    storeName={props.storeName}
                    onAddHidden={props.onAddHidden}
                    setName={props.setNamePrefix + rc}
                    loading={props.loading}
                />
                <Grid container spacing={2}>
                    <TransactionKPICard 
                        value={props.value.day}
                        showPriorYear={true}
                        currentDisplayName={dateFormat(new Date(props.value.day.current.params.saleDateGEQ), "UTC:ddd dS mmm")}
                        priorDisplayName="Yesterday"
                        minHeight={props.minHeight}
                        me={props.me}
                        revenueCommission={rc}
                    />
                    <TransactionKPICard 
                        value={props.value.calendarMonth}
                        showPriorYear={true}
                        currentDisplayName={dateFormat(new Date(props.value.calendarMonth.current.params.saleDateGEQ), "UTC:mmm") + " Month"}
                        priorDisplayName="Last MTD"
                        minHeight={props.minHeight}
                        me={props.me}
                        revenueCommission={rc}
                    />
                    { //only showing yearly cards if they have this permission
                        props.me !== undefined && props.me.accessProfile !== undefined && props.me.accessProfile.systemAreas.includes("ViewYearCards") && props.value.calendarYear !== undefined && props.value.calendarYear.current !== undefined ?
                            <TransactionKPICard 
                                value={props.value.calendarYear}
                                showPriorYear={false}
                                currentDisplayName={"Year " + dateFormat(new Date(props.value.calendarYear.current.params.saleDateGEQ), "UTC:yyyy")}
                                priorDisplayName="Last YTD"
                                minHeight={props.minHeight}
                                me={props.me}
                                revenueCommission={rc}
                            />
                        : null
                    }
                </Grid>
            </React.Fragment>
        )
    )
}