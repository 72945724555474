import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'

export default function RecSheetLineToolbar(props) {
    //calculate the tab value
    let tabValue = false
    //console.log("PLP is " + props.location.pathname + " and match is ")
    if(props.location.pathname === "/recSheets/" + props.match.params.recSheetID + "/lines") {tabValue = 0}
    if(props.location.pathname === "/recSheets/" + props.match.params.recSheetID + "/lines/unreconciled") {tabValue = 1}
    if(props.location.pathname === "/recSheets/" + props.match.params.recSheetID + "/lines/reconciled") {tabValue = 2}
    if(props.location.pathname === "/recSheets/" + props.match.params.recSheetID + "/lines/saleAdjustments") {tabValue = 3}
    if(props.location.pathname === "/recSheets/" + props.match.params.recSheetID + "/lines/unreconciledSales") {tabValue = 4}

    return (
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
            <Tab label="All" component={Link} to={"/recSheets/" + props.match.params.recSheetID + "/lines"} />
            <Tab label="Unreconciled" component={Link} to={"/recSheets/" + props.match.params.recSheetID + "/lines/unreconciled"} />
            <Tab label="Reconciled" component={Link} to={"/recSheets/" + props.match.params.recSheetID + "/lines/reconciled"} />
            <Tab label="Sale Adjustments" component={Link} to={"/recSheets/" + props.match.params.recSheetID + "/lines/saleAdjustments"} />
            <Tab label="Unreconciled sales" component={Link} to={"/recSheets/" + props.match.params.recSheetID + "/lines/unreconciledSales"} />
        </Tabs>
    );
}
