import React, {useState} from 'react'
import DateRangeIcon from '@material-ui/icons/DateRange'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {Link} from 'react-router-dom'
import dateFormat from 'dateformat'

import DataTable, {addAuditFields, renderCB} from '../../../shared/dataTable/dataTable'
import UserEntityForm from './userEntityForm';
import AccessProfileEntityForm from './accessProfileEntityForm';
import StoreEntityForm from '../stores/storeEntityForm';
import LookupCell from '../../../shared/dataTable/lookupCell';

const columnData = [
    { fieldName: 'userID', default: true, name: 'ID', description: "The unchanging identifier of the user"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the user"},
    { fieldName: 'email', default: true, name: 'Email (login)', description: "Email (and login) of the user"},
    { fieldName: 'active', default: true, name: 'Active', description: "Whether the user is active (can log in) or not", renderCell: dr => renderCB(dr.active)},
    { fieldName: 'lastLogin', default: true, name: 'Last Login', description: "The date and time the user last logged into the system", renderCell: dr => dr.lastLogin === null ? "N/a" : dateFormat(dr.lastLogin, "dd/mm/yyyy HH:MM:ss", true)},
    { fieldName: 'storeName', default: true, name: 'Default store', description: "The users default store", renderCell: (dr) => 
        <LookupCell 
            id={dr.storeID} 
            entityForm={StoreEntityForm}
            label={dr.storeName}
        />
    },
    { fieldName: 'accessProfileName', default: true, name: 'Access profile', description: "The access profile decides what the user has access to", renderCell: (dr) => 
        <LookupCell 
            id={dr.accessProfileID} 
            entityForm={AccessProfileEntityForm}
            label={dr.accessProfileName}
        />
    },
];

export default function UsersList(props) {

    return(
        <DataTable
            columnData={addAuditFields(columnData)}
            entityForm={UserEntityForm}
            
            filters={{
                ...props.filters,
                fkJoins: ""
            }} 
            title="Users List"
            enableCrud={props.enableCrud}
        />
    )
}