import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link, Route, Switch} from 'react-router-dom'

import RecSheetLineToolbar from './recSheetLineToolbar'

export default function RecSheetToolbar(props) {
    //no toolbar on the root of recSheet
    //only when we get to the lines

    return (
        <Switch>
            <Route path="/recSheets/:recSheetID/lines" render={routeProps => <RecSheetLineToolbar {...routeProps} />} />
        </Switch>
    );
}
