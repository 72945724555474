import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function NoTranslateModal(props) {
    return(
        <Dialog open={props.open} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                SKU Not Available
            </DialogTitle>
            <DialogContent>
                <p>The selected SKU is not available at that store on this rate card for this sale type.  Choose from the list of available SKU's, or change the sale type or store.</p>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}