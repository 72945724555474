import React, {useState, useEffect} from 'react'
import { Button, Grid } from '@material-ui/core'

import saleEntityForm from '../entityForm/saleEntityForm'

const initialCols = [0, 1]
const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}

export default function SalesTable(props) {
    const [formVals, setFormVals] = useState(initialCols.map(x => ({
        ...saleEntityForm.blankForm,
        storeID: props.me === undefined ? 0 : props.me.storeID, //the effect which normally sets this inside the form doesnt work because it calls the handleFormValsCahnge in too quick succession
    }))) //start with instances of the blank sale entity form

    const EntityForm = saleEntityForm.form

    function handleFormValsChange(col, newFormVals) {
        var newArray = []
        //only change the one in the array that is being passed
        formVals.forEach((fv, i) => {
            if(i === col) {
                //this is the one being changed, or it's the first col, which controls the other cols too
                newArray.push(newFormVals)
            } else {
                //this one is not being changed
                //but if the first col is being changed, and the current one has no sale type, copy this sale type
                if(col === 0 && fv.saleTypeID === 0) {
                    //copying the sale type over
                    newArray.push({
                        ...fv,
                        saleType: newFormVals.saleType,
                        saleTypeID: newFormVals.saleTypeID
                    })
                } else {
                    //just leave it as-is
                    newArray.push(fv)
                }
            }
        })
        setFormVals(newArray)
    }

    function handleCreate(createFormVals) {
        //redirect to the create sale page
        console.log("fv: " + JSON.stringify(createFormVals))
        props.history.push("/sales/newSale/" + btoa(JSON.stringify(createFormVals)))
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h2 style={{marginBottom: "0px"}}>
                    Comparison Calculator
                </h2>
            </Grid>
            {
                formVals.map((fv, i) => 
                    <React.Fragment key={i}>
                        <Grid item xs={12/formVals.length}>
                            <EntityForm 
                                formVals={fv} 
                                onChange={nfv => handleFormValsChange(i, nfv)} 
                                validationResult={blankValidationResult} //no validation result becasue no validation because not submitting to server here 
                                disabled={false} 
                                me={props.me}
                                calculatorOnly={true}
                                isHighestRevenue={fv.revenue === formVals.reduce((acc, cur) => cur.revenue > acc ? cur.revenue : acc, 0)}
                            />
                            <Button
                                variant="contained"
                                onClick={e => handleCreate(fv)}
                                style={{marginTop: "10px"}}
                            >
                                Create sale...
                            </Button>
                        </Grid>
                    </React.Fragment>
                )
            }
        </Grid>
    )
}