import React, {useEffect} from 'react'
import Announcement from '../announcements/announcement/announcement'

export default function AnnouncementViewer(props) {
    //upon loading the control get the announcements
    useEffect(() => {
        //console.log("Getting announcmentss..")
        if(!(props.refreshMyAnnouncements === undefined)) {
            props.refreshMyAnnouncements()
        }
    }, [])

    if(!Array.isArray(props.myAnnouncements)) {
        return null
    }

    //handles user clicking ok
    function handleChange() {
        //refresh the list so that hopefully it dissappears
        props.refreshMyAnnouncements()
    }

    return(
        <>
            {
                props.myAnnouncements.map(a => 
                    <div style={{padding: "10px 5px 10px 5px"}} >
                        <Announcement
                            me={props.me}
                            onChange={handleChange}
                            announcement={a}
                            refreshMyAnnouncements={props.refreshMyAnnouncements}
                        />
                    </div>
                )
            }
        </>
    )
}