import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import RecSheetLines from './recSheetLines'
import RecSheetsList from './recSheetsList'
import UnreconciledSales from './unreconciledSales'
import SaleAdjustments from './saleAdjustments'

export default function Recs(props) {
    if(props.me.accessProfile.systemAreas.includes("RevenueReconciliation")) {
        return(
            <Switch>
                <Route path="/recSheets/:recSheetID/lines/unreconciledSales" render={renderProps => <UnreconciledSales {...renderProps} me={props.me} />} />
                <Route path="/recSheets/:recSheetID/lines/saleAdjustments" render={renderProps => <SaleAdjustments {...renderProps} me={props.me} />} />
                <Route path="/recSheets/:recSheetID/lines/:filter" render={renderProps => <RecSheetLines {...renderProps} me={props.me} />} />
                <Route path="/recSheets/:recSheetID/lines" render={renderProps => <RecSheetLines {...renderProps} me={props.me} />} />
                <Route path="/recSheets" render={renderProps => <RecSheetsList {...renderProps} me={props.me} />} />
            </Switch>
        )
    } else {
        return null
    }
}