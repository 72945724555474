import React from 'react'
import APIFooter from '../../../shared/api/apiFooter'

const details = {
    name: "Rate Cards",
    url: (process.env.REACT_APP_API_URL === undefined ? ((window.location.href + "salt").replace(window.location.pathname + "salt", "/api")) : process.env.REACT_APP_API_URL) + "/rateCards",
    params: [
        {
            param: "fromDate",
            name: "Rate card effective date",
            format: "yyyy-mm-dd",
            example: "2020-04-01",
            regex: "^[12][019][0-9][0-9]-[01]{0,1}[0-9]-[0-3]{0,1}[0-9](T[0-9][0-9]:[0-9][0-9]:[0-9][0-9]\.[0-9][0-9][0-9]Z)?$",
        },        
        {
            param: "rateCardID",
            name: "Rate card ID",
            format: "integer",
            example: "123",
            regex: "^[0-9]{1,7}$",
        },
    ]
}

export default function SalesAPIData(props) {
    return (
        <APIFooter
            me={props.me}
            details={details}
        />
    )
}