import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { InputAdornment } from '@material-ui/core'

import CheckBox from '../../../shared/checkBox'
import AutoCompleteAsync from '../../../shared/autoCompleteAsync'
import AutoCompleteManual from '../../../shared/autoCompleteManual'
import AnalysisListItemSelector from './analysisListItemSelector'

export default {
    displayName: "Analysis Field",
    idField: "analysisFieldID",

    getURL: id => "/analysisFields?fkJoins=" + encodeURIComponent("analysisFieldStores,analysisListItems") + "&analysisFieldID=" + id,
    putURL: id => "/analysisField/" + id,
    deleteURL: id => "/analysisField/" + id,
    postURL: "/analysisField",
    getListURL: "/analysisFields",
    maxWidth: "md",
    recordName: formVals => formVals.name,

    blankForm: {
        analysisFieldID: 0,
        name: "", 
        label: "",
        inputType: "text",
        regex: "",
        defaultValue: "",
        analysisListItems: [],
        analysisFieldStores: [],
        required: false,
        targetRevenue: false,
        targetQuantity: false,
        autoFillFrom: "",
    },
    form : function (props) {
        const [transformed, setTransformed] = useState(false)

        const inputTypes = [
            {
                value: "text",
                label: "Text entry"
            },
            {
                value: "single",
                label: "Single value from list"
            },
            {
                value: "multi",
                label: "Multiple values from list"
            },
        ]

        useEffect(() => {
            //when we field load the data convert the array of analysisFieldStores to a straightforward array of ints
            props.onChange({...props.formVals, analysisFieldStores: props.formVals.analysisFieldStores.map(s => s.storeID)})
            setTransformed(true)
        }, [props.formVals.analysisFieldID])
        
        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }

        function handleDefaultDDLChange(event) {
            if(props.formVals.inputType === "multi") {
                props.onChange({...props.formVals, defaultValue: event.target.value.join(", ")})
            } else {
                props.onChange({...props.formVals, defaultValue: event.target.value})
            }
        }

        return (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    value={props.formVals.name}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("name")}
                    helperText={props.validationResult.failMessages["name"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="dense"
                            id="label"
                            label="Label"
                            type="text"
                            value={props.formVals.label}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("label")}
                            helperText={props.validationResult.failMessages["label"]}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AutoCompleteManual 
                            multi={false}
                            id={"inputType"}
                            label="Input type"
                            onChange={handleChange}
                            placeholder="Choose input type"
                            value={props.formVals.inputType}
                            options={inputTypes}
                        />
                        
                    </Grid> 
                    <Grid item xs={12} sm={4}>
                        <CheckBox 
                            id="required"
                            label="Required"
                            tooltip="Field must be filled in when recording sales"
                            onChange={handleChange}
                            value={props.formVals.required}
                            error={props.validationResult.failMessages.hasOwnProperty("required")}
                            helperText={props.validationResult.failMessages["required"]}
                            disabled={props.disabled}
                        />
                    </Grid>               
                    <Grid item xs={12} sm={4}>
                        <CheckBox 
                            id="targetRevenue"
                            label="Revenue targets"
                            tooltip={props.formVals.inputType === "single" ? "Tick to allow revenue targets to be applied" : "Targets can only be applied when the input type is single"}
                            onChange={handleChange}
                            value={props.formVals.targetRevenue}
                            error={props.validationResult.failMessages.hasOwnProperty("targetRevenue")}
                            helperText={props.validationResult.failMessages["targetRevenue"]}
                            disabled={props.disabled || props.formVals.inputType !== "single"}
                        />
                    </Grid>               
                    <Grid item xs={12} sm={4}>
                        <CheckBox 
                            id="targetQuantity"
                            label="Quantity targets"
                            tooltip={props.formVals.inputType === "single" ? "Tick to allow quantity targets to be applied" : "Targets can only be applied when the input type is single"}
                            onChange={handleChange}
                            value={props.formVals.targetQuantity}
                            error={props.validationResult.failMessages.hasOwnProperty("targetQuantity")}
                            helperText={props.validationResult.failMessages["targetQuantity"]}
                            disabled={props.disabled || props.formVals.inputType !== "single"}
                        />
                    </Grid>               
                </Grid>
                
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <h4>Free text options</h4>
                        <TextField
                            margin="dense"
                            id="regex"
                            label="Regex pattern"
                            type="text"
                            value={props.formVals.regex}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("regex")}
                            helperText={props.validationResult.failMessages["regex"]}
                            fullWidth
                            disabled={props.disabled || props.formVals.inputType !== "text"}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                startAdornment: <InputAdornment position="start">^</InputAdornment>
                            }}
                        />                
                        <TextField
                            margin="dense"
                            id="defaultValue"
                            label="Default value"
                            type="text"
                            value={props.formVals.defaultValue}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("defaultValue")}
                            helperText={props.validationResult.failMessages["defaultValue"]}
                            fullWidth
                            disabled={props.disabled || props.formVals.inputType !== "text"}
                        />                
                    </Grid>
                    <Grid item md={6}>
                        <h4>List options</h4>
                        <AnalysisListItemSelector
                            id="analysisListItems"
                            value={props.formVals.analysisListItems || []}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("defaultValue")}
                            helperText={props.validationResult.failMessages["defaultValue"]}
                            disabled={props.disabled || !(["single", "multi"].includes(props.formVals.inputType))}
                            analysisFieldID={props.formVals.analysisFieldID}
                        />
                        <AutoCompleteManual 
                            multi={props.formVals.inputType === "multi"}
                            id="defaultDDL"
                            label="Default value"
                            onChange={handleDefaultDDLChange}
                            disabled={props.disabled || !(["single", "multi"].includes(props.formVals.inputType))}
                            placeholder="Choose default value"
                            value={props.formVals.inputType === "multi" ? props.formVals.defaultValue.split(", "): props.formVals.defaultValue}
                            options={(props.formVals.analysisListItems || []).map(li => {return {value: li.code, label: li.code}})}
                            clearable={true}
                            clearValue=""
                        />
                        <AutoCompleteManual 
                            multi={false}
                            id="autoFillFrom"
                            label="Autofill from"
                            onChange={handleChange}
                            disabled={props.disabled || !(["single"].includes(props.formVals.inputType))}
                            placeholder="Autofill from"
                            value={props.formVals.autoFillFrom}
                            options={[
                                {value: "rateCardLineBandName", label: "Band"},
                                {value: "rateCardLineDescriptionContains", label: "SKU description contains"},
                                //{value: "metricPart", label: "Metric (see notes)"},
                            ]}
                            clearable={true}
                            clearValue=""
                        />
                        {/* {
                            props.formVals.autoFillFrom !== "metricPart" ? null :
                                <Alert severity="info">
                                    Use the description field on the list option to specify the metric name and calculation part, like this: "Unlimited mix|Unlimited no Lite"
                                </Alert>
                        } */}
                    </Grid>
                </Grid>
                <h4>
                    Where this analysis field applies
                </h4>
                {
                    transformed ?
                        <AutoCompleteAsync 
                            multi={true}
                            disabled={props.disabled}
                            id={"analysisFieldStores"}
                            label="Stores"
                            onChange={handleChange}
                            placeholder="Choose some stores"
                            value={props.formVals.analysisFieldStores || []}
                            getURL={input => "/stores?pageLength=100&sort=name&sortDir=asc&page=0&quickFilter=" + encodeURIComponent(input)}
                            valueField="storeID"
                            labelField="name"
                            getURLValues={"/stores?storeID=" + (props.formVals.analysisFieldStores || []).join(",")}
                            labelFieldValues="name"
                            idField="storeID"
                            error={props.validationResult.failMessages.hasOwnProperty("analysisFieldStores")}
                            helperText={props.validationResult.failMessages["analysisFieldStores"]}
                        />
                    : null
                }
            </div>
    
        )
    },
}

