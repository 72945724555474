import React, {useState} from 'react'
import dateFormat from 'dateformat'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {Link} from 'react-router-dom'

import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import RateCardEntityForm from './rateCardEntityForm';
import RateCardCompareModal from './rateCardCompareModal'
import RateCardsAPIData from './rateCardsApiData'

const filters = [
]

const columnData = [
    { fieldName: 'rateCardID', default: false, name: 'ID', description: "The unchanging identifier of the rate card"},
    { fieldName: 'fromDate', default: true, name: 'From', description: "The date from which this rate card is effective", renderCell: dr => dateFormat(new Date(dr.fromDate), "dd/mm/yyyy") },
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the rate card"},
    { fieldName: 'rateCardLineCount', default: true, name: 'Rate card lines', description: 'A link to the lines of the rate card', renderCell: dr => <Link to={'/data/rateCards/' + dr.rateCardID + '/lines'}>{dr.rateCardLineCount} lines</Link>}
];



export default function RateCardsList(props) {
    const [compareOpen, setCompareOpen] = useState(false)
    const [rateCardIDs, setRateCardIDs] = useState([])

    function handleCompareOpen(rcIDs) {
        setCompareOpen(true)
        setRateCardIDs(rcIDs)
    }

    function handleCompareClose() {
        setCompareOpen(false)
    }

    //function for rendering the actions area when some items are selected
    function selectActions(props) {
        //calculated selected values
        const numSelected = props.selected.length;
        return (
            <Tooltip title={numSelected === 2 ? "Compare these rate cards" : "Select 2 rate cards to compare"}>
                <IconButton disabled={numSelected !== 2} aria-label={numSelected === 2 ? "Compare these rate cards" : "Select 2 rate cards to compare"} onClick={() => handleCompareOpen(props.selected)}>
                    <CompareArrowsIcon />
                </IconButton>
            </Tooltip>
        );
    }

    return(
        <div>
            <h1>
                Rate Cards
            </h1>
            <p>
                Rate cards list what can be sold, the price to the customer, and the revenue receivable.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={RateCardEntityForm}
                selectActions={selectActions}
                filters={filters} 
                title="Rate Cards"
                enableCrud={props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup")}
                sort="fromDate"
                sortDir="desc"
                me={props.me}
            />
            <RateCardsAPIData me={props.me} />
            <RateCardCompareModal 
                open={compareOpen}
                rateCardIDs={rateCardIDs}
                onClose={handleCompareClose}
                me={props.me}
            />
        </div>
    )
}