import React from 'react'
import Grid from '@material-ui/core/Grid'

//project
import CheckBox from '../../../shared/checkBox'
import ValueField from './valueField'

export default function FieldSet(props) {
    var reductionIsFixed = props.value.filter(l => l.storeID === props.store.storeID).length === 1 ? (props.value.filter(l => l.storeID === props.store.storeID)[0])[props.fieldPrefix + "ReductionIsFixed"] : false

    return (
        <>
            <h4>{props.title}</h4>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <CheckBox 
                        disabled={props.disabled || !props.captureVariablePercent} 
                        label={"Fixed reduction"} 
                        onChange={e => props.onChange(e, props.store.storeID, props.fieldPrefix + "ReductionIsFixed")} 
                        value={reductionIsFixed}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ValueField 
                        store={props.store} 
                        field={props.fieldPrefix + "ReductionFixed"}
                        label="Fixed reduction"
                        endAdornment="%"
                        onChange={props.onChange}
                        disabled={props.disabled || !reductionIsFixed}
                        value={props.value}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ValueField 
                        store={props.store} 
                        field={props.fieldPrefix + "ReductionRatio"}
                        label="Variable reduction"
                        endAdornment="x discount %"
                        onChange={props.onChange}
                        disabled={props.disabled || reductionIsFixed}
                        value={props.value}
                    />
                </Grid>
            </Grid>
        </>
    )
}