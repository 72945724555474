import React, {useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import Backdrop from '@material-ui/core/Backdrop'

import ValidationSummary from '../../shared/form/validationSummary';
import CopyModal from './copyModal'

export default function ActionButtons(props) {
    const [copyOpen, setCopyOpen] = useState(false)

    return(
        <Grid container spacing={3} >
            <Grid item xs={7}>
                <Button 
                    style={{marginTop: "10px"}} 
                    variant="contained"
                    color="primary"
                    onClick={props.handleSave}
                    startIcon={<SaveIcon />}
                    disabled={props.saving || props.saved}
                >
                    {
                        props.saving ?
                            "Saving..."
                        :
                            props.saved ?
                                "Saved"
                            :
                                "Save"
                    }
                </Button>
                {
                    props.saving ?
                        <Button 
                            style={{margin: "10px 0px 0px 10px"}} 
                            variant="contained"
                            color="default"
                            onClick={props.handleCancel}
                        >
                            Cancel
                        </Button>
                    : 
                        <>
                            <Button
                                onClick={() => setCopyOpen(true)}
                                variant="contained"
                                style={{margin: "10px 0px 0px 10px"}}
                                startIcon={<CopyIcon />}
                            >
                                Copy from another month...
                            </Button>
                            <CopyModal
                                open={copyOpen}
                                store={props.store}
                                onTargetsChange={props.handleTargetsChange}
                                onClose={() => setCopyOpen(false)}
                                targets={props.targets}
                                targetTargetDate={props.targetDate}
                            />
                        </>
                }
                
            </Grid>
            <Grid item xs={5} align="right">
                <ValidationSummary validationResult={props.validationResult} />
                <Backdrop open={props.saving} style={{zIndex: "9999"}} onClick={props.handleCancel}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                <   CircularProgress size={200} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Saving... click to cancel
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Backdrop>
            </Grid>
        </Grid>
    )
}
