import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import dateFormat from 'dateformat'

import rateCardLineEntityForm from '../../data/rateCards/rateCardLineEntityForm'
import rateCardEntityForm from '../../data/rateCards/rateCardEntityForm'
import LookupCell from '../../../shared/dataTable/lookupCell'
import {formatMoney} from '../../../../lib/general'

export default function RateCardDetails(props) {


    return (
        <Paper elevation={3} style={{margin: "18px 18px", padding: "25px"}} >
            <Grid container spacing={1} >
                <Grid item md={2} xs={5}>
                    Rate card:
                </Grid>
                <Grid item md={4} xs={7}>
                    {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                        <LookupCell
                            id={props.formVals.rateCardID} 
                            entityForm={rateCardEntityForm}
                            label={dateFormat(props.formVals.rateCardLineStore.rateCardLine.rateCard.fromDate, "dd/mm/yyyy", true)}
                        />
                    }
                </Grid>
                <Grid item md={2} xs={5}>
                    Rate card line:
                </Grid>
                <Grid item md={4} xs={7}>
                    {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                        <LookupCell
                            id={props.formVals.rateCardLineID} 
                            entityForm={rateCardLineEntityForm}
                            label={props.formVals.sku}
                            me={props.me}
                        />
                    }
                </Grid>
                <Grid item md={2} xs={5}>
                    RRP:
                </Grid>
                <Grid item md={4} xs={7}>
                    {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                        formatMoney(props.formVals.rateCardLineStore.rateCardLine.customerRRP)
                    }
                </Grid>
                <Grid item md={2} xs={5}>
                    Monthly:
                </Grid>
                <Grid item md={4} xs={7}>
                    {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                        formatMoney(props.formVals.rateCardLineStore.rateCardLine.customerMonthly)
                    }
                </Grid>
                {
                    props.me !== undefined && props.me.accessProfile !== undefined && props.me.accessProfile.showRevenue ?
                        <>
                            <Grid item md={2} xs={5}>
                                Revenue:
                            </Grid>
                            <Grid item md={4} xs={7}>
                                {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                                    formatMoney(props.formVals.rateCardLineStore.revenue)
                                }
                            </Grid>
                        </>
                    : null
                }
                {
                    props.me !== undefined && props.me.accessProfile !== undefined && props.me.accessProfile.showCommission ?
                        <>
                            <Grid item md={2} xs={5}>
                                Commission:
                            </Grid>
                            <Grid item md={4} xs={7}>
                                {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                                    formatMoney(props.formVals.rateCardLineStore.commission)
                                }
                            </Grid>
                        </>
                    : null
                }
                <Grid item md={2} xs={5}>
                    Contract length:
                </Grid>
                <Grid item md={4} xs={7}>
                    {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                        props.formVals.rateCardLineStore.rateCardLine.contractMonths
                    }
                </Grid>
                <Grid item md={2} xs={5}>
                    Type:
                </Grid>
                <Grid item md={4} xs={7}>
                    {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                        props.formVals.rateCardLineStore.rateCardLine.skuTypeName
                    }
                </Grid>
                <Grid item md={2} xs={5}>
                    Legacy SKU:
                </Grid>
                <Grid item md={4} xs={7}>
                    {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                        props.formVals.rateCardLineStore.rateCardLine.legacySKU
                    }
                </Grid>
                <Grid item md={2} xs={5}>
                    Band:
                </Grid>
                <Grid item md={4} xs={7}>
                    {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null :
                        props.formVals.rateCardLineStore.rateCardLine.bandName
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}