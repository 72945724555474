import React, { useState, useEffect } from 'react';
import {Route, Switch} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Login from '../../pages/user/login'
import LandingPage from '../../pages/landing/landingPage'

import Stores from '../../pages/data/stores/stores'
import StoreTypes from '../../pages/data/storeTypes/storeTypes'
import AnalsisFields from '../../pages/data/analysisFields/analysisFields'
import RateCards from '../../pages/data/rateCards/rateCards'
import RateCardsToolbar from '../../pages/data/rateCards/rateCardsToolbar'
import SKUTypes from '../../pages/data/skuTypes/skuTypes'
import Bands from '../../pages/data/bands/bands'
import Discounts from '../../pages/data/discounts/discounts'
import Users from '../../pages/data/users/users'
import UsersToolbar from '../../pages/data/users/usersToolbar'
import SaleTypes from '../../pages/data/saleTypes/saleTypes'
import Metrics from '../../pages/data/metrics/metrics'

import Sales from '../../pages/sales/sales'
import SalesToolbar from '../../pages/sales/salesToolbar'

import Announcements from '../../pages/announcements/announcements'

import Rota from '../../pages/rota/rota'
import RotaToolbar from '../../pages/rota/rotaToolbar'

import RecSheets from '../../pages/recSheets/recSheets';
import recSheetToolbar from '../../pages/recSheets/recSheetToolbar'

import Targets from '../../pages/targets/targets'
import TargetsToolbar from '../../pages/targets/targetsToolbar'

import Daily from '../../pages/daily/daily'
import DailyToolbar from '../../pages/daily/dailyToolbar'

import About from '../../pages/about/about'

import authFetch from '../../../lib/authFetch'

//all the routes for all the pages
const pageRoutes = [
    {path: '/user/login', page: Login},

    {path: '/data/stores', page: Stores},
    {path: '/data/storeTypes', page: StoreTypes},
    {path: '/data/analysisFields', page: AnalsisFields},
    {path: '/data/rateCards', page: RateCards, toolbar: RateCardsToolbar},
    {path: '/data/skuTypes', page: SKUTypes},
    {path: '/data/bands', page: Bands},
    {path: '/data/discounts', page: Discounts},
    {path: '/data/users', page: Users, toolbar: UsersToolbar},
    {path: '/data/saleTypes', page: SaleTypes},
    {path: '/data/metrics', page: Metrics},

    {path: '/sales', page: Sales, toolbar: SalesToolbar},

    {path: '/announcements', page: Announcements},

    {path: '/rota', page: Rota, toolbar: RotaToolbar},

    {path: '/targets', page: Targets, toolbar: TargetsToolbar},

    {path: '/daily', page: Daily, toolbar: DailyToolbar},

    {path: '/recSheets', page: RecSheets, toolbar: recSheetToolbar},

    {path: '/about', page: About},
]

export default function PageRoutes(props) {
    const [kpiCard, setKpiCard] = useState(null)
    const [tableSets, setTableSets] = useState([])
    const [hidden, setHidden] = useState("")
    const [myAnnouncements, setMyAnnouncements] = useState([])

    //get the user preference which indicates the hidden sections this user doesnt want to see
    useEffect(() => {
        authFetch("/user/userPreference/defaults/hiddenCards")
        .then(r => {
            if(r.validationResult.fail) {
                //no mappings
                return Promise.resolve("")
            } else {
                //there is something
                return Promise.resolve(r.data)
            }
        })
        .then(r => {
            setHidden(r)
        })
        .catch(err => {
            setHidden("")
        })
    }, [])

    function handleChangeHidden(newHidden) {
        //add a hidden and save it
        //console.log("New hidden " + newHidden)
        setNewHidden(newHidden + "")
    }

    function setNewHidden(newHidden) {
        //set this as the new hidden value
        setHidden(newHidden)
        //and save this as the users preference for next time
        var up = {
            value: newHidden
        }
        authFetch("/user/userPreference/defaults/hiddenCards", {method: "PUT", body: JSON.stringify(up)})
    }

    function handleAddHidden(addingHidden) {
        //console.log("Adding hidden " + addingHidden)
        //add a hidden and save it
        var comma = ","
        if(hidden === "") {
            comma = ""
        }
        setNewHidden(hidden + comma + addingHidden)
    }

    function handleNewKpiCard(newKpiCard) {
        setKpiCard(newKpiCard)
    }

    function handleNewTableSet(newTS) {
        //merge this with the tablesets we already have
        var anyMatching = tableSets.filter(ts => ts.tab === newTS.tab)
        //do we already have it?
        if(anyMatching.length === 0) {
            //no, add it
            setTableSets([
                ...tableSets,
                newTS
            ])
        } else {
            //modify the one that's there
            var newTableSets = []
            tableSets.forEach(t => {
                newTableSets.push({
                    tab: t.tab,
                    tableSet: (t.tab === newTS.tab ? newTS.tableSet : t.tableSet)
                })
            })
            setTableSets(newTableSets)
        }
    }

    function refreshMyAnnouncements() {
        //get any announcmeents that need to be shown tot he user
        authFetch("/announcements/myAnnouncements")
        .then(r => {
            setMyAnnouncements(r.listData)
        })
        .catch(err => console.log("Failed to fetch announcements"))
    }

    const noNarrowPadding = useMediaQuery("(min-width:1000px)")

    var mainStyle = {
        paddingLeft: "5px",
        paddingRight: "5px",
        width: "100%",
        boxSizing: "border-box"
    }
    if(noNarrowPadding) {
        mainStyle.paddingLeft = "24px"
        mainStyle.paddingRight = "24px"
    }

    return(
        <main className={props.mainClassName} style={mainStyle}>
            <div  className={props.drawerHeaderClassName} />
            <Switch>
                {
                    pageRoutes.map(pageRoute => {
                        const Page = pageRoute.page
                        return (
                            <Route key={pageRoute.path} path={pageRoute.path} render={routeProps => <Page {...routeProps} me={props.me} />} />
                        );
                    })
                }
                <Route 
                    path={""} 
                    render={routeProps => 
                        <LandingPage 
                            {...routeProps} 
                            me={props.me} 
                            kpiCard={kpiCard} 
                            onNewKpiCard={handleNewKpiCard} 
                            hidden={hidden}
                            onChangeHidden={handleChangeHidden}
                            onAddHidden={handleAddHidden}
                            tableSets={tableSets}
                            onNewTableSet={handleNewTableSet} 
                            onCardUsersChange={props.onCardUsersChange}
                            myAnnouncements={myAnnouncements}
                            refreshMyAnnouncements={refreshMyAnnouncements}
                        />
                    } 
                />
            </Switch>
        </main>
    )
}

export function ToolbarRoutes(props) {
    return(
        <div style={{display: "flex", overflow: "hidden", flexGrow: 1}}>
            {
                pageRoutes.map(pageRoute => {
                    const Toolbar = pageRoute.toolbar
                    if(Toolbar !== undefined) {
                        return (
                            <Route key={pageRoute.path} path={pageRoute.path} render={routeProps => <Toolbar {...routeProps} me={props.me} />} />
                        );
                    } else {
                        return null
                    }
                })
            }
        </div>
    )
}