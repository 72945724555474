import React, {useEffect} from 'react'
import authFetch from '../../../lib/authFetch'

export default function UrlRedirect(props) {
    //displays a loading message while loading the URL to send the user to 
    useEffect(() => {
        authFetch("/announcement/" + props.match.params.announcementID + "/url")
        .then(u => {
            window.location.href = u.data.url
        })
    }, [])


    return (
        "Loading link..."
    )
}