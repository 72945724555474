import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'

export default function PlanRotaToolbar(props) {
    //calculate the tab value
    let tabValue = false
    if(props.location.pathname.includes("/rota/plan")) {tabValue = 0}

    return (
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
            <Tab label="Plan" component={Link} to={"/rota/plan"} />
        </Tabs>
    );
}
