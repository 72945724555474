import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import {formatMoney} from '../../../../lib/general'
import SalesLink from '../../../shared/salesLink';

export default function BodyCol(props) {
    return (
        <>
            <TableCell scope="row" style={{padding: "4px", backgroundColor: props.shaded ? "#e8e8e8" : undefined, textAlign: "right"}}>
                <SalesLink
                    params={props.params}
                >
                    {props.quantity}
                </SalesLink>
            </TableCell>
            {
                props.me === undefined || !props.me.accessProfile.showRevenue ? null :
                    <TableCell style={{padding: "4px", backgroundColor: props.shaded ? "#e8e8e8" : undefined, textAlign: "right"}}>
                        <SalesLink
                            params={props.params}
                        >
                            {formatMoney(props.revenue, 2)}
                        </SalesLink>
                    </TableCell>
            }
        </>
    )
}