import React from 'react'

import {Link} from 'react-router-dom'

export default function Licences(props) {


    return(
        <div>
            <h2>
                System Licence
            </h2>
            <p>
                Your system is currently running under the following licence:
            </p>
            <p>
                <strong>
                    {props.me.licence}: {props.me.licenceName}
                </strong>
            </p>
            <p>
                To discuss the terms of your licence or to request changes to your licence please <Link to="/about/support">contact support</Link>.
            </p>
        </div>
    );
}
