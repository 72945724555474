import React from 'react'
import { Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function TitleWithAdornment(props) {
    const geq600 = useMediaQuery("(min-width:600px)")

    return (
        <Grid container spacing={0}>
            <Grid item xs={props.showing ? 9 : 12}>
                <h2 style={{marginTop: "12px", marginBottom: "12px"}}>
                    {props.title}
                </h2>
            </Grid>
            {
                props.showing ?
                    <Grid item xs={3} style={{textAlign: (geq600 ? "right" : "left"), padding: (geq600 ? undefined : "0px 0px 10px 0px")}}>
                        {props.children}
                    </Grid>
                : null
            }
        </Grid>
    )
}