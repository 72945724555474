import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import Popper from '@material-ui/core/Popper'

//project lib
import authFetch from '../../lib/authFetch'

export default function AutoCompleteNative(props) {
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    //console.log("Value is " + JSON.stringify(props.value))

    function getOptions() {
        //if no manual list of options is supplied, get the list from the optionsURL
        if(props.options === undefined) {
            setLoading(true)
            authFetch(props.optionsURL)
            .then(r => {
                //is there a function to apply after getting the options?
                if(props.optionsFn === undefined) {
                    setOptions(r.listData)
                } else {
                    setOptions(props.optionsFn(r.listData))
                }
                setLoading(false)
            })
        }
    }

    function getClearValue() {
        if(props.multiple) {
            return []
        } else {
            return props.clearValue
        }
    }

    useEffect(() => {
        //get the options on first load or if url changes
        getOptions()
    }, [props.optionsURL])

    useEffect(() => {
        //if options change or are passed, these take priority over the URL ones
        if(props.options !== undefined) {
            setOptions(props.options)
        }
    }, [JSON.stringify(props.options)])

    function handleChange(e, newVal) {
        //the actual selected value has changed
        if(newVal === null) {
            //return the value when clear
            raiseChangeEvent(getClearValue())
        } else {
            //something is selected
            //translate it to a value
            //console.log("Selected option " + JSON.stringify(newVal))
            if(props.multiple) {
                raiseChangeEvent(newVal.map(o => props.optionValue(o)))
            } else {
                raiseChangeEvent(props.optionValue(newVal))
            }
            
        }
    }

    function raiseChangeEvent(newVal) {
        //construct a pseudo event to be handled by the parent
        //console.log("Change raised, new value: " + JSON.stringify(newVal))
        var e = {
            target: {
                id: props.id,
                value: newVal
            }
        }
        props.onChange(e)
    }

    const PopperMy = function (p) {
        return (<Popper {...p} placement={props.placement} />)
    }

    return (
        <Autocomplete 
            multiple={props.multiple}
            value={(
                props.value === getClearValue() ? null : 
                    props.multiple ?
                        options.filter(o => (Array.isArray(props.value) ? props.value : []).includes(props.optionValue(o))) || null
                    :
                        options.filter(o => props.optionValue(o) === props.value)[0] || null
            )}
            onChange={handleChange}
            getOptionLabel={props.optionLabel}
            options={options}
            disabled={props.disabled}
            size={props.multiple ? "small" : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="dense"
                    label={props.label}
                    placeholder={props.disabled ? "" : props.placeholder}
                    fullWidth
                    error={props.error}
                    helperText={props.helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            PopperComponent={PopperMy}
        />
    )
}