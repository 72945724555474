import React, {useState, useEffect} from 'react'
import dateFormat from 'dateformat'

import MonthHeader from '../../../shared/monthHeader'
import StoreTabs from '../../../shared/storeTabs'
import storeEntityForm from '../../data/stores/storeEntityForm'
import DailyStatGrid from './dailyStatGrid'
import DailyStatsApiData from './dailyStatsApiData'
import { getDefaultStoreID } from '../../../../lib/general'

export default function TargetConsole(props) {
    const defaultParams = {
        storeID: getDefaultStoreID(props.me),
        dailyStatDate: dateFormat(new Date(), "yyyy-mm-01") //first day of this month
    }
    //checking for missing params
    //routes dictate either all or none are supplied
    useEffect(() => {
        //check for if none is supplied
        if(props.match.params.dailyStatDate === undefined || props.match.params.storeID === undefined) {
            //a param is missing, redirect to the defaults
            props.history.push("/daily/dailyStats/" + defaultParams.dailyStatDate + "/" + defaultParams.storeID)
        }
    }, [
        props.match.params.dailyStatDate,
        props.match.params.storeID,
    ])

    const store = (props.me.accessProfile.accessProfileStores.filter(a => a.storeID + "" === props.match.params.storeID + "")[0] || {store: storeEntityForm.blankForm}).store
    //console.log("Param storeid is " + JSON.stringify(props.me.accessProfile.accessProfileStores))

    function handleTargetDateChange(newDate) {
        props.history.push("/daily/dailyStats/" + dateFormat(newDate, "yyyy-mm-dd") + "/" + props.match.params.storeID)
    }

    function handleStoreIDChange(newStoreID) {
        props.history.push("/daily/dailyStats/" + props.match.params.dailyStatDate + "/" + newStoreID)
    }

    if(props.match.params.dailyStatDate === undefined || props.match.params.storeID === undefined) {
        //no params passed, redirecting to defaults, in the meantime return null
        return null
    } else {
        return (
            <div>
                <MonthHeader
                    monthDate={new Date(props.match.params.dailyStatDate)}
                    onChange={handleTargetDateChange}
                />
                <StoreTabs
                    store={store}
                    onChange={s => handleStoreIDChange(s.storeID)}
                    me={props.me}
                />
                <DailyStatGrid
                    me={props.me}
                    store={store}
                    dailyStatDate={new Date(props.match.params.dailyStatDate)}
                    highlightTargetID={props.match.params.highlightTargetID}
                />
                <DailyStatsApiData 
                    me={props.me}
                />
            </div>
        )
    }

    
}