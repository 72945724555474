import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import CheckBox from '../../../shared/checkBox'
import AutoComplete from '../../../shared/autoComplete'

export default {
    displayName: "Store",
    idField: "storeID",

    getURL: id => "/stores?storeID=" + id,
    putURL: id => "/store/" + id,
    deleteURL: id => "/store/" + id,
    postURL: "/store",
    getListURL: "/stores",
    maxWidth: "sm",
    recordName: formVals => formVals.name,

    blankForm: {
        storeID: 0, 
        name: "", 
        storeTypeID: 0,
        targetRevenue: false,
        targetQuantity: false,
    },
    form : function (props) {
        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }
    
        return (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    value={props.formVals.name}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("name")}
                    helperText={props.validationResult.failMessages["name"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <AutoComplete 
                    multi={false}
                    id="storeTypeID"
                    label="Type"
                    onChange={handleChange}
                    placeholder="Pick the store type"
                    value={props.formVals.storeTypeID}
                    getURL="/storeTypes"
                    valueField="storeTypeID"
                    labelField="name"
                    error={props.validationResult.failMessages.hasOwnProperty("storeTypeID")}
                    helperText={props.validationResult.failMessages["storeTypeID"]}
                    disabled={props.disabled}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <CheckBox 
                            id="targetRevenue"
                            label="Revenue targets"
                            tooltip={"Tick to allow revenue targets to be applied"}
                            onChange={handleChange}
                            value={props.formVals.targetRevenue}
                            error={props.validationResult.failMessages.hasOwnProperty("targetRevenue")}
                            helperText={props.validationResult.failMessages["targetRevenue"]}
                            disabled={props.disabled}
                        />
                    </Grid>               
                    <Grid item xs={12} sm={6}>
                        <CheckBox 
                            id="targetQuantity"
                            label="Quantity targets"
                            tooltip={"Tick to allow quantity targets to be applied"}
                            onChange={handleChange}
                            value={props.formVals.targetQuantity}
                            error={props.validationResult.failMessages.hasOwnProperty("targetQuantity")}
                            helperText={props.validationResult.failMessages["targetQuantity"]}
                            disabled={props.disabled}
                        />
                    </Grid>               
                </Grid>
                <h3>
                    Reporting information
                </h3>
                <TextField
                    margin="dense"
                    id="postCode"
                    label="Postcode (optional)"
                    type="text"
                    value={props.formVals.postCode}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("postCode")}
                    helperText={props.validationResult.failMessages["postCode"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <TextField
                    margin="dense"
                    id="squareFeet"
                    label="Square feet"
                    type="text"
                    value={props.formVals.squareFeet}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("squareFeet")}
                    helperText={props.validationResult.failMessages["squareFeet"]}
                    fullWidth
                    disabled={props.disabled}
                />
            </div>
    
        )
    },
}

