import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'

export default function ViewRotaToolbar(props) {
    //calculate the tab value
    let tabValue = false
    if(props.location.pathname.includes("/rota/view/user/")) {tabValue = 0}
    if(props.location.pathname.includes("/rota/view/store/")) {tabValue = 1}
    if(props.location.pathname.includes("/rota/view/approval")) {tabValue = 2}

    return (
        props.me === undefined ?
            <div>
                Loading...
            </div>
        :
            <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
                <Tab label="User" component={Link} to={"/rota/view/user/" + props.me.userID} />
                <Tab label="Store" component={Link} to={"/rota/view/store/" + props.me.storeID} />
                {
                    props.me.accessProfile.systemAreas.split(", ").includes("ApproveRotaLines") ?
                        <Tab label="Approval" component={Link} to={"/rota/view/approval"} />
                    : null
                }
            </Tabs>
    );
}
