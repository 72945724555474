import React from 'react'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Card from '@material-ui/core/Card'

export default function KPICard(props) {
    
    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card>
                <CardContent style={{textAlign: "center", minHeight: props.minHeight}}>
                    {props.children}
                </CardContent>
                <CardActions style={{justifyContent: "center"}}>
                    {props.action}
                </CardActions>
            </Card>
        </Grid>
    )
}