import React, {useState} from 'react'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'

import CrudModal from '../../../shared/crudModal'
import saleEntityForm from './saleEntityForm'

export default function RefundAlert(props) {
    const [modalOpen, setModalOpen] = useState(false)
    
    function handleModalClose() {
        setModalOpen(false)
    }

    function handleClick() {
        setModalOpen(true)
    }

    return (
        //do not show the alert when the refund is still being processed
        props.formVals.isRefundedOrderRef === undefined && (props.formVals.isRefund || props.formVals.isRefunded) ?
            <div>
                <Alert style={{margin: "20px"}} severity="warning" action={
                    <Button onClick={handleClick}>
                        View {props.formVals.isRefund ? "Sale" : "Refund"}
                    </Button>
                }>
                    {
                        props.formVals.isRefund ?
                            <span><strong>This is a refund</strong> of sale number {props.formVals.refSaleID}</span>
                        :
                            <span><strong>This sale has been refunded</strong> on refund number {props.formVals.refSaleID}</span>
                    }
                    
                </Alert>
                <CrudModal
                    action="read"
                    open={modalOpen}
                    id={props.formVals.refSaleID}
                    entityForm={saleEntityForm}
                    onClose={handleModalClose}
                    me={props.me}
                />
            </div>
        : null
    )
}