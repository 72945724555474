import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function ValidationModal(props) {
    return(
        <Dialog open={props.open} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Scanning failed
            </DialogTitle>
            <DialogContent>
                <p>Scanning failed: <strong>{props.failSummary}</strong></p>
                <p>Please try again or enter the data manually</p>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}