import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

//icons
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ReadIcon from '@material-ui/icons/Search'

export default function CrudCell(props) {
    //props to be passed are 
    //rowID (the ID of the record being edited) and 
    //action (delete, update, copy) and 
    //entityName to provide a meaningful tooltip and
    //handleClick a callback to handle the button being clicked
    var title = ""
    title = props.action === 'read' ? "View this " + props.entityName : title
    title = props.action === 'delete' ? "Delete this " + props.entityName : title
    title = props.action === 'copy' ? "Copy this " + props.entityName : title
    title = props.action === 'update' ? "Edit this " + props.entityName : title

    function handleClick() {
        props.onClick(props.rowID, props.action)
    }

    return (
        <Tooltip title={title}>
            <IconButton size="small" onClick={handleClick}>
                {props.action === 'read' ? <ReadIcon size="small"></ReadIcon> : undefined}
                {props.action === 'delete' ? <DeleteIcon size="small"></DeleteIcon> : undefined}
                {props.action === 'copy' ? <FileCopyIcon size="small"></FileCopyIcon> : undefined}
                {props.action === 'update' ? <EditIcon size="small"></EditIcon> : undefined}
            </IconButton>
        </Tooltip>
    )
}