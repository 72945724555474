import React, {useState, useEffect} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import IconButton from '@material-ui/core/IconButton';
import {Link} from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"

import {appearsLoggedIn, logOut} from "../../../lib/auth"
import userEntityForm from '../../pages/data/users/userEntityForm' 
import CrudModal from '../crudModal'
import authFetch from '../../../lib/authFetch'
import {initialsFromName} from '../../../lib/general' 

export default function AccountMenu(props) {
    const [anchor, setAnchor] = useState(null)
    const [crudModalOpen, setCrudModalOpen] = useState(false)

    function handleClick(event) {
        setAnchor(event.currentTarget)
    }

    function handleClose() {
        setAnchor(null)
    }

    function handleLogOut() {
        handleClose() //not that it really matters
        logOut() //removes the cookie
        //now refresh the app manifesting the changes
        window.location.href = "/"
    }

    function handleProfile() {
        handleClose()
        setCrudModalOpen(true)
    }

    function handleCrudClose() {
        setCrudModalOpen(false)
    }

    return(
        <div style={{width: "48px", height: "48px", display: "flex"}}>
            <Avatar onClick={handleClick}>
                {initialsFromName(props.me.name)}
            </Avatar>
            <Menu
                id="loggedInMenu"
                anchorEl={anchor}
                onClose={handleClose}
                open={Boolean(anchor)}
            >
                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                <MenuItem onClick={handleLogOut}>Log out</MenuItem>
            </Menu>
            <CrudModal 
                entityForm={userEntityForm}
                action={"update"}
                open={crudModalOpen}
                onClose={handleCrudClose}
                id={props.me.userID}
                //overrideOnCopy={props.overrideOnCopy}
            />
        </div>
    );
}