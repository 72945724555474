import React, {useState} from 'react'
import DateRangeIcon from '@material-ui/icons/DateRange'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import dateFormat from 'dateformat'

import DataTable from '../../dataTable/dataTable'

export default function HistoryDataTable(props) {
    const columnData = [
        { fieldName: 'createdDate', default: true, name: 'Date', description: "The date the history was made", renderCell: dr => dateFormat(dr.createdDate, "dd/mm/yyyy HH:MM:ss", true)},
        { fieldName: 'createdByUserName', default: true, name: 'User', description: "User who made the change to the " + props.entityDisplayName},
        { fieldName: 'description', default: true, name: 'Description', description: "Description of the action resulting in the history entry"},
        { fieldName: 'step', default: true, name: 'Step', description: "The current step the " + props.entityDisplayName + " is at", renderCell: dr => getStepName(dr.step)},

    ];

    function getStepName(step) {
        //see if we can find the right step in the passed steps
        const foundStep = props.steps.filter(s => s.name === step)
        //we found it?
        if(foundStep.length === 1) {
            return (foundStep[0].displayName)
        } else {
            return step
        }
    }

    return(
        <DataTable
            columnData={columnData} //not addind audit fields here as need them to be shown by default
            getListURL={"/histories"}
            filters={{documentType: props.documentType, documentID: props.documentID}} 
            title={(props.entityDisplayName) + " " + props.documentID + " history"}
            //entityName="history"
            //entityDisplayName="Material Acceptance Compliance"
            //entityForm={macEntityForm}
            //blankForm={blankForm}
            idField="historyID"
            enableCrud={false}
            sort="createdDate"
            sortDir="asc"
            //maxWidth="lg"
        />
    )
}