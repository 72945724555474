import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'
import {Route, Switch} from 'react-router-dom'
import ViewRotaToolbar from './view/viewRotaToolbar';
import PlanRotaToolbar from './plan/planRotaToolbar';

export default function RotaToolbar(props) {


    return (
        <Switch>
            <Route path="/rota/view" render={renderProps => <ViewRotaToolbar {...renderProps} me={props.me} />} />
            <Route path="/rota/plan" render={renderProps => <PlanRotaToolbar {...renderProps} me={props.me} />} />
        </Switch>
    )
}
