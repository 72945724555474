import React, {useEffect} from 'react'
import { Tooltip, Grid } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TitleWithAdornment from './titleWithAdornment';

export default function RevenueCommissionSwitchTitle(props) {
    const geq700 = useMediaQuery("(min-width:700px)")

    useEffect(() => {
        //guard againstr a missing props.me
        if(props.me !== undefined && (props.me.revenueCommission === undefined || props.me.revenueCommission === [])) {
            //it's not currently set to a valid value.  need to set it
            //but what value?  check the accessProfile
            if(props.me.accessProfile.showRevenue && props.me.accessProfile.showCommission) {
                //could be either, default to revenue
                handleChange(["revenue"])
            } else if (props.me.accessProfile.showCommission) {
                //only commission
                handleChange(["commission"])
            } else {
                //default to revenue.  It's invalid to have none ticked
                handleChange(["revenue"])
            }
        }
    }, [JSON.stringify(props.me)])

    function handleChange(newVal) {
        if(newVal.length !== 0) {
            props.me.handleNewMe({
                ...props.me,
                revenueCommission: newVal
            })
        }
    }

    return (
        <TitleWithAdornment 
            title={props.title}
            showing={props.me.accessProfile.showRevenue && props.me.accessProfile.showCommission}
        >
            <ToggleButtonGroup
                value={props.me.revenueCommission}
                onChange={(e, newVal) => handleChange(newVal)}
                aria-label="Revenue or commission"
                size="small"
                style={{marginTop :"12px"}}
            >
                <ToggleButton value="revenue" aria-label="Revenue">
                    <Tooltip title="Show revenue">
                        <span>{geq700 ? "Revenue" : "Rev"}</span>
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value="commission" aria-label="Commission">
                    <Tooltip title="Show commission">
                        <span>{geq700 ? "Commission" : "Com"}</span>
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>
        </TitleWithAdornment>
    )
}