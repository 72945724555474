import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'

//components
import ValidationSummary from '../../shared/form/validationSummary'
//import StoreEntityForm from '../stores/storeEntityForm'
//import SKUHistoryCell from './skuHistoryCell'

//lib
import authFetch from '../../../lib/authFetch';

const blankValidationResult = {
    fail: false,
    failMessages: {}
}

export default function DeleteModal(props) {
    const [validationResult, setValidationResult] = useState(blankValidationResult)

    function handleCommit() {
        //make the api call, then close the modal
        authFetch("/recSheetLines", {method: "DELETE", body: JSON.stringify({recSheetLineIDs: props.recSheetLineIDs})})
        .then(r => {
            setValidationResult(r.validationResult)
            if(!r.validationResult.fail){
                //it's done
                props.onDataChange()
                props.onClose()
            }
        })
    }

    function handleEnter() {
        setValidationResult(blankValidationResult)
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                Deleting {props.recSheetLineIDs.length} lines
            </DialogTitle>
            <DialogContent>
                <p>Are you sure you want to delete {props.recSheetLineIDs.length} rec sheet lines?</p>
                <p><strong>This cannot be undone!</strong></p>
                <ValidationSummary 
                    validationResult={validationResult} 
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button 
                    color="secondary" 
                    variant="contained" 
                    onClick={handleCommit} 
                >
                    Delete {props.recSheetLineIDs.length} Rec Sheet Lines
                </Button>
            </DialogActions>
      </Dialog>
    )
}