import React, {useState, useEffect} from 'react'
import TimeSlice from './timeSlice'
import authFetch from '../../../../lib/authFetch'
import SliceSelector from './sliceSelector'

export default function TableSet(props) {
    //const timeSliceInvalid = (!Array.isArray(props.tableSet.timeSlices) || props.tableSet.timeSlices.length === 0)
    const [timeSlice, setTimeSlice] = useState(props.tableSet === null || props.tableSet === undefined ? null : props.tableSet.timeSlices[0])

    //on first load, and if any of the params change, reload the tableSet
    useEffect(() => {
        props.onLoadingChange(true)
        //which url are we going to use?
        var url
        if(props.drhOnly) {
            //only the drh tables.  no targets or anything
            //so this is the daily report
            url = "/kpi/drhTable?fromDate=" + props.fromDate + "&toDate=" + props.toDate + "&storeID=" + props.storeIDs.join(",") + "&splitBy=" + props.splitBy
        } else {
            //this si the whole lot, for the landing page
            url = "/kpi/tables?date=" + props.date + "&storeID=" + props.storeIDs.join(",") + "&splitBy=" + props.splitBy
        }
        authFetch(url)
        .then(r => {
            props.onLoadingChange(false)
            props.onNewTableSet(r.data.tableSet)
            setTimeSlice(r.data.tableSet.timeSlices[0])
        })
        .catch(err => {
            props.onLoadingChange(false)
        })
    }, [props.storeIDs.join(","), props.date, props.splitBy, props.fromDate, props.toDate])

    //is there a tableset yet?
    if(props.tableSet === null || timeSlice === null) {
        //nope
        return (
            <div style={{minHeight: "800px"}}>
                <p>Loading tables...</p>
            </div>
        )
    }

    

    function handleTimeSliceChange(e) {
        //set the new time slice
        //is it found? check in case they clicked 'clear' in the ddl
        var found = props.tableSet.timeSlices.filter(t => t.name === e.target.value)
        if(found.length === 1) {
            setTimeSlice(found[0])
        }
    }

    return (
        <>
            <SliceSelector
                timeSlices={props.tableSet.timeSlices}
                timeSlice={timeSlice}
                onChange={e => handleTimeSliceChange(e)}
            />
            <TimeSlice
                me={props.me}
                timeSlice={timeSlice}
                loading={props.loadingKPI}
                storeIDs={props.storeIDs}
                date={props.date}
                fromDate={props.fromDate}
                toDate={props.toDate}
                splitBy={props.splitBy}
                drhOnly={props.drhOnly}
            />
        </>
    )
}