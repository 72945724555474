import React from 'react'

export default function Approval(props) {
    

    return(
        props.me.accessProfile.systemAreas.split(", ").includes("ApproveRotaLines") ?
            <>
                Approvals
            </>
        : null
    )
}