import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'

export default function RateCardToolbar(props) {
    //calculate the tab value
    let tabValue = false
    //console.log("PLP is " + props.location.pathname + " and match is ")
    if(props.location.pathname === "/data/rateCards") {tabValue = 0}
    if(props.location.pathname === "/data/rateCards/all") {tabValue = 1}

    return (
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
            <Tab label="Current Rate Card" component={Link} to={"/data/rateCards"} />
            <Tab label="All Rate Cards" component={Link} to={"/data/rateCards/all"} />
        </Tabs>
    );
}
