import React, {useState, useEffect} from 'react'
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import {Prompt} from 'react-router-dom'
import dateFormat from 'dateformat'

import authFetch from '../../../lib/authFetch';
import {byName} from '../../../lib/general'
import TargetsTable from './targetsTable'
import ActionButtons from './actionButtons'
import { recalculateHoursTargets, setDefaultHoursWeights} from './hoursCell';
import HoursInputChangeModal from './hoursInputChangeModal';

const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}

export default function TargetGrid(props) {
    const [users, setUsers] = useState([]) //for providing a list of users who are targetted on total
    const [analysisFields, setAnalysisFields] = useState([])
    const [targets, setTargets] = useState([])
    const [saved, setSaved] = useState(true)
    const [saving, setSaving] = useState(false)
    const [validationResult, setValidationResult] = useState(blankValidationResult)
    const [potentialHoursInput, setPotentialHoursInput] = useState(false)
    const [hoursInputChangeModalOpen, setHoursInputChangeModalOpen] = useState(false)

    //this decides how the user is inputting targets - the starndard way or the hours way
    //if all targets have 0 hours and weight then using standard
    var hoursInput = (targets.reduce((acc, cur) => acc + parseFloat(cur.hours) + parseFloat(cur.weight), 0) > 0)
    //standard: they input individual targets and this decides what is the total for the store
    //hourd: they input the store totals and an hours and weight figure for each user, and this is used to distrubute the total to each user
    //it is not stored explicitly in state, becasue at any time it is possible to deduce this from the targets data

    function handleHoursInputChange(e) {
        //do we need to confirm this?  Yes, if there are targets
        if(targets.length === 0) {
            //no need to confirm
            handleHoursInputConfirm(e.target.checked)
        } else {
            //there are targets, need to confirm
            //open the modal to confirm the destructive change
            //what are we potentially changing to?
            setPotentialHoursInput(e.target.checked)
            //open the modal
            setHoursInputChangeModalOpen(true)
        }
    }

    function handleHoursInputConfirm(newValue) {
        //close the modal if open
        setHoursInputChangeModalOpen(false)
        //the targets can now be considered not saved!
        setSaved(false)
        //if they are enabling or enabling we need to do different things
        if (newValue) {
            //they are enabling hours
            var newTargets = setDefaultHoursWeights(targets, users, props.store, analysisFields, props.targetDate)
            //recalculate target values now that weights have canged
            recalculateHoursTargets(newTargets, props.store, props.targetDate, analysisFields)
            //set all targets to be distributed to the first user (1 and 1)
            setTargets(newTargets)
        } else {
            //they are disabling hours
            //on all targets set hours and weight to 0
            setTargets(
                targets.map(t => ({
                    ...t,
                    hours: 0,
                    weight: 0, //when all hours and weights are 0 it means using standard input
                }))
            )
        }
    }

    function byLabel(a, b) {
        //for sorting lists by name
        if(a.label > b.label) {return 1}
        if(a.label < b.label) {return -1}
        return 0
    }
    //effect gets the data whenever any of the things driving the grid data change
    useEffect(() => {
        //there are a few API calls to make
        props.setLoading(true)
        var proms = []
        const myAbortController = new AbortController()
        if(props.store.storeID === 0) {
            props.setLoading(false)
        } else {
            proms.push(
                authFetch("/accessProfileStores?storeID=" + props.store.storeID + "&fkJoins=" + encodeURIComponent(["accessProfile","accessProfile.users"].join(",")), {signal: myAbortController.signal})
                .then(r => {
                    var u = r.listData.map(aps => aps.accessProfile.users).reduce((acc, cur) => acc.concat(cur), []).filter(u => u !== null && u !== undefined).filter(u => u.active).sort(byName)
                    //console.log("Users: " + JSON.stringify(u))
                    setUsers(u) 
                }),
                authFetch("/analysisFieldStores?fkJoins=" + encodeURIComponent(["analysisField","analysisField.analysisListItems"].join(",")) + "&storeID=" + props.store.storeID, {signal: myAbortController.signal})
                .then(r => {
                    setAnalysisFields(r.listData.map(afs => afs.analysisField).filter(af => af.inputType === "single" && (af.targetRevenue || af.targetQuantity)).sort(byLabel))
                }),
                authFetch("/targets/actuals?storeID=" + props.store.storeID + "&targetDate=" + dateFormat(props.targetDate, "UTC:yyyy-mm-dd"), {signal: myAbortController.signal})
                .then(r => {
                    setTargets(r.listData)
                    setSaved(true)
                    setSaving(false)
                }),
            )
        }
        Promise.all(proms)
        .then(na => {
            props.setLoading(false)
        })
        return () => {
            myAbortController.abort()
        }  
    }, [
        JSON.stringify(props.store),
        dateFormat(props.targetDate, "yyyy-mm-dd"),
        props.view
    ])

    function validTargets(t) {
        //must be for one of the valid users
        if(!users.map(u => u.userID + "").includes(t.targetForUserID + "")) {
            console.log("Invalid target " + t.targetForUserID + " because of targetForUserID")
            return false
        }
        if(!(t.userID + "" === "0" || users.map(u => u.userID + "").includes(t.userID + ""))) {
            console.log("Invalid target " + t.targetForUserID + " because of userID")
            return false
        }
        //is this a store one (analysisFieldID is 0)
        if(t.analysisFieldID + "" === "0") {
            //check if it's quantity or revenue and that that is valid
            if((t.targetType === "revenue" && !(props.store.targetRevenue)) || (t.targetType === "quantity" && !(props.store.targetQuantity))) {
                console.log("Invalid target " + t.targetForUserID + " because of conflict between target and store targetting status")
                return false
            }
        }
        //check this is one of the analysisfields
        if(t.analysisFieldID !== 0) {
            var matchingAF = analysisFields.filter(af => af.analysisFieldID === t.analysisFieldID)
            if(matchingAF.length === 0) {
                console.log("Invalid target " + t.targetForUserID + " because of analysis field missing")
                return false
            }
            //check the analysislistitem exists
            if(!matchingAF[0].analysisListItems.map(ali => ali.analysisListItemID + "").includes(t.analysisListItemID + "")) {
                console.log("Invalid target " + t.targetForUserID + " because of analysis list item not existing")
                return false
            }
            //check the revenue quantity is valid
            if((t.targetType === "revenue" && !(matchingAF[0].targetRevenue)) || (t.targetType === "quantity" && !(matchingAF[0].targetQuantity))) {
                console.log("Invalid target " + t.targetForUserID + " because of conflict between target and af monitoring status")
                return false
            }
        }
        return true
    }

    //var tempTargets = []
    function handleTargetsChange(newTargets) {
        setSaved(false)
        //console.log("Targets is now " + JSON.stringify(newTargets))
        setTargets(newTargets)
    }

    function handleSave() {
        //send the targets tot he server
        setSaving(true)
        authFetch("/targets/" + props.store.storeID + "/" + dateFormat(props.targetDate, "UTC:yyyy-mm-dd"), {method: "PUT", body: JSON.stringify({targets: targets.filter(validTargets)})})
        .then(r => {
            //the server responds with the same list of targets, and any validatoin issues
            setValidationResult(r.validationResult)
            setSaving(false)
            setTargets(r.listData)
            if(r.validationResult.fail) {
                setSaved(false)
            } else {
                setSaved(true)
            }
        })
    }

    function handleCancel() {
        setSaving(false)
        props.setLoading(false)
    }

    if(props.store.storeID === 0) {
        return (
            <p>No store selected...</p>
        )
    }

    return(
        <>
            <FormGroup>
                <FormControlLabel control={<Switch checked={hoursInput} onChange={handleHoursInputChange} />} label="Input using hours to distribute totals" />
            </FormGroup>
            <Prompt
                when={!saved}
                message="You have NOT saved your changes, are you sure you want to continue?"
            />
            <TargetsTable
                me={props.me}
                targets={targets}
                analysisFields={analysisFields}
                targetDate={props.targetDate}
                highlightTargetID={props.highlightTargetID}
                store={props.store}
                users={users}
                view={props.view}
                onTargetsChange={handleTargetsChange}
                hoursInput={hoursInput}
            />
            <ActionButtons
                me={props.me}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleTargetsChange={handleTargetsChange}
                saving={saving}
                saved={saved}
                store={props.store}
                targets={targets}
                targetDate={props.targetDate}
                validationResult={validationResult}
            />
            <HoursInputChangeModal
                me={props.me}
                potentialHoursInput={potentialHoursInput}
                onChange={handleHoursInputConfirm}
                open={hoursInputChangeModalOpen}
                onClose={() => setHoursInputChangeModalOpen(false)}
            />
        </>
    )
}

function targetsAreEqual(t1, t2) {
    return (
        t1.storeID === t2.storeID &&
        t1.userID === t2.userID &&
        t1.targetForUserID === t2.targetForUserID &&
        t1.analysisFieldID === t2.analysisFieldID &&
        t1.analysisListItemID === t2.analysisListItemID &&
        t1.targetType === t2.targetType &&
        dateFormat(t1.targetDate, "yyyy-mm-dd") === dateFormat(t2.targetDate, "yyyy-mm-dd")
    )
}

export {targetsAreEqual}