import React from 'react'
import Grid from '@material-ui/core/Grid'

//project
import CheckBox from '../../../shared/checkBox'
import ValueField from './valueField'
import { accessibleStores } from '../../../../lib/general'

export default function RateCardLineStoreSelector(props) {
    const stores = props.me === undefined ? [] : accessibleStores(props.me)

    function handleRevenueCommissionChange(event, storeID, field) {
        //console.log("Revenue on store " + storeID + " changed to " + event.target.value)
        //check it's a decimal number before updating the relevent row in value
        var decimal = new RegExp("^[0-9]*([.][0-9]{0,2}){0,1}$")
        if(decimal.test(event.target.value)) {
            //console.log("Regex ok")
            //it fits the pattern, update the row in value
            //we know there is a row in value by now
            var newVals = []
            props.value.forEach(s => {
                var thisOne = {...s}
                if(s.storeID === storeID) {
                    thisOne[field] = event.target.value
                }
                newVals.push(thisOne)
            })
            //construct a pseudo event to pass up to the parent form
            buildEvent(newVals)
        } else {
            //do nothing since it doesn't fit the pattern
            //console.log("Regex failed")
        }
    }

    function buildEvent(withNewVal) {
        var e = {
            target: {
                id: props.id,
                value: withNewVal
            }
        }
        //console.log("Calling change with " + JSON.stringify(withNewVal))
        props.onChange(e)
    }

    function handleCheckboxChange(event, storeID) {
        //decide if we're adding or removing it
        var newVals = []
        if(event.target.value) {
            //they have ticked
            //if it exists then set active to true, else add it
            if(props.value.filter(s => s.storeID === storeID).length === 1) {
                //it exists
                props.value.forEach(s => {
                    var thisOne = {...s}
                    if(s.storeID === storeID) {
                        thisOne.active = true
                    }
                    newVals.push(thisOne)
                })
            } else {
                //it does not exist, add it
                newVals.push(...props.value, {storeID: storeID, active: true, rateCardLineID: props.rateCardLineID, revenue: "0", commission: "0"})
            }
        } else {
            //they have unticked
            //set it as inactive.  unless revenue and commission is "" then delete it
            props.value.forEach(s => {
                var thisOne = {...s}
                if(s.storeID === storeID) {
                    thisOne.active = false
                }
                if(s.storeID === storeID && s.revenue === "0" && s.commission === "0") {
                    //skip this one, deleting it from the list, since revenue is blank
                } else {
                    newVals.push(thisOne)
                }
            })
        }
        //construct a pseudo event to pass up to the parent form
        buildEvent(newVals)
    }

    const showRevenue = props.me === undefined ? false : props.me.accessProfile.showRevenue
    const showCommission = props.me === undefined ? false : props.me.accessProfile.showCommission
    const width = showRevenue && showCommission ? 4 : 6


    return (
        <div>
            {stores.map(s => {
                return (
                    <Grid container spacing={2} key={s.storeID} alignContent="flex-end">
                        <Grid item xs={width} style={{alignItems: "flex-end", display: "flex"}}>
                            <CheckBox disabled={props.disabled} label={s.name} onChange={e => handleCheckboxChange(e, s.storeID)} value={props.value.filter(ts => s.storeID === ts.storeID && ts.active).length === 1} />
                        </Grid>
                        <ValueField 
                            store={s} 
                            field="revenue" 
                            onChange={handleRevenueCommissionChange}
                            disabled={props.disabled}
                            value={props.value}
                            visible={showRevenue}
                            width={width}
                        />
                        <ValueField 
                            store={s} 
                            field="commission" 
                            onChange={handleRevenueCommissionChange}
                            disabled={props.disabled}
                            value={props.value}
                            visible={showCommission}
                            width={width}
                        />
                    </Grid>
                )
            })}
        </div>

    )
}