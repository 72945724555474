import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dateFormat from 'dateformat'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//components
import ValidationSummary from '../../shared/form/validationSummary'
//import StoreEntityForm from '../stores/storeEntityForm'
//import SKUHistoryCell from './skuHistoryCell'

//lib
import authFetch from '../../../lib/authFetch';
import Alert from '@material-ui/lab/Alert';
import AutoCompleteAsync from '../../shared/autoCompleteAsync';

const blankFormVals = {
    saleID: "",
    notes: "",
    rateCardID: 0
}

const blankValidationResult = {
    fail: false,
    failMessages: {}
}

export default function ChangeRateCardModal(props) {

    const [validationResult, setValidationResult] = useState(blankValidationResult)
    const [formVals, setFormVals] = useState(blankFormVals)
    const [validating, setValidating] = useState(false)
    const [committing, setCommitting] = useState(false)

    //console.log("Sales ids: " + JSON.stringify(props.salesIDs))

    function handleEnter() {
        setValidationResult(blankValidationResult)
        setFormVals({
            ...blankFormVals,
            saleID: props.salesIDs.join(","),
        })
    }

    function handleChangeNotes(e) {
        setFormVals({
            ...formVals,
            notes: e.target.value
        })
    }

    function handleChangeDDL(e) {
        var newVals = {
            ...formVals,
            rateCardID: e.target.value
        }
        setFormVals(newVals)
        //get validation messages for the new value
        setValidating(true)
        authFetch("/sales/changeRateCardValidate", {method: "PUT", body: JSON.stringify(newVals)})
        .then(r => {
            setValidationResult(r.validationResult)
            setValidating(false)
        })
        .catch(err => setValidating(false))
    }

    function handleCommit() {
        //make the api call, then close the modal
        setCommitting(true)
        authFetch("/sales/changeRateCard", {method: "PUT", body: JSON.stringify(formVals)})
        .then(r => {
            setCommitting(false)
            setValidationResult(r.validationResult)
            if(!r.validationResult.fail){
                //it's done
                props.onChange()
                props.onClose()
            }
        })
        .catch(err => setCommitting(false))
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="lg" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                Change rate card on {props.salesIDs.length} sales
            </DialogTitle>
            <DialogContent style={{minHeight: "250px"}}>
                <p>You are about to change the rate card on sales {props.salesIDs.join(", ")}</p>
                <TextField
                    margin="dense"
                    id="notes"
                    label="Reason for change (required)"
                    type="text"
                    value={formVals.notes}
                    onChange={handleChangeNotes}
                    error={validationResult.failMessages.hasOwnProperty("notes")}
                    helperText={validationResult.failMessages["notes"]}
                    fullWidth
                />
                <AutoCompleteAsync 
                    multi={false}
                    id="rateCardID"
                    label="New rate card"
                    onChange={handleChangeDDL}
                    placeholder="Choose new rate card..."
                    value={formVals.rateCardID}
                    getURL={input => "/rateCards?pageLength=50&page=0&sort=fromDate&sortDir=desc" + ((input === "" || input === undefined) ? "" : "&quickFilter=" + encodeURIComponent(input))}
                    valueField="rateCardID"
                    labelFn={v => dateFormat(v.fromDate, "dd/mm/yyyy")}
                    getURLValues={"/rateCards?pageLength=1&page=0&rateCardID=" + formVals.rateCardID}
                    labelValues={v => dateFormat(v.fromDate, "dd/mm/yyyy")}
                    idField="rateCardID"
                    error={validationResult.failMessages.hasOwnProperty("rateCardID")}
                    helperText={validationResult.failMessages["rateCardID"]}
                />
                {
                    formVals.rateCardID === 0 ? null :
                    //depending on if there are validation errors or not
                    validationResult.fail ?
                        validationResult.failMessages.translation === undefined ? null :
                            //the errors come through like Sale 1 erorr|Sale 2 error|sale 3 error
                            //make an alert for each one
                            (validationResult.failMessages.translation + "").split("|").map(t => {
                                return (
                                    <Alert severity="error" key={t}>
                                        {t}
                                    </Alert>
                                )
                            })
                        
                    :
                        //no validation errors, they are ok to proceed, but warn them of the gravity of their actions!
                        <Alert severity="warning" style={{margin:"5px"}}>
                            This action cannot be undone!  Please double check before taking this action!
                        </Alert>
                }
                <ValidationSummary 
                    validationResult={{
                        ...validationResult,
                        failMessages: {
                            ...validationResult.failMessages,
                            translation: undefined
                        }
                    }} 
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button 
                    color="primary" 
                    variant="contained" 
                    onClick={handleCommit} 
                    disabled={formVals.rateCardID === 0 || validating || committing}
                >
                    {
                        validating ?
                            "Validating..."
                        :
                            committing ?
                                "Processing..."
                            :
                                "Change " + props.salesIDs.length + " Rate Cards"
                    }
                </Button>
            </DialogActions>
      </Dialog>
    )
}