import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import SKUTypesList from './skuTypesList'

export default function SKUTypes(props) {
    return(
        <div>
            <Route path="/data/skuTypes" component={SKUTypesList} />
        </div>
    )
}