import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import Licences from './licences'
import Support from './support'

export default function Sales(props) {
    return(
        <Switch>
            <Route path="/about/licences" render={renderProps => <Licences {...renderProps} me={props.me} />} />
            <Route path="/about/support" render={renderProps => <Support {...renderProps} me={props.me} />} />
        </Switch>
    )
}