import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'

import AccessProfileStoreSelector from './accessProfileStoreSelector'
import authFetch from '../../../../lib/authFetch'
import SystemAreaSelector from './systemAreaSelector'
import CheckBox from '../../../shared/checkBox'

export default {
    displayName: "Access Profile",
    idField: "accessProfileID",

    getURL: id => "/accessProfiles?accessProfileID=" + id + "&fkJoins=accessProfileStores",
    putURL: id => "/accessProfile/" + id,
    deleteURL: id => "/accessProfile/" + id,
    postURL: "/accessProfile",
    getListURL: "/accessProfiles",
    maxWidth: "md",
    recordName: formVals => formVals.name,

    blankForm: {
        accessProfileID: 0,
        name: "",
        systemAreas: "",
        accessProfileStores: [],
        showRevenue: true,
        showCommission: false,
    },
    form : function (props) {
        //whenever the accessProfileID changes we should reload the list items from the db
        // useEffect(() => {
        //     if(props.formVals.accessProfileID === 0) {
        //         //it's a new one so blank array
        //         props.onChange({...props.formVals, accessProfileStores: []})
        //     } else {
        //         var newVals = {...props.formVals}
        //         //whenever the accessProfileID changes we should reload the list items from the db
        //         authFetch("/accessProfileStores?accessProfileID=" + props.formVals.accessProfileID)
        //         .then(r => {
        //             newVals.accessProfileStores = r.listData
        //             //manifest the new formVals
        //             props.onChange(newVals)
        //         })
        //     }
        // }, [props.formVals.accessProfileID])
        

        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }
    
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <h4>Access profile</h4>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            value={props.formVals.name}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("name")}
                            helperText={props.validationResult.failMessages["name"]}
                            fullWidth
                            disabled={props.disabled}
                        />
                        <h4>System areas</h4>
                        <SystemAreaSelector 
                            id="systemAreas"
                            value={props.formVals.systemAreas}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("systemAreas")}
                            helperText={props.validationResult.failMessages["systemAreas"]}
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <h4>
                            Stores
                        </h4>
                        <AccessProfileStoreSelector
                            id="accessProfileStores"
                            value={props.formVals.accessProfileStores || []}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("accessProfileStores")}
                            helperText={props.validationResult.failMessages["accessProfileStores"]}
                            disabled={props.disabled}
                            accessProfileID={props.formVals.accessProfileID}
                            me={props.me}
                        />
                        <h4>
                            Display figures
                        </h4>
                        <CheckBox 
                            id="showRevenue"
                            label="Show revenue"
                            onChange={handleChange}
                            value={props.formVals.showRevenue}
                            error={props.validationResult.failMessages.hasOwnProperty("showRevenue")}
                            helperText={props.validationResult.failMessages["showRevenue"]}
                            disabled={props.disabled}
                        />
                        <CheckBox 
                            id="showCommission"
                            label="Show commission"
                            onChange={handleChange}
                            value={props.formVals.showCommission}
                            error={props.validationResult.failMessages.hasOwnProperty("showCommission")}
                            helperText={props.validationResult.failMessages["showCommission"]}
                            disabled={props.disabled}
                        />
                    </Grid>
                </Grid>
                {
                    props.disabled ? null :
                    <Alert severity="info" style={{margin: "10px"}}>
                        Changes to affected users may not take effect until the user logs in again
                    </Alert>
                }
            </div>
    
        )
    },
}

