import React, {useEffect, useState} from 'react'
import dateFormat from 'dateformat'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'

//import CheckBox from '../../../shared/checkBox'
//import AutoComplete from '../../../shared/autoComplete'
import DatePicker from '../../shared/form/datePicker'
//import authFetch from '../../../../lib/authFetch'
import OrderRefField from '../sales/entityForm/orderRefField'
import {getToken} from '../../../lib/auth'
import ReconciledAlert from './reconciledAlert'

export default {
    displayName: "Revenue Rec Line",
    idField: "recSheetLineID",

    getURL: id => "/recSheetLines?fkJoins=" + encodeURIComponent("file, sale") + "&recSheetLineID=" + id,
    putURL: id => "/recSheetLine/" + id,
    deleteURL: id => "/recSheetLine/" + id,
    postURL: "/recSheetLine",
    getListURL: "/recSheetLines",
    maxWidth: "lg",
    recordName: formVals => formVals.recSheetLineID,
    overrideOnCopy: {
        fileID:0
    },

    blankForm: {
        recSheetLineID: 0,
        sku: "", 
        description: "", 
        revenue: 0,
        orderRef: "",
        worksheet: "",
        adjustment: 0,
        adjustmentReason: "",
        saleDate: dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-mm-dd") + "T00:00:00.000Z",
        storeName: "",
        fileID: 0,
        file: {
            originalName: "",
            fileID: 0
        },
        recSheetID: 0,
        saleID: 0
    },
    form : function (props) {
        const blankFile = {
            fileID: 0,
            originalName: ""
        }

        function handleChange(event) {
            //check if we are allowing the change
            //only allow prices that are decimal numbers
            var decimal = new RegExp("^[0-9]*([.][0-9]{0,2}){0,1}$")
            if(['revenue'].includes(event.target.id) && !decimal.test(event.target.value)) {
                //invalid.  revert the value
                event.target.value = props.formVals[event.target.id]
            }
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <h4>Revenue Details</h4>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <DatePicker 
                                    id="saleDate"
                                    label="Sale date"
                                    onChange={handleChange}
                                    value={props.formVals.saleDate}
                                    error={props.validationResult.failMessages.hasOwnProperty("saleDate")}
                                    helperText={props.validationResult.failMessages["saleDate"]}
                                    disabled={props.disabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    margin="dense"
                                    id="revenue"
                                    label="Revenue receiveable"
                                    type="text"
                                    value={props.formVals.revenue}
                                    onChange={handleChange}
                                    error={props.validationResult.failMessages.hasOwnProperty("revenue")}
                                    helperText={props.validationResult.failMessages["revenue"]}
                                    fullWidth
                                    disabled={props.disabled}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    margin="dense"
                                    id="sku"
                                    label="SKU"
                                    type="text"
                                    value={props.formVals.sku}
                                    onChange={handleChange}
                                    error={props.validationResult.failMessages.hasOwnProperty("sku")}
                                    helperText={props.validationResult.failMessages["sku"]}
                                    fullWidth
                                    disabled={props.disabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    margin="dense"
                                    id="storeName"
                                    label="Store"
                                    type="text"
                                    value={props.formVals.storeName}
                                    onChange={handleChange}
                                    error={props.validationResult.failMessages.hasOwnProperty("storeName")}
                                    helperText={props.validationResult.failMessages["storeName"]}
                                    fullWidth
                                    disabled={props.disabled}
                                />
                            </Grid>
                        </Grid>
                        
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            value={props.formVals.description}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("description")}
                            helperText={props.validationResult.failMessages["description"]}
                            fullWidth
                            disabled={props.disabled}
                        />
                        <OrderRefField
                            id="orderRef"
                            label="Order reference (20 digits)"
                            value={props.formVals.orderRef}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("orderRef")}
                            helperText={props.validationResult.failMessages["orderRef"]}
                            disabled={props.disabled}
                        />
                        
                    </Grid>
                    <Grid item xs={12} md={6}>
                    {
                        props.formVals.fileID === 0 ? 
                        <div>
                            <h4>
                                Manual record
                            </h4>
                            <p>
                                This revenue reconciliation sheet line was created manually and not uploaded from a spreadsheet.
                            </p>
                        </div>
                        :
                        <div>
                            {
                                props.me.accessProfile.systemAreas.includes("SystemSetup") ?
                                    <>
                                        <h4>
                                            Upload file
                                        </h4>
                                        <p>This record was uploaded from worksheet <strong>{props.formVals.worksheet}</strong> of this file:</p>
                                        <p><a href={(process.env.REACT_APP_API_URL === undefined ? ((window.location.href + "salt").replace(window.location.pathname + "salt", "/api")) : process.env.REACT_APP_API_URL) + "/file/" + props.formVals.fileID + "/download?token=" + getToken()}>{props.formVals.file.originalName}</a></p>
                                    </>
                                : null
                            }
                        </div>
                    }
                    <h4>
                        Reconciliation
                    </h4>
                    <ReconciledAlert
                        formVals={props.formVals}
                        me={props.me}
                        onDataChanged={props.onDataChanged}
                    />
                    </Grid>
                </Grid>
    
            </div>
    
        )
    },
}

