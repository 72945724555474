import React, {useState, useEffect} from 'react'
import { CircularProgress } from '@material-ui/core'
import dateFormat from 'dateformat'

import authFetch from '../../../lib/authFetch'
import recSheetEntityForm from './recSheetEntityForm'
import SalesTable from '../sales/salesTable'
import RevenueCommissionSwitchTitle from '../../shared/title/revenueCommissionSwitchTitle'

export default function SaleAdjustments(props) {
    const [recSheet, setRecSheet] = useState(recSheetEntityForm.blankForm)
    const [loading, setLoading] = useState(false)

    //get the date card for use in the header etc
    useEffect(() => {
        setLoading(true)
        authFetch("/recSheets?recSheetID=" + props.match.params.recSheetID)
        .then(r => {
            setLoading(false)
            setRecSheet(r.listData[0])
        })
    }, [props.match.params.recSheetID])

    const filters = {
        saleDateGEQ: dateFormat(recSheet.recDate, 'yyyy-mm-01'),
        saleDateLEQ: dateFormat(new Date(new Date(recSheet.recDate).getFullYear(), new Date(recSheet.recDate).getMonth() + 1, 0), 'yyyy-mm-dd'),
        currentHistoryStep: "recorded,modified",
        unreconciledRevenueNot: 0
    }

    return (
        <div>
            {
                loading ?
                    <CircularProgress size={150} />
                :
                    <>
                        <RevenueCommissionSwitchTitle
                            me={props.me}
                            title={"Unreconciled Sales"}
                        />
                        <p>
                            Here are sales made during this reconciliation sheet period which are not yet reconciled.
                        </p>
                        <SalesTable
                            columnSet={(props.me === undefined || props.me.revenueCommission === undefined ? ["revenue"] : props.me.revenueCommission)}
                            filters={filters} //these are the default filters, taken from the URL 
                            title="Transactions"
                            enableCrud={true} //enabling editing of sales, if the accessProfile permits it
                            sort="adjustedRevenue"
                            sortDir="desc"
                            showTopButtons={true}
                            me={props.me}
                        />
                    </>
            }
        </div>
    )
}