import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'


export default function CreditFailModal(props) {
    function handleCreditFailSale() {
        props.onCreditFailSale()
        props.onClose()
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Credit Check Failed?
            </DialogTitle>
            <DialogContent>
                <strong>The customer has failed credit check?</strong>
                <p>Clicking 'Credit Check Failed' will allow you to record the sale as cancelled due to credit check fail.  It will not count towards targets, revenue or commission.</p>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
                <Button color="secondary" onClick={handleCreditFailSale} variant="contained">
                    Credit Check Failed
                </Button>
            </DialogActions>
      </Dialog>
    )
}