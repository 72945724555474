import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import StoreTypesList from './storeTypesList'

export default function StoreTypes(props) {
    return(
        <div>
            <Route path="/data/storeTypes" component={StoreTypesList} />
        </div>
    )
}