import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'

export default function TargetsToolbarViews(props) {
    //calculate the tab value
    let tabValue = false
    //console.log("PLP is " + props.location.pathname + " and match is ")
    if(props.match.params.view === "set") {tabValue = 0}
    if(props.match.params.view === "progress") {tabValue = 1}
    if(props.match.params.view === "variance") {tabValue = 2}

    return (
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
            <Tab label="Targets" component={Link} to={"/targets/set/" + props.match.params.targetDate + "/" + props.match.params.storeID} />
            <Tab label="Progress" component={Link} to={"/targets/progress/" + props.match.params.targetDate + "/" + props.match.params.storeID} />
            <Tab label="Difference" component={Link} to={"/targets/variance/" + props.match.params.targetDate + "/" + props.match.params.storeID} />
        </Tabs>
    );
}
