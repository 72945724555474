import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import AutoCompleteNative from '../../../shared/autoCompleteNative'
import {ensureArray} from "../../../../lib/general"
import DiscountStoreSelector from "./discountStoreSelector"

export default {
    displayName: "Discount",
    idField: "discountID",

    getURL: id => "/discounts?discountID=" + id + "&fkJoins=discountStores",
    putURL: id => "/discount/" + id,
    deleteURL: id => "/discount/" + id,
    postURL: "/discount",
    getListURL: "/discounts",
    maxWidth: "xl",
    recordName: formVals => formVals.name,

    blankForm: {
        discountID: 0,
        name: "", 
        saleTypeID: 0,
        discountStores: [],
    },
    form : function (props) {
        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }
    
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            value={props.formVals.name}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("name")}
                            helperText={props.validationResult.failMessages["name"]}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AutoCompleteNative
                            id="saleTypeID"
                            value={props.formVals.saleTypeID}
                            label="Sale type"
                            onChange={handleChange}
                            optionLabel={o => o.name}
                            optionValue={o => o.saleTypeID}
                            clearValue={0}
                            options={ensureArray(props.me.saleTypes)}
                            error={props.validationResult.failMessages.hasOwnProperty("saleTypeID")}
                            helperText={props.validationResult.failMessages["saleTypeID"]}
                            placeholder={"Choose sale type..."}
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DiscountStoreSelector
                            id="discountStores"
                            value={props.formVals.discountStores || []}
                            name={props.formVals.name}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("discountStores")}
                            helperText={props.validationResult.failMessages["discountStores"]}
                            disabled={props.disabled}
                            discountID={props.formVals.discountID}
                            me={props.me}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    },
}

