import React, {useState} from 'react'
import Chip from '@material-ui/core/Chip'

//components
import CrudModal from '../crudModal'

export default function LookupCell(props) {
    const [modalOpen, setModalOpen] = useState(false)

    function handleCrudClose() {
        setModalOpen(false)
    }

    function handleClick() {
        setModalOpen(true)
    }

    return (
        <div>
            {
                //to do: make a enableCrud=true mode that renders a dropdown?  Or at least a way to change the value
            }
            {
                props.id === 0 ? undefined :
                <Chip variant="outlined" size="small" clickable label={props.label} onClick={() => handleClick()}>
                </Chip>
            }
            {
                modalOpen ? 
                    <CrudModal 
                        action="read"
                        open={modalOpen}
                        id={props.id}
                        entityForm={props.entityForm}
                        onClose={handleCrudClose}
                        me={props.me}
                        onDataChanged={props.onDataChanged}
                    />
                : undefined
            }
        </div> 
        )
}