import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function RevenueCommissionErrorModal(props) {
    return(
        <Dialog open={props.open} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Calculation Error
            </DialogTitle>
            <DialogContent>
                <p>Revenue and commission could not be calculated.  Check the form.  If this persists please contact support.</p>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}