import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { formatMoney } from '../../../../lib/general'
import KPICard from './kpiCard'
import MetricCalcModal from './metricCalcModal'
import { ensureArray } from '../../../../lib/general'

function getValue(fromValue, timePeriod, property) {
    //guarding against dodgy data from the API
    if(!Array.isArray(fromValue) || fromValue.length === 0 || fromValue[0][timePeriod] === undefined) {
        return (property === "valueString" ? "-" : 0)
    }
    return fromValue[0][timePeriod][property]
}

export default function MetricCard(props) {
    const [metricCalcOpen, setMetricCalcOpen] = useState(false)

    //text that is going to be displayed
    const currentValueString = getValue(props.value, "current", "valueString")
    const priorValueString = getValue(props.value, "prior", "valueString")
    //value compared with successValue to measure colour
    const currentValue = getValue(props.value, "current", "value")

    //working out the colour
    const successValue = Array.isArray(props.value) && props.value.length > 0 ? props.value[0].metricSuccessValue : 0
    const successIsLess = Array.isArray(props.value) && props.value.length > 0 ? props.value[0].metricSuccessIsLess : 0
    var colour //leave undefined if no colour to be had
    if(successValue + "" !== "0") {
        //we are measuring success
        var multiplier = (successIsLess * -2) + 1 //reversing the outcome of the comparison if success is less rather than more
        if((currentValue * multiplier) >= (successValue * multiplier)) {
            //yay.  good colour
            colour = "#4caf50"
        } else {
            //nope.  bad colour
            colour = "#f44336"
        }
    }

    return (
        <KPICard
            minHeight={props.minHeight}
            action={                
                <Button
                    onClick={() => setMetricCalcOpen(true)}
                >
                    Details
                </Button>
            }
        >
            <Typography color="textSecondary" gutterBottom>
                {props.title}
            </Typography>
            <Typography variant="h5" component="h2">
                <span style={{color: colour}}>
                    {currentValueString}
                </span>
            </Typography>
            <Typography color="textSecondary">
                {priorValueString} last month
            </Typography>
            <Typography variant="body2" component="p">
            </Typography>
            <MetricCalcModal
                open={metricCalcOpen}
                value={props.value}
                onClose={() => setMetricCalcOpen(false)}
                title={props.title + " calculation"}
                me={props.me}
                currentPercentString={currentValueString}
            />
        </KPICard>
    )
}