import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'

export default function LandingTabs(props) {

    function handleTabChange(e, newValue) {
        //redirect to the new URL
        props.history.push("/landing/" + newValue + "/" + props.match.params.date + "/" + props.match.params.userID)
    }

    return (
        <>
            <Paper>
                <Tabs
                    variant="scrollable" 
                    scrollButtons="auto"
                    value={parseInt(props.match.params.tab)}
                    onChange={handleTabChange}
                >
                    <Tab 
                        label="Cards" 
                        //style={{minWidth: (wideTabs ? "160px" : "100px")}} 
                    />
                    <Tab 
                        label="League Table" //static, always appears
                        //style={{minWidth: (wideTabs ? "160px" : "100px")}} 
                    />
                    {
                        //adding all and all stores?  Only if more than one store available tothe user
                        props.stores.length <= 1 ? null :
                            <Tab 
                                label="All (users)" 
                            />
                    }
                    {
                        //adding all and all stores?  Only if more than one store available tothe user
                        props.stores.length <= 1 ? null :
                            <Tab 
                                label="All (stores)" 
                            />
                    }
                    {
                        //adding all and all stores?  Only if more than one store available tothe user
                        props.stores.length <= 1 ? null :
                            <Tab 
                                label="All (types)" 
                            />
                    }
                    {
                        props.stores.map((t, i) => 
                            <Tab 
                                key={i}
                                label={t.name} 
                            />
                        )
                    }
                </Tabs>
            </Paper>  
        </>          
    )
}