import React, {useState, useRef} from 'react'
import TableCell from '@material-ui/core/TableCell';
import Popover from '@material-ui/core/Popover'

import TargetGridCell from './targetGridCell'
import IsUserTargetCell from './isUserTargetCell';
import {targetsAreEqual} from './targetGrid' 
import TotalCell from './totalCell';
import {formatMoney} from '../../../lib/general'
import HoursTotalCell from './hoursTotalCell'

export default function ColsAnalysisFields(props) {
    const anchorEl = useRef(null);
    const [closed, setClosed] = useState(false)

    //filter out any analysisField items which are not targetable
    const analysisListItems = props.analysisField.analysisListItems.filter(a => a.enableTargets)

    if(props.rowType === "header1") {
        return (
            <TableCell colSpan={analysisListItems.length + 1} align="center" style={{backgroundColor: props.backgroundColor, padding: "4px"}}>
                <strong>{props.analysisField.label + " (" + props.targetType.substring(0, 1).toUpperCase() + props.targetType.substring(1) + ")"}</strong>
            </TableCell>
        )
    }

    if(props.rowType === "header2") {
        return (
            <React.Fragment>
                <TableCell style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="center"></TableCell>
                {
                    analysisListItems.map(ali => {
                        return (
                            <TableCell key={ali.analysisListItemID} style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="center">
                                <strong>{ali.code}</strong>
                            </TableCell>
                        )
                    })
                }
            </React.Fragment>
        )
    }

    function isTargetForUser() {
        //is there a relevent target?  There could be more then 1; one for each analysisListItem
        //they should all have the same isTarget value though!
        if(props.targets.length > 0) {
            //return the isTarget value of the first record
            return props.targets[0].isTarget || false
        } 
        //no targets so not a target
        return false
    }

    function handleIsUserTargetChange(e) {
        var newReleventTargets = []
        //for each analysisListItem, check if a target exists.  If it does, update, ifnot create
        analysisListItems.forEach(ali => {
            var releventToThisAli = props.targets.filter(t => t.analysisListItemID === ali.analysisListItemID)
            if(releventToThisAli.length === 1) {
                //update
                newReleventTargets.push({
                    ...releventToThisAli[0], 
                    isTarget: e.target.checked
                })
            } else {
                //create
                newReleventTargets.push({
                    ...props.blankTarget,
                    isTarget: true,
                    analysisListItemID: ali.analysisListItemID
                })
            }
        })
        //now merge this with allTargets
        props.allTargets.forEach(t => {
            if(newReleventTargets.filter(tt => targetsAreEqual(t, tt)).length === 0) {
                //it's not already in the list, add it
                newReleventTargets.push(t)
            }
        })
        props.onChange(newReleventTargets)
    }

    if(props.rowType === "row") {
        return (
            <React.Fragment>
                {
                    props.hoursInput ? null :
                    <TableCell style={{backgroundColor: props.backgroundColor, padding: "0px"}} align="center">
                        <IsUserTargetCell
                            user={props.user}
                            disabled={props.view !== "set" || !props.me.accessProfile.systemAreas.includes("RecordTargets")}
                            value={isTargetForUser()}
                            onChange={e => handleIsUserTargetChange(e)}
                        />
                    </TableCell>
                }
                {
                    analysisListItems.map((ali, i) => {
                        return (
                            <TableCell key={ali.analysisListItemID} style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="right" colSpan={(i === 0 && props.hoursInput) ? 2 : 1}>
                                <TargetGridCell 
                                    view={props.view}
                                    targets={(props.targets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)}
                                    disabled={props.view !== "set" || !props.me.accessProfile.systemAreas.includes("RecordTargets") || props.hoursInput}
                                    targetType={props.targetType}
                                    onChange={props.onChange}
                                    allTargets={props.allTargets}
                                    blankTarget={{
                                        ...props.blankTarget,
                                        analysisListItemID: ali.analysisListItemID
                                    }}
                                    highlightTargetID={props.highlightTargetID}
                                    tooltip={ali.code}
                                />
                            </TableCell>
                        )
                    })
                }
            </React.Fragment>
        )
    }

    if(props.rowType === "total") {
        return (
            <React.Fragment>
                { //dont need the filler cell if using hours input - just use the whole cell like the rows above
                    props.hoursInput ? null :
                    <TableCell style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="center">
                        
                    </TableCell>
                }
                {
                    analysisListItems.map((ali, i) => {
                        return (
                            props.view === "set" ?
                                props.hoursInput ?
                                    <TableCell style={{backgroundColor: props.backgroundColor, padding: "4px"}} colSpan={(props.hoursInput && i === 0) ? 2 : 1}>
                                        <HoursTotalCell
                                            totalTargets={(props.totalTargets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)}
                                            onChange={props.onChange}
                                            tooltip={"Total " + ali.code}
                                            allTargets={props.allTargets}
                                        />
                                    </TableCell>
                                :
                                <TotalCell
                                    targets={(props.targets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)}
                                    totalTargets={(props.totalTargets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)}
                                    backgroundColor={props.backgroundColor}
                                    key={ali.analysisListItemID}
                                    bold
                                    colSpan={(props.hoursInput && i === 0) ? 2 : 1}
                                />
                            :
                                <TableCell key={ali.analysisListItemID} style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="center" colSpan={(props.hoursInput && i === 0) ? 2 : 1}>
                        
                                </TableCell>
                        )
                    })
                }
            </React.Fragment>
        )
    }

    if(props.rowType === "totalTarget") {
        return (
            <React.Fragment>
                <TableCell style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="center">
                    <IsUserTargetCell
                        user={props.user}
                        disabled={props.view !== "set" || !props.me.accessProfile.systemAreas.includes("RecordTargets")}
                        value={isTargetForUser()}
                        onChange={e => handleIsUserTargetChange(e)}
                    />
                </TableCell>
                {
                    analysisListItems.map(ali => {
                        return (
                            props.view === "set" ?
                                <TotalCell
                                    targets={(props.targets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)}
                                    totalTargets={(props.totalTargets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)}
                                    backgroundColor={props.backgroundColor}
                                    key={ali.analysisListItemID}
                                    highlightTargetID={props.highlightTargetID}
                                />
                            :
                                (props.targets || []).filter(t => t.analysisListItemID === ali.analysisListItemID).length === 1 && (props.targets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)[0].isTarget ?
                                    <TableCell ref={anchorEl} key={ali.analysisListItemID} style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="right">
                                        {
                                            formatMoney(props.view === "progress" ? (props.targets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)[0].actual : (props.targets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)[0].actual - (props.targets || []).filter(t => t.analysisListItemID === ali.analysisListItemID)[0].target, 0)
                                        }
                                        {
                                            anchorEl.current === null ? null :
                                                <Popover
                                                    open={!closed && props.highlightTargetID !== undefined && props.highlightTargetID !== 0 && (props.targets || []).map(t => t.targetID + "").includes(props.highlightTargetID + "")}
                                                    anchorEl={anchorEl.current}
                                                    onClose={() => setClosed(true)}
                                                    onClick={() => setClosed(true)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <div style={{padding: "18px"}}>
                                                        The target you clicked
                                                    </div>
                                                </Popover>
                                        }
                                    </TableCell>
                                :
                                    <TableCell key={ali.analysisListItemID} style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="right">
                                        n/a
                                    </TableCell>
                                
        
                                
                        )
                    })
                }
            </React.Fragment>
        )
    }
    //should never get here
    return null
}