import React, {useEffect, useState} from 'react'
import dateFormat from 'dateformat'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import RotaDateSelectorModal from './rotaDateSelectorModal'

export default function RotaDateSelector(props) {
    const [changeOpen, setChangeOpen] = useState(false)

    const today = new Date()
    //week
    const firstDayThisWeek = new Date(new Date().setDate(today.getDate() - today.getDay()))
    const lastDayThisWeek = new Date(new Date().setDate(firstDayThisWeek.getDate() + 6))
    const firstDayNextWeek = new Date(new Date().setDate(firstDayThisWeek.getDate() + 7))
    const lastDayNextWeek = new Date(new Date().setDate(firstDayThisWeek.getDate() + 13))
    const firstDayWeekAfter = new Date(new Date().setDate(firstDayThisWeek.getDate() + 14))
    const lastDayWeekAfter = new Date(new Date().setDate(firstDayThisWeek.getDate() + 20))
    //month
    const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const lastDayThisMonth = new Date(today.getFullYear(), today.getMonth()+1, 0)
    const firstDayNextMonth = new Date(today.getFullYear(), today.getMonth()+1, 1)
    const lastDayNextMonth = new Date(today.getFullYear(), today.getMonth()+2, 0)
    const rotaDateParamSets = {
        thisWeek: {
            name: "This week",
            params: {
                rotaDateGEQ: firstDayThisWeek,
                rotaDateLEQ: lastDayThisWeek,
            },
        },
        nextWeek: {
            name: "Next week",
            params: {
                rotaDateGEQ: firstDayNextWeek,
                rotaDateLEQ: lastDayNextWeek,
            },
        },
        weekAfter: {
            name: "Week after",
            params: {
                rotaDateGEQ: firstDayWeekAfter,
                rotaDateLEQ: lastDayWeekAfter,
            },
        },
        thisMonth: {
            name: firstDayThisMonth.toLocaleString('default', { month: 'long' }),
            params: {
                rotaDateGEQ: firstDayThisMonth,
                rotaDateLEQ: lastDayThisMonth,
            },
        },
        nextMonth: {
            name: firstDayNextMonth.toLocaleString('default', { month: 'long' }),
            params: {
                rotaDateGEQ: firstDayNextMonth,
                rotaDateLEQ: lastDayNextMonth,
            },
        },
    }

    useEffect(() => {
        //on first load set the default dates
        props.onDatesChange(
            rotaDateParamSets.thisWeek.params.rotaDateGEQ,
            rotaDateParamSets.thisWeek.params.rotaDateLEQ,
        )
    }, [])

    return (
        <>
            <Button
                onClick={() => setChangeOpen(true)}
                endIcon={<EditIcon />}
            >
                {dateFormat(props.rotaDateGEQ, "ddd d/m")} - {dateFormat(props.rotaDateLEQ, "ddd d/m")}
            </Button>
            <RotaDateSelectorModal
                rotaDateParamSets={rotaDateParamSets}
                open={changeOpen}
                onClose={() => setChangeOpen(false)}
                onDatesChange={props.onDatesChange}
                rotaDateGEQ={props.rotaDateGEQ}
                rotaDateLEQ={props.rotaDateLEQ}
            />
        </>
    )
}