import React, {useState} from 'react'
import Alert from '@material-ui/lab/Alert'

export default function Step2(props) {
    const [showDupeSKU, setShowDupeSKU] = useState(true)

    function sortRows(a, b){
        if(a.row < b.row) { return -1; }
        if(a.row > b.row) { return 1; }
        return 0;
    }

    return(
        <div>
            <h4>
                Validation results
            </h4>
            <p>Here are the results of the validation performed on the Excel file.  Any rows with errors will not be created.  You can check the errors and re-upload the file.  The <strong>same sku</strong> will not be created twice.</p>
            {
                props.formVals.validationReport.length === 0 ?
                    <Alert severity="success">
                        There are no validation errors
                    </Alert>
                :
                    (props.formVals.validationReport || []).sort((a,b) => sortRows(a,b)).map(e => {
                        return (
                        <Alert key={e.row + ":" + e.column + ":" + e.error} severity="error" style={{marginTop: "4px"}}>
                            Row {e.row} col {e.column} value "{e.value}" error: "{e.error}"
                        </Alert>
                        )
                    })
            }
        </div>
    )
}