import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'
import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined'
import ThumbDownIcon from '@material-ui/icons/ThumbDownOutlined'
import dateFormat from 'dateformat'

import { formatMoney, getDaysInMonth } from '../../../../lib/general'
import KPICard from './kpiCard'
import SalesLink from '../../../shared/salesLink'


export default withRouter(function TargetKPICard(props) {

    const multi = Array.isArray(props.value)
    const referenceTarget = multi ? props.value[0] : props.value
    const actual = multi ? props.value.reduce((acc, cur) => acc + cur.actual, 0) : props.value.actual
    const target = multi ? props.value.reduce((acc, cur) => acc + cur.target, 0) : props.value.target
    const isRevenue = referenceTarget.targetType === "revenue"


    const targetDate = new Date(referenceTarget.targetDate)   
    //how these are set depends on whether we're looking at current month (today) or not (end of month)
    var today
    //if this is not the current month then its using the end of the month
    if(targetDate.getFullYear() !== new Date().getFullYear() || targetDate.getMonth() !== new Date().getMonth()) {
        //target date is not current month - overriding with end of month
        today = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0)
    } else {
        //it's current month
        today = new Date()
    }
    const daysInMonth = getDaysInMonth(today.getMonth() + 1, today.getFullYear())
    const dayOfMonth = today.getDate()

    //if the user likes to MTD the target on actual, then adjust the target
    var mtdTarget = target
    if(props.me.proRateTargetsOnActual && daysInMonth !== 0) {
        mtdTarget = (target / daysInMonth) * dayOfMonth
    }

    const forecast = actual / (dayOfMonth / daysInMonth)

    return (
        <KPICard
            minHeight={props.minHeight}
        >
            <Typography color="textSecondary" gutterBottom>
                {
                    referenceTarget.userID === 0 ? "Total " : "Your "
                }
                 {
                    referenceTarget.analysisFieldID !== 0 ?
                        referenceTarget.analysisListItemCode + " "
                    :
                        ""
                }
                {referenceTarget.targetType}
                {
                    " at " + referenceTarget.storeName
                }
            </Typography>
            <Typography variant="h5" component="h2">
                {
                    actual >= mtdTarget ?
                        <ThumbUpIcon style={{color:"#4caf50"}} />
                    :
                        <ThumbDownIcon style={{color:"#f44336"}} />
                } 
                <SalesLink
                    me={props.me}
                    params={{
                        ...referenceTarget.actualSaleParams,
                        //overriding saleDateGeq in case they have it set less than one month
                        saleDateGEQ: dateFormat(new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()), "yyyy-mm-dd")
                    }}
                >
                    {
                        isRevenue ? 
                            <span style={{fontSize: "15px"}}>£</span>
                        : " "
                    }
                    {formatMoney(actual, 0)}
                </SalesLink>
                {" of "}
                <span style={{cursor: "pointer"}} onClick={() => props.history.push("/targets/set/" + dateFormat(referenceTarget.targetDate, "yyyy-mm-dd") + "/" + referenceTarget.storeID + "/" + referenceTarget.targetID)}>
                    {
                        isRevenue ? 
                            <span style={{fontSize: "15px"}}>£</span>
                        : " "
                    }
                    {
                        formatMoney(mtdTarget, 0)
                    }
                </span>
            </Typography>
            <Typography color="textSecondary">
                {
                    actual >= target ?
                        "Target met!"
                    :
                        <>
                            <span style={{cursor: "pointer"}} onClick={() => props.history.push("/targets/variance/" + dateFormat(referenceTarget.targetDate, "yyyy-mm-dd") + "/" + referenceTarget.storeID + "/" + referenceTarget.targetID)}>
                                {
                                    isRevenue ? 
                                        <span style={{fontSize: "11px"}}>£</span>
                                    : " "
                                }
                                {formatMoney((target - actual), 0)}
                            </span> more to meet this target
                        </>
                }
            </Typography>
            <Typography variant="body2" component="p">
                {
                    //only showing forecast if current month
                    today.getFullYear() !== new Date().getFullYear() || today.getMonth() !== new Date().getMonth() ? null :
                    <span style={{color: actual >= target ? undefined : Math.floor(forecast) === target ? "#ff9800" : forecast > target ? "#4caf50" : "#f44336"}}>
                        Will finish the month on 
                        {
                            isRevenue ? 
                                <span style={{fontSize: "11px"}}> £</span>
                            : " "
                        }
                        {formatMoney(forecast, 0)} at current rate
                    </span>
                }
            </Typography>
        </KPICard>
    )
})