import React, {useState} from 'react'
import DateRangeIcon from '@material-ui/icons/DateRange'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {Link} from 'react-router-dom'

import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import AccessProfileEntityForm from './accessProfileEntityForm';
import StoreEntityForm from '../stores/storeEntityForm'
import ChipCell from '../../../shared/dataTable/chipCell';

const filters = {
    fkJoins: "accessProfileStores,accessProfileStores.store"
}

const columnData = [
    { fieldName: 'accessProfileID', default: true, name: 'ID', description: "The unchanging identifier of the access profile"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the access profile"},
    { fieldName: 'systemAreas', default: true, name: 'System Areas', description: "Areas of the system the access profile grants access to"},
    { fieldName: 'accessProfileStores', default: true, name: 'Stores', description: "Stores the access profile grants access to", renderCell: dr => 
    <ChipCell 
        chips={dr.accessProfileStores.map(s => s.store)} 
        //onDataChanged={onDataChanged} 
        enableCrud={false}
        entityForm={StoreEntityForm}
        //parentEntityForm={StoreTypeEntityForm}
        //parentEntityValue={dataRow.storeTypeID}
    />
},

];


export default function AccessProfilesList(props) {
    return(
        <div>
            <h1>
                Access Profiles
            </h1>
            <p>
                Access profiles control the areas of the system and stores that users with the profile have access to.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={AccessProfileEntityForm}
                
                filters={filters} 
                title="Access Profiles"
                enableCrud={true}
                me={props.me}
            />
        </div>
    )
}