import React from 'react'

import DailyStatsTableModal from '../../daily/dailyStats/dailyStatsTableModal'
import SalesTableModal from '../../sales/salesTableModal'

export default function TableDependentModal(props) {
    //depending on the passed table name, it will show the appropriate modal
    if(props.tableName === "dailyStats") {
        return(
            <DailyStatsTableModal
                title={props.title}
                me={props.me}
                params={props.params}
                open={props.open}
                onClose={props.onClose}
            />
        )
    } else {
        //assume sales
        return (
            <SalesTableModal
                title={props.title}
                me={props.me}
                params={props.params}
                open={props.open}
                onClose={props.onClose}
            />
        )
    }
}