import React from 'react'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'

import MetricCard from './metricCard'
import CardSetHeader from './cardSetHeader'

export default function MetricPartCards(props) {
    function ensureArray(fromArray) {
        if(Array.isArray(fromArray)) {
            return fromArray
        }
        return []
    }

    //make a list of metricNames found in the data
    const userMetricParts = ensureArray(ensureArray(props.kpiCard.user).map(x => x.metricParts)).flat()
    const storeMetricParts = ensureArray(ensureArray(props.kpiCard.store).map(x => x.metricParts)).flat()
    const myStoresMetricParts = ensureArray(props.kpiCard.myStores === undefined ? [] : props.kpiCard.myStores.metricParts)
    var allMetricParts = []
    allMetricParts.push(...userMetricParts)
    allMetricParts.push(...storeMetricParts)
    allMetricParts.push(...myStoresMetricParts)
    const foundMetricNames = [
        ...new Set(allMetricParts.filter(mp => mp !== null && mp !== undefined).map(x => x.metricName))
    ]

    return (
        foundMetricNames.sort().map(metricName => {
            //is this metricPar hidden?
            if(props.hidden.split(",").includes("metricName" + metricName.replace(",", ""))) {
                //it's hidden
                return null
            }
            //return the set of cards showing this metric name
            return (
                <React.Fragment key={metricName}>
                    <CardSetHeader
                        title={metricName + " " + dateFormat(new Date(props.date), "UTC:mmm yyyy")}
                        setName={"metricName" + metricName.replace(",", "")}
                        onAddHidden={props.onAddHidden}
                    />
                    <Grid container spacing={2}>
                        {
                            props.kpiCard.user === undefined ? null :
                            props.kpiCard.user.filter(x => x.metricParts.map(s => s.metricName).includes(metricName)).map((user, i) => {
                                //now mapping through users with this metric name
                                var thisUser = props.allUsers.filter(au => au.userID + "" === user.day.current.params.attributableToUserID + "")
                                return (
                                    <MetricCard
                                        key={i}
                                        me={props.me}
                                        minHeight={props.minHeight}
                                        value={ensureArray(user.metricParts).filter(st => st.metricName === metricName)}
                                        title={(thisUser.length === 0 ? "(Username not found)" : thisUser[0].name) + " " + metricName} 
                                    />
                                )
                            })
                        }
                        {
                            props.kpiCard.store === undefined ? null :
                            props.kpiCard.store.filter(x => x.metricParts.map(s => s.metricName).includes(metricName)).map((store, i) => {
                                //now mapping through stores with this metric name
                                var thisStore = props.stores.filter(as => as.storeID + "" === store.day.current.params.storeID + "")
                                return (
                                    <MetricCard
                                        key={i}
                                        me={props.me}
                                        minHeight={props.minHeight}
                                        value={ensureArray(store.metricParts).filter(st => st.metricName === metricName)}
                                        title={metricName + " at " + (thisStore.length === 0 ? "(Store name not found)" : thisStore[0].name)} 
                                    />
                                )
                            })
                        }
                        {
                            props.kpiCard.myStores === undefined ? null :
                            <MetricCard
                                me={props.me}
                                minHeight={props.minHeight}
                                value={ensureArray(props.kpiCard.myStores.metricParts).filter(mp => mp.metricName === metricName)}
                                title={"All stores " + metricName} 
                            />
                        }
                    </Grid>
                </React.Fragment>
            )
        })

    )
}