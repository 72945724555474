import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SalesTable from './salesTable'


export default function SalesTableModal(props) {
    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return (
        <Dialog open={props.open} maxWidth="xl" fullScreen={!noFullscreen} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <SalesTable
                    columnSet={(props.me === undefined || props.me.revenueCommission === undefined ? ["revenue"] : props.me.revenueCommission)}
                    filters={props.params} 
                    title={props.title}
                    enableCrud={false} 
                    sort="saleID"
                    sortDir="desc"
                    showTopButtons={false}
                    me={props.me}
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}