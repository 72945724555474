import React from 'react'
import {Route, Switch} from 'react-router-dom'

import RotaUser from './rotaUser'
import RotaStore from './rotaStore'
import Approval from './approval'

export default function Rota(props) {
    return(
        <Switch>
            <Route path="/rota/view/user/:userID" render={renderProps => <RotaUser {...renderProps} me={props.me} />} />
            <Route path="/rota/view/store/:storeID" render={renderProps => <RotaStore {...renderProps} me={props.me} />} />
            <Route path="/rota/view/approval" render={renderProps => <Approval {...renderProps} me={props.me} />} />
        </Switch>
    )
}