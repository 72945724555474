import React from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {appearsLoggedIn} from './lib/auth'

//project
import leftMenu from './components/shared/menu/leftMenu'
import login from "./components/pages/user/login"
import UrlRedirect from './components/pages/announcements/urlRedirect'

export default function App(props) {
    return (
            <BrowserRouter>
                <Switch>
                    <Route path="/announcement/urlRedirect/:announcementID" render={renderProps => <UrlRedirect {...renderProps} me={props.me} />} />
                    <Route path="/" component={appearsLoggedIn() === 0 ? login : leftMenu} />
                </Switch>
            </BrowserRouter>
        );
}

