import React from 'react'
import { Tooltip } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {getToken} from '../../../../lib/auth';

function checkString(checking, alternative) {
    //return checking or alternative
    return (checking === undefined || checking === "" ? alternative : checking)
}

export default function ClickAction(props) {

    //if no clickType on this announcement then blank
    if(props.announcement.clickType !== "file" && props.announcement.clickType !== "url") {
        return null
    }

    return(
        <>
            {" - "}
            <Tooltip title={props.announcement.clickType === "file" ? "Click to download" : checkString(props.announcement.url, "https://example.com")}>
                <a 
                    href={props.disabled ? "javascript:void(0)" : (props.announcement.clickType === "url" ? "/announcement/urlRedirect/" + props.announcement.announcementID : (process.env.REACT_APP_API_URL === undefined ? ((window.location.href + "salt").replace(window.location.pathname + "salt", "/api")) : process.env.REACT_APP_API_URL) + "/announcement/" + props.announcement.announcementID + "/download?token=" + getToken())} 
                    target="_blank"
                >
                    {
                        props.announcement.clickType === "file" ? 
                            checkString(props.announcement.file === undefined ? "" : props.announcement.file.originalName, "example.pdf") 
                        : 
                            <>
                                click here 
                            </>
                    }
                    <OpenInNewIcon fontSize='small' />
                </a>
            </Tooltip>
        </>
    )
}