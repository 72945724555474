import React from 'react'
import {Route, Switch} from 'react-router-dom'

import RateCardsList from './rateCardsList'
import CurrentRateCard from './currentRateCard'
import RateCardLines from './rateCardLines'

export default function RateCards(props) {
    return(
        <Switch>
            <Route path="/data/rateCards/:rateCardID/lines" render={renderProps => <RateCardLines {...renderProps} me={props.me} />} />
            <Route path="/data/rateCards/all" render={renderProps => <RateCardsList {...renderProps} me={props.me} />} />
            <Route path="/data/rateCards" render={renderProps => <CurrentRateCard {...renderProps} me={props.me} />} />

        </Switch>
    )
}