import React from 'react'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import SuccessIcon from '@material-ui/icons/DoneAllOutlined'
import InfoIcon from '@material-ui/icons/InfoOutlined'

//using same type values as the MUI alert component
export default function ColouredIcon(props) {
    if(props.type === "warning") {
        return (<WarningIcon style={{color:"#ff9800"}} />)
    }
    if(props.type === "error") {
        return (<ErrorIcon style={{color:"#f44336"}} />)
    }
    if(props.type === "success") {
        return (<SuccessIcon style={{color:"#4caf50"}} />)
    }
    if(props.type === "info") {
        return (<InfoIcon style={{color: "#2196f3"}}/>)
    }
    //we dont have an icon for this
    return null
}