import React, { useState } from 'react'
import Button from '@material-ui/core/Button'

import TopModal from './topModal'

export default function TopButtons(props) {
    const [open, setOpen] = useState(false)
    const [type, setType] = useState("stores")
    const [displayName, setDisplayName] = useState("Stores")
    const [idField, setIDField] = useState("Stores")

    function handleClick(t, d, i) {
        setType(t)
        setDisplayName(d)
        setIDField(i)
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }

    return (
        <div style={{display: "flex", alignSelf: "center", minHeight: "31px"}}>
            {
                !props.showTopStore ? null :
            <Button style={{marginLeft: "4px", marginRight: "4px"}} size={props.size} variant={props.variant} onClick={() => handleClick("stores", "Stores", "storeID")}>{props.showTop ? "Top " : ""}Stores</Button>
            }
            {
                !props.showTopSKU ? null :
                <Button style={{marginLeft: "4px", marginRight: "4px"}} size={props.size} variant={props.variant} onClick={() => handleClick("skus", "SKUs", "sku")}>{props.showTop ? "Top " : ""}SKUs</Button>
            }
            {
                !props.showTopUser ? null :
                <Button style={{marginLeft: "4px", marginRight: "4px"}} size={props.size} variant={props.variant} onClick={() => handleClick("users", "Users", "attributableToUserID")}>{props.showTop ? "Top " : ""}Users</Button>
            }
            <TopModal
                type={type}
                displayName={displayName}
                open={open}
                filters={props.filters}
                onClose={handleClose}
                idField={idField}
                me={props.me}
                sort={props.sort}
            />
        </div>
    )
}