import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';

export default function checkBox(props) {
    const handleChange = name => event => {
        //build an event object to return
        var e = {
            target: {
                id: props.id,
                value: event.target.checked
            }
        }
        props.onChange(e)
    };

    return (
        <TooltipWrapper tooltip={props.tooltip}>
            <div>
                <FormControl error={props.error} component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                        control={
                            <Checkbox 
                                checked={(props.value || props.value === 1 || props.value === "true")} 
                                disabled={props.disabled} 
                                onChange={handleChange()} 
                                indeterminate={props.indeterminate}
                            />
                        }
                        label={props.label}
                        />
                    </FormGroup>
                    {props.error ? <FormHelperText>{props.helperText}</FormHelperText> : undefined}
                </FormControl>
            </div>
        </TooltipWrapper>
    )
}

function TooltipWrapper(props) {
    return(
        props.tooltip === undefined ? 
            props.children
        :
        <Tooltip title={props.tooltip}>
            {props.children}
        </Tooltip>
    )
}