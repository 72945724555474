import React from 'react'

import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import saleTypeEntityForm from './saleTypeEntityForm';
import {formatMoney} from '../../../../lib/general'

const filters = [
]

const columnData = [
    { fieldName: 'saleTypeID', default: true, name: 'ID', description: "The unchanging identifier of the sale type"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the sale type"},
];



export default function SaleTypesList(props) {

    return(
        <div>
            <h1>
                Sale Types
            </h1>
            <p>
                The sale types reflect how the rate card is split into different types of sale, example upgrade, new, accessories.  Different dicounts apply to the different sale types, and also different rates of commission and revenue.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={saleTypeEntityForm}
                
                filters={filters} 
                title="Sale Types"
                enableCrud={true}
                me={props.me}
            />
        </div>
    )
}