import React, {useState} from 'react'
import dateFormat from 'dateformat'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

//icons
import AddCircleIcon from '@material-ui/icons/AddCircle';

//repo
import CrudModal from '../../shared/crudModal'
import rotaLineEntityForm from './entityForm/rotaLineEntityForm'

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
} 

export default function DaysList(props) {
    const [crudModalOpen, setCrudModalOpen] = useState(false)
    const [crudModalAction, setCrudModalAction] = useState("create")
    const [defaultFormVals, setDefaultFormVals] = useState(null)

    //some dates
    const rotaDateGEQ = new Date(props.params.rotaDateGEQ)
    const rotaDateLEQ = new Date(props.params.rotaDateLEQ)
    const daysBetween = Math.round((rotaDateLEQ.getTime() - rotaDateGEQ.getTime()) / (1000*60*60*24)) + 1

    //calculate the number of distinct users present
    const distinctUsers = props.rotaLines.reduce((acc, cur) => acc.map(x => x.userID).includes(cur.userID) ? acc : acc.concat(cur.user), [])

    function handleAddClick(defaultVals) {
        //are we prepopulating the form?  ie when clicking add in a cell rather than top left?
        if(defaultVals !== undefined) {
            setDefaultFormVals(defaultVals)
        }
        setCrudModalAction("create")
        setCrudModalOpen(true)
    }

    return (
        <>
            <TableContainer component={Paper} style={{marginTop: "10px"}}>
                <Table aria-label="Targets table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Tooltip title="Add new">
                                    <IconButton size="small" onClick={() => handleAddClick()} aria-label="Add new">
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            {
                                distinctUsers.map(du => 
                                    <TableCell key={du.userID}>
                                        {du.name}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            //make an array which is the length of the number of days between the earliest and latest day
                            [...Array(daysBetween)]
                            //loop through this array (we only use i)
                            .map((x, i) => {
                                //this is the date we are working with this iteration
                                var thisDate = rotaDateGEQ.addDays(i)
                                return(
                                    <TableRow key={i}>
                                        <TableCell>
                                            {dateFormat(thisDate, "ddd d/m")}
                                        </TableCell>
                                        {
                                            distinctUsers.map(du => 
                                                <TableCell key={du.userID}>
                                                    <IconButton 
                                                        size="small" 
                                                        onClick={() => handleAddClick({
                                                            ...rotaLineEntityForm.blankForm,
                                                            userID: du.userID,
                                                            rotaLineDate: dateFormat(thisDate, "yyyy-mm-dd")
                                                        })} 
                                                        aria-label="Add new"
                                                    >
                                                        <AddCircleIcon />
                                                    </IconButton>
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <CrudModal
                entityForm={rotaLineEntityForm}
                action={crudModalAction}
                open={crudModalOpen}
                onClose={() => setCrudModalOpen(false)}
                id="rotaLineCrud"
                me={props.me}
                onDataChanged={props.onDataChanged}
                defaultFormVals={defaultFormVals === null ? undefined : defaultFormVals}
            />
        </>
    )
}