import React, {useState, useEffect} from 'react'
import List from '@material-ui/core/List';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

//import {getLeftMenu} from '../../../lib/auth'
import { Collapse } from '@material-ui/core';


export default function LeftMenuItems(props) {
    const [openSubMenus, setOpenSubMenus] = useState([])

    const menu = [
        //user should have access to at least one store and also one of the relevent permissions to get into here
        {
            text: "Home",
            linkTo: "/"
        },
        //show sales console link if they have permission to some stores and also permission to do anything with sales
        props.me.accessProfile.accessProfileStores.length > 0 && (props.me.accessProfile.systemAreas.split(", ").includes("RecordSales") || props.me.accessProfile.systemAreas.split(", ").includes("RecordRefunds") || props.me.accessProfile.systemAreas.split(", ").includes("UpdateSales")) ? {
            text: "Sales console",
            linkTo: "/sales",
        } : undefined,
        {
            text: "Rate cards",
            linkTo: "/data/rateCards"
        },
        {
            text: "Targets",
            linkTo: "/targets"
        },
        props.me.accessProfile.systemAreas.split(", ").includes("Announce") ? {
            text: "Announcements",
            linkTo: "/announcements"
        } : undefined,
        //if they have permission to plan rota show this option, else just the link to view rota
        true ? undefined : props.me.accessProfile.accessProfileStores.length > 0 && (props.me.accessProfile.systemAreas.split(", ").includes("RecordRotaLines")) ? 
            {
                text: "Rota",
                subMenu: [
                    {
                        text: "Rota",
                        linkTo: "/rota/view/user/" + props.me.userID
                    },
                    {
                        text: "Planning",
                        linkTo: "/rota/plan"
                    },
                ],
            } 
        : 
            {
                text: "Rota",
                linkTo: "/rota/user/" + props.me.userID
            },
        {
            text: "Daily stats",
            linkTo: "/daily/dailyStats"
        },
        props.me.accessProfile.systemAreas.split(", ").includes("RevenueReconciliation") ? {
            text: "Revenue reconciliation",
            linkTo: "/recSheets",
        } : undefined,
        {
            text: "System setup",
            subMenu: [
                props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ? {
                    text: "Analysis fields",
                    linkTo: "/data/analysisFields"
                } : undefined,
                props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ? {
                    text: "Bands",
                    linkTo: "/data/bands"
                } : undefined,
                props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ? {
                    text: "Discounts",
                    linkTo: "/data/discounts"
                } : undefined,
                props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ? {
                    text: "Metrics",
                    linkTo: "/data/metrics"
                } : undefined,
                props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ? {
                    text: "Sale types",
                    linkTo: "/data/saleTypes"
                } : undefined,
                props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ? {
                    text: "SKU types",
                    linkTo: "/data/skuTypes"
                } : undefined,
                props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ? {
                    text: "Stores",
                    linkTo: "/data/stores"
                } : undefined,
                props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup") ? {
                    text: "Store types",
                    linkTo: "/data/storeTypes"
                } : undefined,
                {
                    text: "Users",
                    linkTo: "/data/users"
                },
            ].filter(m => m !== undefined)
        },
        {
            text: "About",
            subMenu: [
                {
                    text: "Licences",
                    linkTo: "/about/licences"
                },
                {
                    text: "Support",
                    linkTo: "/about/support"
                },
            ].filter(m => m !== undefined)
        },
    ].filter(m => m !== undefined)

    function handleSubClick(subText) {
        if (openSubMenus.includes(subText)) {
            //the clicked one is already open, close it by removing it
            setOpenSubMenus(openSubMenus.filter(m => (m !== subText)))
        } else {
            //add it to the array
            setOpenSubMenus(openSubMenus.concat(subText))
        }
    }

    function makeMenuItem(menuNode, indent) {
        const itemAttributes = {}
        //the item can be EITHER a submenu or a link
        var isSubMenu = Boolean(menuNode.subMenu !== undefined)
        if(!isSubMenu && menuNode.linkTo !== undefined){
            //it is not a submenu and there is a link so set up the handler
            itemAttributes.onClick = () => props.handleMenuSelect(menuNode.linkTo)
        }
        if(isSubMenu){
            //when clicked open up the submenu
            itemAttributes.onClick = () => handleSubClick(menuNode.text)
        }
        //itemAttributes.key = menuNode.text
        itemAttributes.style = {paddingLeft: 15 * (indent + 1)}
        //menu item with no link, just a button
        return(
            <div key={"div" + menuNode.text}>
            <ListItem button {...itemAttributes}>
                <ListItemText primary={menuNode.text} />
                {
                    isSubMenu ? 
                        openSubMenus.includes(menuNode.text) ? <ExpandLess /> : <ExpandMore />
                    :
                    ""
                }
            </ListItem>
                {
                    isSubMenu && 
                    makeList(menuNode.subMenu, indent+1, menuNode.text)
                }
            </div>
        )
    }

    function makeList(fromArray, indent, parentText) {
        //if it is a subMenu then we need to wrap it in a collapse
        if(indent === 0) {
            return(
                <List component="div" disablePadding >
                    {
                        fromArray.map(m => {
                            return(makeMenuItem(m, indent))
                        })
                    }
                </List>
            )
        } else {
            return(
                <Collapse in={openSubMenus.includes(parentText)} timeout="auto" unmountOnExit >
                    <List component="div" disablePadding dense={Boolean(indent !== 0)} >
                        {
                            fromArray.map(m => {
                                return(makeMenuItem(m, indent, "parent"))
                            })
                        }
                    </List>
                </Collapse>
            )
        }
    }

    return(
        makeList(menu, 0)
    )

}
