import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import LongRunningOperationSpinner from '../../shared/longRunningOperationSpinner'

import authFetch from '../../../lib/authFetch';

const blankResult = {
    considered: [],
    reconciled: [],
    matchMultiple: [],
    matchNone: []
}

export default function AutoRecModal(props) {
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(blankResult)

    function handleEnter() {
        //on calling we immediately do the reconciliation
        setLoading(true)
        //make the api call
        authFetch("/recSheet/" + props.recSheetID + "/autoRec", {method: "PUT"})
        .then(r => {
            setResult(r.data)
            setLoading(false)
        })
    }

    return(
        <Dialog open={props.open} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                {
                    loading ?
                        "Performing auto reconciliation..."
                    :
                        "Auto reconciliation results"
                }
            </DialogTitle>
            <DialogContent>
                {
                    loading ?
                        <LongRunningOperationSpinner/>
                    :
                        <div>
                            {
                                result === undefined || result.considered === undefined ? null :
                                <>
                                    <p>
                                        {result.considered.length} unreconciled lines were considered:
                                    </p>
                                    {
                                        result.reconciled.length === 0 ? null :
                                        <Alert style={{margin: "8px"}} severity="success">
                                            {result.reconciled.length} lines were successfully matched
                                        </Alert>
                                    }
                                    {
                                        result.matchNone.length === 0 ? null :
                                        <Alert style={{margin: "8px"}} severity="warning">
                                            {result.matchNone.length} lines could not be matched because <strong>no sale</strong> could be found with the same SKU, Store, Sale Date, Revenue and Order ref
                                        </Alert>
                                    }
                                    {
                                        result.matchMultiple.length === 0 ? null :
                                        <Alert style={{margin: "8px"}} severity="warning">
                                            {result.matchMultiple.length} lines could not be matched because <strong>multiple sales</strong> found with this SKU, Store, Sale Date, Revenue and Order ref
                                        </Alert>
                                    }
                                </>
                            }
                            <p>
                                Close this dialog and look at the 'Auto rec' field to review the changes made
                            </p>
                        </div>
                }
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}