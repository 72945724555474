import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//lib
import authFetch from '../../../lib/authFetch';

export default function ColumnDataModal(props) {

    const [formVals, setFormVals] = useState(props.value)

    function handleSave(setDefault) {
        //the action button has been pressed
        props.onChange(formVals)
        props.onClose()
        //also save default?
        if(setDefault) {
            var body = {}
            body.value = formVals.map(c => c.name).join(",")
            authFetch("/user/userPreference/TableColumns/" + encodeURIComponent(props.tableName), {method: "PUT", body: JSON.stringify(body)}, "/")
        }
    }

    function handleChange(event, name) {
        //a box has been ticked or not
        var newFormVals = formVals
        if (event.target.checked) {
            //new tick add the column to the formvals
            newFormVals = newFormVals.concat(props.columnData.filter(a => a.name === name))
        } else {
            //tick has been removed filter it out
            newFormVals = newFormVals.filter(a => a.name !== name)
        }
        setFormVals(newFormVals)
    }

    function handleAll() {
        setFormVals(props.columnData)
    }

    function handleNone() {
        setFormVals([])
    }

    function handleDefault() {
        setFormVals(props.columnData.filter(c => c.default))
    }
    
    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth={"md"} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Select columns
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                Choose the columns you want to see
            </DialogContentText>
                <FormGroup>
                    {props.columnData.map(c => {
                        return(
                            <FormControlLabel
                                key={c.name}
                                control={
                                    <Checkbox checked={formVals.filter(a => a.name === c.name).length > 0} onChange={(event) => handleChange(event, c.name)} value={c.name} />
                                }
                                label={c.name + (c.description === undefined ? "" : " - " + c.description)}
                            />
                        )
                    })}
                </FormGroup>
                <span style={{color: "blue", textDecoration: "underline"}} onClick={handleAll}>All</span> / <span style={{color: "blue", textDecoration: "underline"}} onClick={handleNone}>none</span> / <span style={{color: "blue", textDecoration: "underline"}} onClick={handleDefault}>default</span>
            </DialogContent>
            <DialogActions>
            <Button color="default" onClick={props.onClose} variant="contained">
                Cancel
            </Button>
            <Button color="default" onClick={() => handleSave(false)} variant="contained">
                Apply
            </Button>
            <Button color="primary" onClick={() => handleSave(true)} variant="contained">
                Apply and Save Default
            </Button>
            </DialogActions>
      </Dialog>
    )
}