import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import dateFormat from 'dateformat'
import {formatMoney} from '../../../lib/general'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import SaleFilters from './saleFilters'
import DataTable from '../../shared/dataTable/dataTable'
import userEntityForm from '../data/users/userEntityForm'
import storeEntityForm from '../data/stores/storeEntityForm'
import SKUHistoryCell from '../data/rateCards/skuHistoryCell'
import LookupCell from '../../shared/dataTable/lookupCell'

export default function TopModal(props) {
    //const [filtersOverridden, setFiltersOverridden] = useState(false)
    const [filters, setFilters] = useState({...props.filters})

    function handleEnter() {
        setFilters(props.filters)
    }

    //console.log("Filters " + JSON.stringify(props.filters))

    const noFullscreen = useMediaQuery("(min-width:1000px)")


    var entityForm = {
        displayName: props.displayName,
        idField: props.idField,

        //getURL: id => "/history/" + id,
        //putURL: id => "/history/" + id,
        //deleteURL: id => "/history/" + id,
        //postURL: "/history",
        getListURL: "/kpi/" + props.type,
        maxWidth: "md",
        recordName: formVals => formVals.historyID,

        blankForm: {
            historyID: 0,
            step: ""
        },
        form : function (props) {
            return (
                <div>
                    <h4>
                        Here is the null top form
                    </h4>
                </div>
        
            )
        },
    }

    function handleFiltersChange(newFilters) {
        //setFiltersOverridden(true)
        setFilters(newFilters)
    }

    function columnData() {
        var c = []
        //these cols depend on the type of top being shown
        if(props.type === "stores") {
            c.push(
                { fieldName: 'storeID', default: false, name: 'Store ID', description: "Unchanging identifier of the store"},
                { fieldName: 'storeName', default: true, name: 'Store', description: "Store the sale was at", renderCell: dr => 
                    <LookupCell 
                        id={dr.storeID} 
                        entityForm={storeEntityForm}
                        label={dr.storeName}
                    />
                },
            )
        } else if(props.type === "skus") {
            c.push(
                { fieldName: 'sku', default: true, name: 'SKU', description: "The SKU", renderCell: dr => <SKUHistoryCell sku={dr.sku} /> },
                { fieldName: 'rateCardLineDescription', default: true, name: 'Description', description: "The description of the SKU" }
            )
        } else {
            //must be users
            c.push(
                { fieldName: 'attributableToUserName', default: true, name: 'Seller', description: "The user who made this sale", renderCell: dr => 
                    <LookupCell 
                        id={dr.attributableToUserID} 
                        entityForm={userEntityForm}
                        label={dr.attributableToUserName}
                    />
                },
            )
        }
        c.push(
            { fieldName: 'revenueCount', default: true, name: 'Transactions', description: "Number of transactions", renderCell: dr => formatMoney(dr.revenueCount, 0), alignRight: true},
        )
        //now the number cols.  depends on props.me.revenueCommission whether they are shown revenue, commission, or what
        if(props.me === undefined || props.me.revenueCommission === undefined) {
            //can't add anything
        } else {
            if(props.me.revenueCommission.includes("revenue")){
                //adding revenue
                c.push(
                    { fieldName: 'adjustedRevenueTotal', default: true, name: 'Revenue', description: "Total revenue", renderCell: dr => formatMoney(dr.adjustedRevenueTotal), alignRight: true},
                    { fieldName: 'adjustedRevenueASP', default: true, name: 'ASP', description: "Average revenue per sale", renderCell: dr => formatMoney(dr.adjustedRevenueASP), alignRight: true},
                )
            }
            if(props.me.revenueCommission.includes("commission")){
                //adding commission
                c.push(
                    { fieldName: 'commissionTotal', default: true, name: 'Commission', description: "Total commission", renderCell: dr => formatMoney(dr.commissionTotal), alignRight: true},
                    { fieldName: 'commissionASP', default: true, name: 'Av Comm', description: "Average commission per sale", renderCell: dr => formatMoney(dr.commissionASP), alignRight: true},
                )
            }
        }
        return c
    }

    return(
        <Dialog open={props.open} maxWidth="md" fullScreen={!noFullscreen} fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                Top {props.displayName}
            </DialogTitle>
            <DialogContent>
                <SaleFilters
                    filters={filters}
                    onFiltersChange={handleFiltersChange}
                />
                <DataTable
                    columnData={columnData()}
                    entityForm={entityForm}
                    filters={filters} 
                    title={"Top " + props.displayName}
                    enableCrud={false}
                    sort={props.sort || "adjustedRevenueTotal"}
                    sortDir="desc"
                    me={props.me}
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}