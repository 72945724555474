import React from 'react'
import AnnounceIcon from '@material-ui/icons/RecordVoiceOver'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'

export default function QuickAnnounce(props) {
    //check if they have permission
    if(props.me === null || props.me === undefined || props.me.userID === 0 || !props.me.accessProfile.systemAreas.split(", ").includes("Announce")) {
        //no permission, hiding the button
        return null
    }

    return (
        <Button size='small' startIcon={<AnnounceIcon fontSize='small' />} component={Link} to="/announcements">
            Announce
        </Button>
    )
}
