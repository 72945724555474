import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress'

//components
import UploadStepper from './uploadStepper'
import Step0 from './step0'
import Step1 from './step1'
import Step2 from './step2'
import LongRunningOperationSpinner from '../../../../shared/longRunningOperationSpinner';

//lib
import authFetch from '../../../../../lib/authFetch';

const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}
const blankStep0FormVals = {
    fileID: 0,
}
const blankStep1FormVals = {
    fileID: 0,
    mappings: [],
    rateCardID: 0,
    commit: false
}
const blankStep2FormVals = {
    validationReport: []
}

export default function LineUploadModal(props) {
    //0 - choose file
    //1 - map fields
    //2 - confirm upload
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [excelFields, setExcelFields] = useState([])

    //formvals and validation result
    const [step0FormVals, setStep0FormVals] = useState(blankStep0FormVals)
    const [step1FormVals, setStep1FormVals] = useState(blankStep1FormVals)
    const [step2FormVals, setStep2FormVals] = useState(blankStep2FormVals)
    const [step0ValidationResult, setStep0ValidationResult] = useState(blankValidationResult)
    const [step1ValidationResult, setStep1ValidationResult] = useState(blankValidationResult)

    function handleEnter() {
        //start from the beginning
        setStep0FormVals(blankStep0FormVals)
        setStep(0)
        setLoading(false)
    }

    //will return an empty array if there are no mappings
    function getUserPreferenceMappings() {
        return authFetch("/user/userPreference/defaults/rateCardMap")
        .then(r => {
            if(r.validationResult.fail) {
                //no mappings
                return Promise.resolve([])
            } else {
                //there is something, parse it as JSON
                var pot = JSON.parse(r.data)
                //console.log("Got up: " + JSON.stringify(pot))
                if(Array.isArray(pot)) {
                    return Promise.resolve(pot)
                } else {
                    //not what we expected
                    return Promise.resolve([])
                }
            }
        })
        .catch(err => {
            console.log(err)
            //probably a JSON parse error
            return Promise.resolve([])
        })
    }

    function handleAction() {
        //the action button has been pressed
        setLoading(true)
        //move to next step, or do the upload, depending on the current step
        if(step === 0) {
            //get the column headers found in the file
            var xlf
            var uploadFields
            authFetch("/file/" + step0FormVals.fileID + "/excelHeaders")
            .then(r => {
                //we got the file headers
                setExcelFields(r.listData)
                //also store it in a local variable so we can use it to apply user preferences if available
                xlf = r.listData
                //get the list of uploadFields from the api, so we can populate step1FormVals
                return authFetch("/rateCardLine/uploadFields")
            })
            .then(uf => {
                //save the upload fields for use later on
                uploadFields = uf.listData
                //get the user preference which might contain a preset mapping from last time
                return getUserPreferenceMappings()
            })
            .then(upm => {
                //set the fileID and fields in step 1 formVals
                //also set the default from the user preferences if available
                setStep1FormVals({
                    fileID: step0FormVals.fileID,
                    rateCardID: props.rateCardID,
                    saleTypeID: props.saleType.saleTypeID,
                    commit: false,
                    mappings: uploadFields.map(x => {
                        var prefsArray = upm.filter(u => u.uploadField === x)
                        var upVal = prefsArray.length > 0 && Array.isArray(prefsArray[0].excelField) ? 
                                prefsArray[0].excelField
                                //only the ones that are valid in this excel file
                                .filter(u => xlf.map(y => y.name).includes(u))
                                //reducing it down to the first (most recent) one
                                .reduce((acc, cur) => acc === "" ? cur : acc, "")
                            : ""
                        return ({
                            uploadField: x,
                            excelField: upVal
                        })
                    })
                })
                //move to next step
                setLoading(false)
                setStep(1)                
            })
        }
        //if it's step 1 lets see what the API makes of the mapping that's been given to it
        if(step === 1) {
            //save the mapping so that next time it defaults if the column names are the same
            //the shape is an array of [{uploadField: 'xxx', excelField: ['yyy', 'zzz']}] where the array contains the most recently used value first
            //first get the current usePreferenceValue so we can merge with it
            getUserPreferenceMappings()
            .then(upm => {
                var newUPM = []
                //first add the values we used now, so that they are the most recently used
                step1FormVals.mappings.forEach(m => {
                    if(m.excelField !== "") { //don't save preference for nothing!
                        newUPM.push({
                            uploadField: m.uploadField,
                            excelField: [
                                m.excelField
                            ]
                        })
                    }
                })
                //now merge in each of the existing mappings
                upm.forEach(u => {
                    //is it an array that can be added?
                    if(Array.isArray(u.excelField)) {
                        //does the uploadField exist?
                        var existing = newUPM.filter(n => n.uploadField === u.uploadField)
                        if(existing.length > 0) {
                            //console.log("Already dealt with " + u.uploadField)
                            //it is one we have just dealt with.  Add the values used in the past in case they come up again in future
                            existing[0].excelField.push(...(u.excelField.filter(x => x !== "")))
                        } else {
                            //the uploadField is not one we have just dealt with, add it in case it comes up in future
                            //console.log("Not seen this one")
                            newUPM.push(u)
                        }
                    }// else {console.log("Not an array")}
                })
                //remove any dupes
                newUPM = newUPM.map(d => ({
                    uploadField: d.uploadField,
                    excelField: [...new Set(d.excelField)]
                }))
                //return with the call to save the mappings for next time
                return authFetch("/user/userPreference/defaults/rateCardMap", {method: "PUT", body: JSON.stringify({value: newUPM})})
            })
            
            //now do the actual upload
            .then(na => authFetch("/rateCardLines/excel", {
                method: "POST",
                body: JSON.stringify(step1FormVals)
            }))
            .then(r => {
                //the response contains a validation report in the shape row, column, error, value
                setStep2FormVals({
                    validationReport: Array.isArray(r.listData) ? r.listData : []
                })
                //now more onto the next page
                setLoading(false)
                setStep(2)
            })
        }
        //if step is 2 it's time to commit these to the database!
        if(step === 2) {
            setLoading(true)
            //post the step 1 formVals but this time set commit to true
            authFetch("/rateCardLines/excel", {
                method: "POST",
                body: JSON.stringify({
                    ...step1FormVals,
                    commit: true
                })
            })
            .then(na => {
                //all done.  Close the disloage and register the data change
                setLoading(false)
                props.onDataChange()
                props.onClose()
            })
        }
    }

    function handleExit() {
        setStep(0) //so that next time it starts from the beginning
        setLoading(false)
    }

    function handleStep0Change(newFormVals) {
        //called by the entityForm when the values of the form change
        setStep0FormVals(newFormVals)
    }    
    
    function handleStep1Change(newFormVals) {
        //called by the entityForm when the values of the form change
        setStep1FormVals(newFormVals)
    }

    return(
        <Dialog open={props.open} maxWidth="md" fullWidth={true} onEnter={handleEnter} onExited={handleExit} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Upload {props.saleType.name} Lines
            </DialogTitle>
            <DialogContent style={{minHeight: "250px"}}>
                <UploadStepper step={step} />
                { //if loading, show the spinner, else show the wizard content
                    loading ?
                        <LongRunningOperationSpinner/>
                    :
                        //content goes here
                        <div>
                            {step === 0 ? <Step0 
                                formVals={step0FormVals}
                                onChange={handleStep0Change}
                                validationResult={step0ValidationResult}
                            /> : undefined}
                            {step === 1 ? <Step1 
                                formVals={step1FormVals}
                                onChange={handleStep1Change}
                                validationResult={step1ValidationResult}
                                excelFields={excelFields}
                            /> : undefined}
                            {step === 2 ? <Step2 
                                formVals={step2FormVals}
                            /> : undefined}
                        </div>
                }
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button 
                    color={step === 2 ? "primary" : "default"} 
                    onClick={handleAction} 
                    variant="contained" 
                    disabled={loading || (step === 0 && step0FormVals.fileID === 0)}
                >
                    {step === 2 ? "Confirm Upload" : "Next"}
                </Button>
            </DialogActions>
      </Dialog>
    )
}