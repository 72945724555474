import React from 'react'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'

import DatePicker from '../../../shared/form/datePicker'

export default function dailyStatFilters(props) {

    function handleDateChange(e) {
        var newFilters = {...props.filters}
        //see if it's a proper date yet
        try{
            newFilters[e.target.id] = dateFormat(e.target.value, "yyyy-mm-dd")
            props.onFiltersChange(newFilters)
        } catch (err) {

        }        
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <DatePicker 
                    id="dailyStatDateGEQ"
                    label="From date"
                    onChange={handleDateChange}
                    value={props.filters.dailyStatDateGEQ || ""}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker 
                    id="dailyStatDateLEQ"
                    label="To date"
                    onChange={handleDateChange}
                    value={props.filters.dailyStatDateLEQ || ""}
                />
            </Grid>
        </Grid>
    )
}