import React from 'react'

import DataTable, {addAuditFields, renderCB} from '../../../shared/dataTable/dataTable'
import StoreTypeEntityForm from './storeTypeEntityForm';
import StoreEntityForm from '../stores/storeEntityForm'
import ChipCell from '../../../shared/dataTable/chipCell';


const filters = {
    fkJoins: "stores"
}

const columnData = [
    { fieldName: 'storeTypeID', default: true, name: 'ID', description: "The unchanging identifier of the storeType"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the store type"},
    { fieldName: 'recordFootfall', default: true, name: 'Record footfall', description: "Whether stores of this type record footfall", renderCell: dr => renderCB(dr.recordFootfall)},
    { fieldName: 'recordChatCount', default: true, name: 'Count chats', description: "Whether stores of this type record daily chat count", renderCell: dr => renderCB(dr.recordChatCount)},
    { fieldName: 'recordStaffCount', default: true, name: 'Count staff', description: "Whether stores of this type record daily staff count", renderCell: dr => renderCB(dr.recordStaffCount)},
    { fieldName: 'storeCount', default: true, name: 'Stores', description: "List of stores of this type", renderCell: (dataRow, onDataChanged, enableCrud) => 
    <ChipCell 
        chips={dataRow.stores} 
        onDataChanged={onDataChanged} 
        enableCrud={enableCrud}
        entityForm={StoreEntityForm}
        parentEntityForm={StoreTypeEntityForm}
        parentEntityValue={dataRow.storeTypeID}
    />
},

];

export default function StoreTypesList(props) {

    return(
        <div>
            <h1>
                Store Types
            </h1>
            <p>
                Store types are used to classify your stores into different types.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={StoreTypeEntityForm}
                
                filters={filters} 
                title="Store Types List"
                enableCrud={true}
            />
        </div>
    )
}