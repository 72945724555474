import React from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'

export default function validationSummary(props) {
    return (
        props.validationResult.fail ? 
            <FormHelperText error={true} >
                {props.validationResult.failSummary + (Object.keys(props.validationResult.failMessages).length <= 1 ? "" : ": " + Object.keys(props.validationResult.failMessages).map(k => k + "-" + props.validationResult.failMessages[k]).join(", "))}
            </FormHelperText> 
        : 
            null
    )
}