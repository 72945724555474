import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'
import {formatMoney} from '../../../../lib/general'

import CrudModal from '../../../shared/crudModal'
import recSheetLineEntityForm from '../../recSheets/recSheetLineEntityForm'
import UnrecModal from './unrecModal'

export default function reconciledAlert(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [modalrslID, setModalrslID] = useState(0)
    const [unrecOpen, setUnrecOpen] = useState(false)
    
    function handleModalClose() {
        setModalOpen(false)
    }

    function handleClick(recSheetLineID) {
        setModalrslID(recSheetLineID)
        setModalOpen(true)
    }

    function handleUnrecClick() {
        setUnrecOpen(true)
    }

    function handleUnrecAction() {
        setUnrecOpen(false)
        //we must also close the parent modal in order to force a reload
        props.onDataChanged()
    }

    function handleUnrecCancel() {
        setUnrecOpen(false)
    }

    return (
        props.formVals.currentHistoryStep === "reconciled" ?
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Alert style={{margin: "8px"}} severity="success" action={
                            <div>
                                {
                                    props.formVals.recSheetLines.map(r => 
                                        <Button onClick={() => handleClick(r.recSheetLineID)} key={r.recSheetLineID}>
                                            View{props.formVals.recSheetLines.length > 1 ? " " + r.recSheetLineID : ""}
                                        </Button>
                                    )
                                }
                            </div>
                        }>
                            This sale is reconciled with rec sheet line{props.formVals.recSheetLines.length > 1 ? "s " : " "}
                            {
                                props.formVals.recSheetLines.map((r, i) => <span>
                                    {i > 0 ? ", " : ""}
                                    <strong>{r.recSheetLineID}</strong> (by {r.recByUserName} at {dateFormat(r.recDate, "dd/mm/yyyy HH:MM:ss")})
                                </span>)
                            }               
                        </Alert>
                        {//showing a warning if revenue was adjusted
                            props.formVals.adjustment === 0 ? null : 
                            <Alert style={{margin: "8px"}}
                                severity="warning"
                            >
                                Revenue was adjusted by £{formatMoney(props.formVals.adjustment)} to make the sale match the revenue sheet! 
                            </Alert>
                        }
                        <CrudModal
                            action="read"
                            open={modalOpen}
                            id={modalrslID}
                            entityForm={recSheetLineEntityForm}
                            onClose={handleModalClose}
                            me={props.me}
                            onDataChanged={props.onDataChanged}
                        />
                    </Grid>
                    {
                        props.me.accessProfile.systemAreas.includes("RevenueReconciliation") ?
                            <Grid item xs={12}>
                                <Button variant="contained" color="secondary" onClick={handleUnrecClick}>
                                    Unreconcile
                                </Button>
                                <UnrecModal
                                    open={unrecOpen}
                                    onAction={handleUnrecAction}
                                    onCancel={handleUnrecCancel}
                                    saleID={props.formVals.saleID}
                                />
                            </Grid>
                        : null
                    }
                    
                </Grid> 
        : null
    )
}