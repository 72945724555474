import React, {useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Button from '@material-ui/core/Button'
import ClickAction from './clickAction'
import authFetch from '../../../../lib/authFetch'
import NoAcknowledgeModal from './noAcknowledgeModal'

function checkString(checking, alternative) {
    //return checking or alternative
    return (checking === undefined || checking === "" ? alternative : checking)
}

export default function Announcement(props) {
    const [open, setOpen] = useState(false)

    function handleAcknowledge(e) {
        //is the control enabled?
        if(props.disabled) {
            //no, it's likely a preview of the announcement
            return null
        }
        //ask the api if we're allowed to acknowledge this announcement
        authFetch("/announcement/" + props.announcement.announcementID + "/acknowledge")
        .then(r => {
            //can it be acknowledged?
            if(r.validationResult.fail) {
                //could not be acknowledged, probably because they have not done the clickaction
                setOpen(true)
            } else {
                //ok, reload the messages (thus making this one dissappear)
                if(!(props.refreshMyAnnouncements === undefined)) {
                    props.refreshMyAnnouncements()
                }
            }
        })
    }

    //check we are trying to display something valid
    if(props.announcement === undefined || props.announcement === null) {
        console.log("Surpressing null announcment")
        return null
    }

    return(
        <>
            <Alert
                severity={props.announcement.type}
                action={
                    props.announcement.acknowledgable ? 
                        <Button color="inherit" size="small" onClick={handleAcknowledge}>
                            OK
                        </Button>
                    : null
                }
            >
                <AlertTitle>{checkString(props.announcement.title, "Title")}</AlertTitle>
                {checkString(props.announcement.message, "Announcement message")}
                <ClickAction
                    me={props.me}
                    announcement={props.announcement}
                    disabled={props.disabled}
                />
            </Alert>
            <NoAcknowledgeModal
                me={props.me}
                open={open}
                announcement={props.announcement}
                onClose={() => setOpen(false)}
            />
        </>
    )
}